import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setFailedCoinLogo } from "app.reducers/ui";

import {
  ASSETS_BASE,
  GROUP_LOGO,
  GENERIC_ICON_PATH,
  WALLET,
  SOURCELESS,
} from "app.constants";

const WalletGroupLogo = ({
  group,
  embed = false,
  name,
  small = false,
  large = false,
}) => {
  const [isError, setIsError] = useState(false);
  const reduxDispatch = useDispatch();

  const logoPath = GROUP_LOGO[group];

  // error handler to add a fallback image when a coin icon is not found
  function handleError() {
    reduxDispatch(setFailedCoinLogo(group));
    setIsError(true);
  }
  let size = 32;
  let maxH = "max-h-[32px]";
  if (large) {
    size = 40;
    maxH = "max-h-[40px]";
  }
  if (small) {
    size = 24;
    maxH = "max-h-[24px]";
  }

  // ignore for generic wallets etc
  if ([WALLET, SOURCELESS].findIndex((s) => s === group) !== -1) {
    return null;
  }

  const logoURL = logoPath
    ? [ASSETS_BASE, logoPath].join("")
    : GENERIC_ICON_PATH;

  if (embed) {
    return (
      <image
        data-source={group}
        alt={name}
        href={logoURL}
        width="12"
        height="12"
        x="12"
        y="12"
      />
    );
  }
  return (
    <img
      data-source={group}
      onError={!isError ? handleError : undefined}
      className={maxH}
      alt={name}
      src={isError ? GENERIC_ICON_PATH : logoURL}
      width={size}
      height={size}
    />
  );
};

WalletGroupLogo.displayName = "WalletGroupLogo";

export default WalletGroupLogo;
