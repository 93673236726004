import { call, put, select } from "redux-saga/effects";
import {
  requestMapCounterparty,
  receiveMapCounterparty,
  mapCounterpartyError,
  fetchAccountMappings,
} from "app.reducers/accountingIntegrations";

import ExchangesAPI from "app.api/ExchangesAPI";
import { STATUS } from "app.constants";

function* mapCounterparty(action) {
  const { tag, linkId, counterpartyId } = action.payload;

  const { updateCounterpartyStatus } = yield select(
    (state) => state.accountingIntegrations
  );

  if (updateCounterpartyStatus === STATUS.WORKING) return;

  yield put(requestMapCounterparty());

  const counterPartyMappings = [
    { linkId: linkId || null, counterPartyId: counterpartyId },
  ];

  const response = yield call(
    ExchangesAPI.mapCounterParty,
    tag.tag,
    counterPartyMappings
  );

  if (response.error) {
    console.error("Error in mapCounterparty", response.body, response.error);
    yield put(mapCounterpartyError(response.body.message));
  } else {
    yield put(receiveMapCounterparty());
    yield put(fetchAccountMappings(linkId));
  }
}

export default mapCounterparty;
