import { call, put } from "redux-saga/effects";
import {
  requestMapDefaultAccount,
  receiveMapDefaultAccount,
  mapDefaultAccountError,
  fetchAccountMappings,
} from "app.reducers/accountingIntegrations";

import ExchangesAPI from "app.api/ExchangesAPI";
import { getLoadingState } from "app.utils/selectors";

function* mapDefaultAccount(action) {
  const { connectionId, feeAccountId, gainLossAccountId, mapId } =
    action.payload;

  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.tags.accountMappingStatus
  );

  if (isLoading) return;

  yield put(requestMapDefaultAccount(nextStatus));

  const response = yield call(
    ExchangesAPI.defaultAccountMapping,
    feeAccountId,
    gainLossAccountId,
    connectionId,
    mapId
  );

  if (response.error) {
    console.error("Error in mapDefaultAccount", response.body, response.error);
    yield put(mapDefaultAccountError(response.body.message));
  } else {
    yield put(receiveMapDefaultAccount());
  }
  yield put(fetchAccountMappings(connectionId));
}

export default mapDefaultAccount;
