import { call, put, all, select } from "redux-saga/effects";
import {
  requestDeleteGroupMapping,
  receiveDeleteGroupMapping,
  deleteGroupMappingError,
  fetchAccountMappings,
} from "app.reducers/accountingIntegrations";

import {
  dismissModal,
  startModalWorking,
  endModalWorking,
} from "app.reducers/ui";

import ExchangesAPI from "app.api/ExchangesAPI";
import { STATUS } from "app.constants";

function* deleteGroupMapping(action) {
  const { mappingId, linkId, tagGroupId } = action.payload;

  const { accountMappingStatus } = yield select(
    (state) => state.accountingIntegrations
  );

  if (accountMappingStatus === STATUS.WORKING) return;

  yield put(requestDeleteGroupMapping());
  yield put(startModalWorking());

  const response = yield call(
    ExchangesAPI.tagGroupToAccountMapping,
    tagGroupId,
    linkId,
    mappingId,
    null,
    null,
    null,
    null
  );

  if (response.error) {
    console.error("Error in deleteGroupMapping", response.body, response.error);
    yield put(deleteGroupMappingError(response.body.message));
  } else {
    yield all([
      put(receiveDeleteGroupMapping()),
      put(fetchAccountMappings(linkId)),
      put(dismissModal()),
    ]);
  }
  yield put(endModalWorking());
}

export default deleteGroupMapping;
