import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router";

import { dismissModal, setAddressLabel } from "app.reducers/ui";

import { useGetLabelByAddress } from "app.hooks";

import { useTranslation } from "react-i18next";
import Button from "app.components/Util/Button";
import Label from "app.components/Util/Form/Label";
import Input from "app.components/Util/Form/Input";

import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";

function ModalAddressLabel({ address }) {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();
  const location = useLocation();
  const getLabelByAddress = useGetLabelByAddress();

  const hasPreselectedAddress = typeof address !== "undefined";

  const { addressLabelWorking } = useSelector((state) => state.ui);

  const existingLabel = address ? getLabelByAddress(address) || "" : "";

  const [label, setLabel] = useState(existingLabel);
  const [addr, setAddr] = useState("");

  const alreadyUsed = getLabelByAddress(addr) || "";

  const labelAlreadyExists = !hasPreselectedAddress && alreadyUsed;

  const handleDismiss = (e) => {
    e.preventDefault();
    reduxDispatch(dismissModal());
  };

  const submitTag = () => {
    if ((label === "" && existingLabel !== "") || label !== "") {
      if (hasPreselectedAddress) {
        reduxDispatch(setAddressLabel(address, label));
      } else {
        reduxDispatch(setAddressLabel(addr, label));
      }
    } else {
      reduxDispatch(dismissModal());
    }
  };

  return (
    <>
      <ModalHeader
        title={t("modals.addressLabel.setAddressLabel")}
        closeCallback={handleDismiss}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitTag();
        }}
      >
        <div className="m-4">
          <p className="mb-4 text-lg">
            {t("modals.addressLabel.addressLabelDescription")}
          </p>
          {hasPreselectedAddress ? (
            <p className="mb-4 flex flex-row items-center text-base">
              {t("modals.addressLabel.addressLabelInstructions")}
              <span
                title={address}
                className="ml-1 inline-block rounded-sm bg-zinc-100 px-1 font-mono text-base"
              >
                {address.length > 64
                  ? `${address.substring(0, 64)}\u2026`
                  : address}
              </span>
              .
            </p>
          ) : null}
          {!hasPreselectedAddress ? (
            <div className="mb-4 flex w-full flex-col gap-1">
              <Label htmlFor="label">
                {t("modals.addressLabel.address")}
                {labelAlreadyExists ? (
                  <span className="ml-2 text-sm font-normal text-red-700">
                    {t("modals.addressLabel.aLabelAlreadyExistsForThisAddress")}
                  </span>
                ) : null}
              </Label>
              <Input
                id="address"
                name="adress"
                type="text"
                hasError={labelAlreadyExists}
                disabled={addressLabelWorking}
                maxLength={255}
                value={addr}
                data-1p-ignore // i don't know why on this field in particular 1Password was showing up...
                onChange={(e) => setAddr(e.target.value)}
              />
            </div>
          ) : null}
          <div className="mb-4 flex w-64 flex-col gap-1">
            {hasPreselectedAddress ? (
              <Label htmlFor="label">
                {t("modals.addressLabel.addressLabel")}
              </Label>
            ) : (
              <Label htmlFor="label">{t("modals.addressLabel.label")}</Label>
            )}
            <Input
              id="label"
              name="label"
              type="text"
              disabled={addressLabelWorking}
              maxLength={100}
              value={label}
              data-1p-ignore // i don't know why on this field in particular 1Password was showing up...
              onChange={(e) => setLabel(e.target.value)}
            />
          </div>
        </div>

        <ModalControls>
          <Button
            type="submit"
            disabled={
              addressLabelWorking ||
              labelAlreadyExists ||
              label === existingLabel ||
              (!hasPreselectedAddress && addr === "")
            }
            working={addressLabelWorking}
            workingText={t("button.saving")}
            buttonType="primary"
            customClasses="ml-4"
            text={t("modals.addressLabel.saveLabel")}
          />
          <Button
            onClick={handleDismiss}
            disabled={addressLabelWorking}
            buttonType="text"
            text={t("common.close")}
          />
          {location.pathname !== "/portfolio/settings/address-labels" ? (
            <NavLink
              className="text-link-blue hover:link-blue-active mr-auto hover:underline"
              to="../portfolio/settings/address-labels"
            >
              {t("modals.addressLabel.manageAddressLabels")}
            </NavLink>
          ) : null}
        </ModalControls>
      </form>
    </>
  );
}

ModalAddressLabel.displayName = "ModalAddressLabel";

export default ModalAddressLabel;
