import { call, put, select } from "redux-saga/effects";
import {
  requestMapTagGroup,
  receiveMapTagGroup,
  mapTagGroupError,
  fetchAccountMappings,
} from "app.reducers/accountingIntegrations";

import { STATUS } from "app.constants";

import ExchangesAPI from "app.api/ExchangesAPI";

function* mapTagGroup(action) {
  const {
    tagGroupdId,
    connectionId,
    mapId,
    accountId,
    feeAccountId,
    gainLossAccountId,
  } = action.payload;

  const mapTagGroupStatus = yield select(
    (state) => state.accountingIntegrations
  );

  const mapStatus = mapTagGroupStatus[accountId] || undefined;

  if (mapStatus === STATUS.WORKING) return;

  yield put(requestMapTagGroup(accountId));

  const response = yield call(
    ExchangesAPI.tagGroupToAccountMapping,
    tagGroupdId,
    connectionId,
    mapId,
    accountId,
    feeAccountId,
    gainLossAccountId
  );

  if (response.error) {
    console.error("Error in mapTagGroup", response.body, response.error);
    yield put(mapTagGroupError(response.body.message));
  } else {
    yield put(receiveMapTagGroup(accountId));
  }
  yield put(fetchAccountMappings(connectionId));
}

export default mapTagGroup;
