import { put, delay } from "redux-saga/effects";
import { fetchSettings } from "app.reducers/exchanges";
import { FIVE_SECONDS } from "app.constants";

function* pollSettings(action) {
  yield delay(FIVE_SECONDS);
  yield put(fetchSettings(action));
}

export default pollSettings;
