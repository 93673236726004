import { call, put, all } from "redux-saga/effects";
import {
  requestUpsertTagGroup,
  receiveUpsertTagGroup,
  upsertTagGroupError,
  fetchTagGroups,
} from "app.reducers/accountingIntegrations";

import { dismissModal } from "app.reducers/ui";

import ExchangesAPI from "app.api/ExchangesAPI";
import { getLoadingState } from "app.utils/selectors";

function* upsertTagGroups(action) {
  const { name, description, id } = action.payload;

  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.tags.upsertTagGroupStatus
  );

  if (isLoading) return;

  yield put(requestUpsertTagGroup(nextStatus));

  const response = yield call(
    ExchangesAPI.upsertTagGroup,
    name,
    description,
    id
  );

  if (response.error) {
    console.error("Error in fetchTagGroups", response.body, response.error);
    yield put(upsertTagGroupError(response.body.message));
  } else {
    yield all([
      put(receiveUpsertTagGroup()),
      put(fetchTagGroups()),
      put(dismissModal()),
    ]);
  }
}

export default upsertTagGroups;
