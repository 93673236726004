export const ledgerNameExists = (ledgerName, ledgers) => {
  if (ledgerName !== "") {
    for (let j = 0; j < ledgers.length; j += 1) {
      const ledger = ledgers[j];
      if (ledger?.userLedgerName?.trim() === ledgerName.trim()) {
        return true;
      }
    }
  }
  return false;
};
