import { put } from "redux-saga/effects";
import { setTokenList } from "app.actions/computables";

function* computeTokenList(allLedgers) {
  const tokenSet = new Map();

  for (let i = 0; i < allLedgers.length; i += 1) {
    const entry = allLedgers[i];
    const symbol = entry.currency.toUpperCase();
    const name = entry.ledger?.tokenName || symbol;

    if (!tokenSet.has(symbol)) {
      tokenSet.set(symbol, { symbol, name });
    }
  }

  yield put(setTokenList([...tokenSet.values()]));
}

export default computeTokenList;
