/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  connectExchangeViaApiKey,
  updateExchangeApiKey,
  fetchApiKeyVaults,
} from "app.reducers/exchanges";
import { dismissApiKeyModal, setModalActionCallback } from "app.reducers/ui";

import {
  APP_NAME,
  STATUS,
  HELP_INFO,
  API_KEY_PASSPHRASE,
  EXCHANGE_IDS,
  API_KEY,
  API_KEY_VAULTS,
  HAS_SUPPORT_LINKS,
} from "app.constants";
import ErrorMessage from "app.components/Util/ErrorMessage";
import HelpInfo from "app.components/Help/HelpInfo";
import Button from "app.components/Util/Button";
import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";
import Input from "app.components/Util/Form/Input";
import Label from "app.components/Util/Form/Label";

const ModalExchangeApiKeyPopUp = ({
  canDismissCallback,
  exchange,
  linkGUID,
  update,
  code,
  apiKeyOverScope,
  apiKeyRequired,
  apiKeyAccessDenied,
  apiKeyInvalidSignedRequest,
  alternateExchange,
  alternateExchangeCallback,
  callLedgerImportAfterHandleKey,
  ledgerIds,
  exchangeId,
  reLink,
}) => {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();

  const [apiKey, setApiKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [passphrase, setPassphrase] = useState("");
  const [nickname, setNickname] = useState("");
  const [autoImport, setAutoImport] = useState(true);

  const { apiKeySavingStatus, apiKeyError } = useSelector(
    (state) => state.exchanges
  );

  const { sourcesImporting } = useSelector((state) => state.computables);

  const currentlyInProgress =
    sourcesImporting[linkGUID] && sourcesImporting[linkGUID] === true;

  useEffect(() => {
    canDismissCallback(apiKeySavingStatus !== STATUS.WORKING);
  }, [canDismissCallback, apiKeySavingStatus]);

  const updateExchangeAPI = async (ex, link, key, secret, pass) => {
    // clean API keys of any whitespace
    await reduxDispatch(
      updateExchangeApiKey(
        ex,
        link,
        key.trim(),
        secret.trim(),
        pass.trim(),
        callLedgerImportAfterHandleKey,
        ledgerIds,
        exchangeId
      )
    );
  };

  const startConnectApiKeyVaultsExchange = (ex, key, secret) => {
    // clean API keys of any whitespace
    reduxDispatch(fetchApiKeyVaults(ex, key.trim(), secret.trim()));
  };

  const startConnectExchange = (ex, key, secret, pass, name, importAuto) => {
    // clean API keys of any whitespace
    reduxDispatch(
      connectExchangeViaApiKey(
        ex,
        key.trim(),
        secret.trim(),
        pass.trim(),
        name.trim(),
        importAuto
      )
    );
  };

  const handleCheckboxChange = (e) => {
    setAutoImport(e.target.checked);
  };

  const label = exchange?.label || "";
  let alternateExchangeBody = null;

  if (typeof alternateExchange !== "undefined") {
    alternateExchangeBody = (
      <p className="mx-4 text-sm">
        <Trans i18nKey="modals.youCanUploadYourTransactionHistory">
          <a
            href="#upload"
            className="text-link-blue text-link-blue-active hover:underline"
            onClick={(e) => {
              e.preventDefault();
              reduxDispatch(
                setModalActionCallback({
                  type: alternateExchangeCallback,
                  exchange: alternateExchange,
                })
              );

              dismissApiKeyModal();
            }}
          >
            {{ label }}
          </a>
        </Trans>
      </p>
    );
  }

  // default wording
  let header = update
    ? t("modals.updateYourApiKey", { label })
    : t("modals.connectWithLabel", { label });

  let message = (
    <p className="my-4 text-lg">
      <Trans i18nKey="modals.enterYourLabelApiKeyForApiViewTransactions">
        <strong />
        {{ label }}
        {{ appName: APP_NAME }}
      </Trans>
      <HelpInfo
        source={exchange.name}
        render={(entry) => {
          if (!entry) return null;
          return (
            <span>
              &nbsp;
              <Trans i18nKey="modals.helpHref">
                <a
                  href={entry}
                  target="_blank"
                  className="text-link-blue text-link-blue-active hover:underline"
                  rel="noopener noreferrer"
                >
                  text
                </a>
              </Trans>
            </span>
          );
        }}
      />
    </p>
  );

  // situational wording
  if (apiKeyOverScope || code === "apiKeyOverScope") {
    header = t("modals.newApiKeyRequired");
    message = (
      <p className="my-4 text-lg">
        {!HAS_SUPPORT_LINKS || !HELP_INFO[exchange.name] ? (
          t("modals.thePermissionsForYourExistingLabelApiKeyChanged", {
            appName: APP_NAME,
            label,
          })
        ) : (
          <Trans i18nKey="modals.thePermissionsForYourExistingLabelApiKeyChangedWithHelp">
            <span>
              <a
                href={HELP_INFO[exchange.name]}
                target="_blank"
                className="text-link-blue text-link-blue-active hover:underline"
                rel="noopener noreferrer"
              >
                text
              </a>
            </span>
            {{ appName: APP_NAME }}
            {{ label }}
          </Trans>
        )}
      </p>
    );
  }

  if (apiKeyRequired || code === "apiKeyRequired" || code === "apiKeyInvalid") {
    header = t("modals.apiKeyRequired");
    message = (
      <p className="my-4 text-lg">
        {!HAS_SUPPORT_LINKS || !HELP_INFO[exchange.name] ? (
          t("modals.yourExistingApiKeyHasExpiredOrBeenRemoved", {
            label,
          })
        ) : (
          <Trans i18nKey="modals.yourExistingApiKeyHasExpiredOrBeenRemovedWithHelp">
            <span>
              <a
                href={HELP_INFO[exchange.name]}
                target="_blank"
                className="text-link-blue text-link-blue-active hover:underline"
                rel="noopener noreferrer"
              >
                text
              </a>
            </span>
            {{ label }}
          </Trans>
        )}
      </p>
    );
  }

  if (apiKeyAccessDenied || code === "apiKeyAccessDenied") {
    header = t("modals.apiKeyHasInsufficientPermissions");
    message = (
      <p className="my-4 text-lg">
        {!HAS_SUPPORT_LINKS || !HELP_INFO[exchange.name] ? (
          t("modals.apiKeyHasInsufficientPermissionsMessage", { label })
        ) : (
          <Trans i18nKey="modals.apiKeyHasInsufficientPermissionsMessageWithHelp">
            <span>
              <a
                className="text-link-blue text-link-blue-active hover:underline"
                href={HELP_INFO[exchange.name]}
                target="_blank"
                rel="noopener noreferrer"
              >
                text
              </a>
            </span>
            {{ label }}
          </Trans>
        )}
      </p>
    );
  }

  if (apiKeyInvalidSignedRequest || code === "apiKeyInvalidSignedRequest") {
    header = t("modals.invalidPassphraseOrSecretKey");
    message = (
      <p className="my-4 text-lg">
        {t("modals.theApiKeyForThisUserHasAnInvalidPassphrase")}
      </p>
    );
  }

  const disableSubmit =
    apiKeySavingStatus === STATUS.WORKING ||
    typeof exchange === "undefined" ||
    apiKey.trim() === "" ||
    secretKey.trim() === "" ||
    (exchange.integrationType === API_KEY_PASSPHRASE &&
      passphrase.trim() === "");

  return (
    <>
      <ModalHeader
        title={header}
        closeCallback={(e) => {
          e.preventDefault();
          reduxDispatch(dismissApiKeyModal());
        }}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (disableSubmit) return;
          e.preventDefault();
          if (update) {
            updateExchangeAPI(
              exchange,
              linkGUID,
              apiKey,
              secretKey,
              passphrase
            );
          } else if (exchange.integrationType === API_KEY_VAULTS) {
            startConnectApiKeyVaultsExchange(exchange, apiKey, secretKey);
          } else {
            startConnectExchange(
              exchange,
              apiKey,
              secretKey,
              passphrase,
              nickname,
              autoImport &&
                exchange.integrationType === API_KEY &&
                !currentlyInProgress
            );
          }
        }}
      >
        <div className="m-4">
          {apiKeySavingStatus === STATUS.ERROR ? (
            <ErrorMessage {...apiKeyError} />
          ) : null}
          {message}
          {exchange.name === EXCHANGE_IDS.KRAKEN ? (
            <p className="my-4 rounded-sm bg-red-100 px-2 py-1 text-xs text-red-700">
              {t("constants.exchanges.krakenMarginTrade")}
            </p>
          ) : null}
          {exchange.name === EXCHANGE_IDS.OKX ? (
            <p className="my-4 rounded-sm bg-red-100 px-2 py-1 text-xs text-red-700">
              {t("constants.exchanges.okxLimitations")}
            </p>
          ) : null}
          {exchange.name === EXCHANGE_IDS.KUCOIN ? (
            <p className="my-4 rounded-sm bg-red-100 px-2 py-1 text-xs text-red-700">
              {t("constants.exchanges.kucoinLimitations")}
            </p>
          ) : null}
          {exchange.name === EXCHANGE_IDS.BTSE ? (
            <p className="my-4 rounded-sm bg-red-100 px-2 py-1 text-xs text-red-700">
              {t("constants.exchanges.btseLimitations")}
            </p>
          ) : null}
          {exchange.name === EXCHANGE_IDS.BINANCE ? (
            <p className="my-4 rounded-sm bg-red-100 px-2 py-1 text-xs text-red-700">
              {t("constants.exchanges.binanceLimitations")}
            </p>
          ) : null}

          <div className="mb-4 flex flex-col">
            <Label htmlFor="apiKey">{t("input.label.apiKey")}</Label>
            <Input
              data-tabbable
              type="text"
              data-testid="apiKey"
              id="apiKey"
              name="apiKey"
              autoComplete="off"
              value={apiKey}
              onChange={(e) => {
                e.preventDefault();
                setApiKey(e.target.value);
              }}
            />
          </div>
          <div className="mb-4 flex flex-col">
            <Label htmlFor="secretKey">{t("input.label.secretKey")}</Label>
            <Input
              data-tabbable
              type="text"
              data-testid="secretKey"
              id="secretKey"
              name="secretKey"
              autoComplete="off"
              value={secretKey}
              onChange={(e) => {
                e.preventDefault();
                setSecretKey(e.target.value);
              }}
            />
          </div>
          {exchange.integrationType === API_KEY_PASSPHRASE ? (
            <div className="mb-4 flex flex-col">
              <Label htmlFor="passphrase">{t("input.label.passphrase")}</Label>
              <Input
                data-tabbable
                type="text"
                data-testid="passphrase"
                id="passphrase"
                name="passphrase"
                autoComplete="off"
                value={passphrase}
                onChange={(e) => {
                  e.preventDefault();
                  setPassphrase(e.target.value);
                }}
              />
            </div>
          ) : null}
          {(exchange.integrationType === API_KEY ||
            exchange.integrationType === API_KEY_PASSPHRASE) &&
          !reLink ? (
            <div className="mb-4 flex flex-col">
              <Label htmlFor="nickname" optional>
                {t("input.label.nickname")}
              </Label>
              <Input
                data-tabbable
                type="text"
                data-testid="nickname"
                id="nickname"
                name="nickname"
                autoComplete="off"
                value={nickname}
                onChange={(e) => {
                  e.preventDefault();
                  setNickname(e.target.value);
                }}
              />
            </div>
          ) : null}
          {exchange.integrationType === API_KEY &&
          !update &&
          !currentlyInProgress ? (
            <label
              htmlFor="autoImport"
              className="mb-4 flex items-center text-sm"
            >
              <input
                type="checkbox"
                data-testid="autoImport"
                id="autoImport"
                name="autoImport"
                checked={autoImport}
                onChange={handleCheckboxChange}
              />
              <span className="ml-2">{t("input.label.autoImport")}</span>
            </label>
          ) : null}
        </div>
        {alternateExchangeBody}

        <ModalControls>
          <Button
            type="submit"
            data-tabbable
            buttonType="primary"
            customClasses="ml-4"
            disabled={disableSubmit}
            text={t("button.save")}
            workingText={t("button.saving")}
            working={apiKeySavingStatus === STATUS.WORKING}
          />
          <Button
            buttonType="text"
            data-tabbable
            disabled={apiKeySavingStatus === STATUS.WORKING}
            onClick={(e) => {
              e.preventDefault();
              reduxDispatch(dismissApiKeyModal());
            }}
            text={t("button.cancel")}
          />
        </ModalControls>
      </form>
    </>
  );
};

ModalExchangeApiKeyPopUp.displayName = "ModalExchangeApiKeyPopUp";

export default ModalExchangeApiKeyPopUp;
