import { call, all, put, select, delay } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import { dismissModal } from "app.reducers/ui";

import {
  fetchTagsAndRules,
  requestBulkCreateTag,
  receiveBulkCreateTag,
  bulkCreateTagError,
  resetBulkCreate,
} from "app.reducers/tags";

import { fetchTimeline, fetchExchangeLedger } from "app.actions/exchangeLedger";

import { FIVE_SECONDS } from "app.constants";

function* bulkCreateTag(action) {
  const {
    ledgerItemIds,
    tag,
    isTimeline = true,
    colorId,
    conditions,
    description,
    skipDataReload = false,
  } = action.payload;

  const lastRequestParams = yield select(
    (state) => state.exchangeLedger.lastRequestParams
  );

  yield put(requestBulkCreateTag());

  const response = yield call(
    ExchangeLedgerAPI.bulkCreateLedgerTag,
    ledgerItemIds,
    tag,
    colorId,
    conditions,
    description
  );

  const { body, error } = response;
  if (error) {
    yield put(bulkCreateTagError(body));
  } else {
    const noLoading = true;
    let itemsFetch = null;

    if (lastRequestParams && !skipDataReload) {
      itemsFetch = isTimeline
        ? fetchTimeline(...lastRequestParams, noLoading)
        : fetchExchangeLedger(...lastRequestParams, noLoading);
    }

    yield all(
      [
        put(receiveBulkCreateTag(body)),
        itemsFetch ? put(itemsFetch) : null,
        put(dismissModal()),
        put(fetchTagsAndRules()),
      ].filter(Boolean)
    );

    yield delay(FIVE_SECONDS);
    yield put(resetBulkCreate());
  }
}

export default bulkCreateTag;
