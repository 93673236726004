import { call, put, select, all } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";
import {
  requestManualJournalTransaction,
  receiveManualJournalTransaction,
  receiveManualJournalTransactionError,
  fetchExchangeLedger,
} from "app.actions/exchangeLedger";
import { fetchLedgers } from "app.reducers/exchanges";

import { getConvertedHour } from "app.utils/dates";
import { fromZonedTime } from "date-fns-tz";
import i18n from "i18next";
import { ACCOUNT_STATUS } from "app.constants/loadingStates";

function* createManualJournalTransaction(action) {
  const { ledgerId, state } = action;

  const {
    type,
    date,
    time,
    amount,
    feeAmount,
    description,
    unitPrice,
    hash,
    address,
  } = state;

  yield put(requestManualJournalTransaction(ACCOUNT_STATUS.UPDATING));

  const hour = getConvertedHour(time.hour, time.period);

  const tzDateTime = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    hour,
    parseInt(time.minutes, 10),
    parseInt(time.seconds, 10)
  );

  const tzAdjustedDateTime = fromZonedTime(tzDateTime, time.timeZone);

  const body = {
    transactionForms: [
      {
        type,
        dateTime: tzAdjustedDateTime.toISOString(),
        amount: parseFloat(amount),
        feeAmount: parseFloat(feeAmount),
        description,
        unitPrice: parseFloat(unitPrice),
        hash,
        address,
      },
    ],
  };

  const response = yield call(
    ExchangeLedgerAPI.manualTransactionCreate,
    ledgerId,
    body
  );

  if (response.error) {
    const errorMessage =
      response.body.message ||
      i18n.t("constants.error.createManualJournalTransaction.default");

    yield put(
      receiveManualJournalTransactionError(errorMessage, ACCOUNT_STATUS.ERROR)
    );
  } else {
    yield put(receiveManualJournalTransaction(ACCOUNT_STATUS.UPDATED));

    const lastRequestParams = yield select(
      (s) => s.exchangeLedger.lastRequestParams
    );

    const noLoading = false;
    yield all([
      put(fetchExchangeLedger(...lastRequestParams, noLoading)),
      put(fetchLedgers(true)), // true for oneTime so this doesn't trigger a duplicate recurring call to fetch ledgers.
    ]);
  }
}

export default createManualJournalTransaction;
