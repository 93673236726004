import React from "react";
import EnrollHeader from "app.components/Enroll/EnrollHeader";
import LoadingSpinner from "app.components/Util/LoadingSpinner";

const EnrollPlaceholder = () => {
  return (
    <div className="bg-enroll-bg flex min-h-screen flex-col pt-6">
      <div className="grow pb-12">
        <EnrollHeader />
        <div className="flex justify-center">
          <div className="relative max-w-[46rem] rounded-sm px-8 md:w-8/12 md:bg-white md:p-8">
            <div className="my-16 flex w-full justify-center">
              <LoadingSpinner />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnrollPlaceholder;
