import { call, put, all } from "redux-saga/effects";
import {
  migrateViaUploadError,
  requestMigrateViaUpload,
  receiveMigrateViaUpload,
} from "app.reducers/exchanges";
import { doRedirect } from "app.reducers/ui";
import ExchangesAPI from "app.api/ExchangesAPI";

import { getSortBy } from "app.utils";

function* migrateViaUpload(action) {
  const { files, exchangeId } = action.payload;

  yield put(requestMigrateViaUpload());

  const response = yield call(ExchangesAPI.migrateViaUpload, files, exchangeId);

  const { body, error } = response;

  if (error) {
    let returnError = error;
    if (error === 500) {
      returnError = {
        code: 500,
        message: "We were unable to find any sources in the uploaded file.",
      };
    }
    yield put(migrateViaUploadError(body, returnError));
  } else {
    const sortedSources = body.sources.sort(
      getSortBy((x) => x.label.toLowerCase(), true)
    );

    yield all([
      put(receiveMigrateViaUpload(exchangeId, body.responseId, sortedSources)),
      put(doRedirect(`/portfolio/add-ledger/migrate/${exchangeId}`)),
    ]);
  }
}
export default migrateViaUpload;
