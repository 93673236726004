import { call, put, all } from "redux-saga/effects";
import {
  requestMigrateSourcesViaUpload,
  receiveMigrateSourcesViaUpload,
  receiveMigrateSourcesViaUploadError,
  fetchLedgers,
} from "app.reducers/exchanges";
import { doRedirect } from "app.reducers/ui";
import ExchangesAPI from "app.api/ExchangesAPI";

function* migrateSources(action) {
  const { exchange, responseId, sources } = action.payload;

  yield put(requestMigrateSourcesViaUpload());

  const response = yield call(ExchangesAPI.migrateSources, responseId, sources);

  const { body, error } = response;
  if (error) {
    yield put(receiveMigrateSourcesViaUploadError(body, error));
  } else {
    yield all([
      put(receiveMigrateSourcesViaUpload(exchange.id, body)),
      put(fetchLedgers()),
      put(doRedirect(`/portfolio/add-ledger/confirm/${exchange.groupKey}`)),
    ]);
  }
}

export default migrateSources;
