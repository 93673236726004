import { takeEvery, takeLeading } from "redux-saga/effects";
import * as accountingIntegrations from "app.reducers/accountingIntegrations";

import connectAccountingOAuth from "./connectAccountingOAuth";
import fetchAccountingIntegrationsConnections from "./fetchAccountingIntegrationsConnections";
import fetchChartOfAccounts from "./fetchChartOfAccounts";
import fetchTagGroups from "./fetchTagGroups";
import upsertTagGroup from "./upsertTagGroup";
import assignTagToGroup from "./assignTagToGroup";
import removeTagFromGroup from "./removeTagFromGroup";
import deleteTagGroup from "./deleteTagGroup";
import mapTokenToAccount from "./mapTokenToAccount";
import fetchAccountMappings from "./fetchAccountMappings";
import mapDefaultAccount from "./mapDefaultAccount";
import mapTagGroup from "./mapTagGroup";
import accountingIntegrationSync from "./accountingIntegrationSync";
import deleteGroupMapping from "./deleteGroupMapping";
import bulkMapTokenAccounts from "./bulkMapTokenAccounts";
import disconnectIntegration from "./disconnectIntegration";
import mapCounterparty from "./mapCounterparty";

// WATCHERS
const accountingIntegrationsSagas = [
  takeLeading(
    accountingIntegrations.fetchAccountingIntegrationsConnections,
    fetchAccountingIntegrationsConnections
  ),
  takeLeading(
    accountingIntegrations.fetchChartOfAccounts,
    fetchChartOfAccounts
  ),
  takeLeading(accountingIntegrations.fetchTagGroups, fetchTagGroups),
  takeLeading(
    accountingIntegrations.fetchAccountMappings,
    fetchAccountMappings
  ),
  takeEvery(
    accountingIntegrations.connectAccountingOAuth,
    connectAccountingOAuth
  ),
  takeEvery(accountingIntegrations.upsertTagGroup, upsertTagGroup),
  takeEvery(accountingIntegrations.addTagToGroup, assignTagToGroup),
  takeEvery(accountingIntegrations.removeTagFromGroup, removeTagFromGroup),
  takeEvery(accountingIntegrations.deleteTagGroup, deleteTagGroup),
  takeEvery(accountingIntegrations.mapTokenToAccount, mapTokenToAccount),
  takeEvery(accountingIntegrations.mapDefaultAccount, mapDefaultAccount),
  takeEvery(accountingIntegrations.mapTagGroup, mapTagGroup),
  takeEvery(accountingIntegrations.deleteGroupMapping, deleteGroupMapping),
  takeEvery(
    accountingIntegrations.accountingIntegrationSync,
    accountingIntegrationSync
  ),
  takeEvery(accountingIntegrations.bulkMapTokenAccounts, bulkMapTokenAccounts),
  takeEvery(
    accountingIntegrations.disconnectIntegration,
    disconnectIntegration
  ),
  takeEvery(accountingIntegrations.mapCounterparty, mapCounterparty),
];

// export all watcher sagas as an array to be composed in the top level
// root saga
export default accountingIntegrationsSagas;
