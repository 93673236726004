import React from "react";
import { useDispatch } from "react-redux";
import { addFlashMessage, removeFlashMessage } from "app.reducers/ui";
import { generateGUID } from "app.utils";

import SvgLoader from "app.components/Util/SvgLoader";
import { HALF_MINUTE } from "app.constants";

export const STATUS_SUCCESS = "success";
export const STATUS_WARNING = "warning";
export const STATUS_DANGER = "danger";
export const STATUS_INFO = "info";

const icons = {
  success: (
    <SvgLoader
      id="Checkmark"
      data-testid="checkmark-icon"
      className="h-5 w-5 stroke-green-600"
    />
  ),
  danger: (
    <SvgLoader
      id="Warning"
      data-testid="warning-icon"
      className="h-5 w-5 fill-red-700"
    />
  ),
  warning: (
    <SvgLoader
      id="Warning"
      data-testid="warning-icon"
      className="h-5 w-5 fill-amber-400"
    />
  ),
  info: (
    <SvgLoader
      id="CircleInfo"
      data-testid="circle-info-icon"
      className="h-5 w-5 fill-blue-600"
    />
  ),
};

const FlashMessage = React.forwardRef(
  ({ status, message, closeCallback }, ref) => {
    const flashClass = [
      "pointer-events-auto",
      "w-96",
      "rounded-sm",
      "p-4",
      "shadow-lg",
      "border",
      "border-zinc-50",
      "bg-white",
      "flex",
      "mb-4",
      status === STATUS_SUCCESS ? "" : null,
      status === STATUS_WARNING ? "" : null,
      status === STATUS_INFO ? "" : null,
      status === STATUS_DANGER ? "" : null,
    ]
      .filter(Boolean)
      .join(" ");

    // a simple message has a _slightly_ different alignment for the close button
    const isSimpleMessage = typeof message === "string";
    const messageContent = isSimpleMessage ? (
      <p className="-mt-0.5 text-sm text-black/80">{message}</p>
    ) : (
      message.body
    );
    const icon =
      isSimpleMessage || typeof message.SvgLoader === "undefined"
        ? icons[status]
        : message.SvgLoader;
    let content = null;

    if (message) {
      content = (
        <div ref={ref} className={flashClass}>
          <div className="mr-4 flex basis-5 items-start">{icon}</div>
          <div className="flex-2">{messageContent}</div>
          {typeof closeCallback !== "undefined" ? (
            <div className="ml-auto flex items-start pl-4">
              <button
                type="button"
                className="group"
                data-tabbable
                onClick={(event) => {
                  event.preventDefault();
                  closeCallback();
                }}
              >
                <SvgLoader
                  id="Close"
                  className="h-4 w-4 stroke-zinc-400 transition-all group-hover:stroke-zinc-600"
                />
              </button>
            </div>
          ) : null}
        </div>
      );
    }

    return content;
  }
);

export const useFlashMessage = () => {
  const reduxDispatch = useDispatch();

  const sendErrorMessage = (message) => {
    const messageId = generateGUID();
    reduxDispatch(addFlashMessage(message, messageId, STATUS_DANGER));

    setTimeout(() => {
      reduxDispatch(removeFlashMessage(messageId));
    }, HALF_MINUTE);
  };

  return { sendErrorMessage };
};

FlashMessage.displayName = "FlashMessage";

export default FlashMessage;
