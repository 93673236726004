import api from "app.api/clients/exchange";

/**
 * Submits a request to get the available conditions and colors for creating tags
 *
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getTagsMetadata = () => {
  return api.get(`/tag/conditional/metadata`, {
    authenticated: true,
  });
};

/**
 * Submits a request to get the available conditions and colors for creating tags
 *
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getTagsAndRules = () => {
  return api.get(`/tag/userConditions`, {
    authenticated: true,
  });
};

/**
 * Submits a request to create a tag
 *
 * @async
 * @function
 * @param {array} ledgerItemIds - The ids of the ledger items
 * @param {string} tag - The id of the transaction/item
 * @returns {object}  response - The Response or Error message
 */
export const bulkCreateLedgerTag = (
  ledgerItemIds,
  tag,
  colorId,
  conditions,
  description
) => {
  const data = {
    tag,
    colorId: colorId || 1,
  };

  if (ledgerItemIds) {
    data.ledgerItemIds = ledgerItemIds;
  }

  if (ledgerItemIds) {
    data.ledgerItemIds = ledgerItemIds;
  }

  if (conditions) {
    data.conditions = conditions;
  }

  if (description) {
    data.description = description;
  }

  return api.post(`/tag/bulk/create`, {
    authenticated: true,
    data,
  });
};

/**
 * Submits a request to create a tag
 *
 * @async
 * @function
 * @param {array} ledgerItemIds - The ids of the ledger items
 * @param {string} tag - The id of the transaction/item
 * @returns {object}  response - The Response or Error message
 */
export const bulkDeleteLedgerTag = (ledgerItemId, conditionId, tag) => {
  const data = {};

  if (ledgerItemId) {
    data.ledgerItemIds = [ledgerItemId];
  }

  if (conditionId) {
    data.conditionId = conditionId;
  }

  if (tag) {
    data.tag = tag;
  }

  return api.post(`/tag/bulk/delete`, {
    authenticated: true,
    data,
  });
};

/**
 * Submits a request to update a tag
 *
 * @async
 * @function
 * @param {string} tag - The id of the transaction/item
 * @returns {object}  response - The Response or Error message
 */
export const editTag = (tag, newTagName, colorId, description) => {
  const data = {
    tag,
    newTagName,
    colorId,
  };

  if (description) {
    data.description = description;
  }

  return api.post(`/tag/update`, {
    authenticated: true,
    data,
  });
};
