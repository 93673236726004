import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { dismissModal } from "app.reducers/ui";
import { editTag } from "app.reducers/tags";

import { useTranslation } from "react-i18next";
import Button from "app.components/Util/Button";
import Label from "app.components/Util/Form/Label";
import Input from "app.components/Util/Form/Input";

import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";

import { STATUS } from "app.constants";

function ModalEditTag({ tag }) {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();
  const [tagText, setTagText] = useState(tag.tag);
  const [tagColor, setTagColor] = useState(tag.colorId);

  const { tags, colors, editStatus } = useSelector((state) => state.tags);

  const isExistingTag =
    tags.findIndex((curTag) => tagText === curTag.tag) > -1 &&
    tagText !== tag.tag;

  const errorMsg = isExistingTag ? t("portfolio.tags.tagExists") : "";

  const hasChanged = tag.colorId !== tagColor || tagText !== tag.tag;

  const disabled = !hasChanged || isExistingTag;

  const handleDismiss = (e) => {
    e.preventDefault();
    reduxDispatch(dismissModal());
  };

  const submitEditTag = () => {
    reduxDispatch(editTag(tag.tag, tagText, tagColor, tag.description));
  };

  return (
    <>
      <ModalHeader
        title={t("modals.editTag.editTag")}
        closeCallback={handleDismiss}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitEditTag();
        }}
      >
        <div className="m-4">
          <p className="mb-4 text-lg">
            {t("modals.editTag.editTagDescription")}
          </p>
        </div>

        <div data-component="new-tag" className="m-4 flex flex-col items-start">
          <div className="flex gap-4">
            <div className="relative flex w-48 flex-col">
              <Label error={errorMsg} htmlFor="tag">
                {t("portfolio.tags.tag")}
              </Label>
              <Input
                type="text"
                hasError={isExistingTag}
                name="tag"
                id="tag"
                maxLength="48"
                data-testid="tag"
                value={tagText}
                className="w-fill"
                onChange={(e) => {
                  setTagText(e.target.value);
                }}
              />
            </div>
            <div className="flex flex-col">
              <p className="text-sm leading-5 font-bold">
                {t("portfolio.tags.color")}
              </p>
              <ul className="mb-1 flex h-8 items-center gap-2 md:mb-0">
                {colors.map((c) => {
                  let className = "h-4 w-4 rounded-sm";
                  if (tagColor === c.id) {
                    className = "h-4 w-4 rounded-sm ring-2 ring-black";
                  }

                  return (
                    <li key={c.hex} className="flex h-4 items-center">
                      <button
                        type="button"
                        className={className}
                        onClick={() => {
                          setTagColor(c.id);
                        }}
                        style={{ backgroundColor: c.hex }}
                      >
                        <span className="sr-only">{c.label}</span>
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <ModalControls>
          <Button
            type="submit"
            disabled={disabled || editStatus === STATUS.WORKING}
            buttonType="primary"
            customClasses="ml-4"
            text={t("button.save")}
          />
          <Button
            onClick={handleDismiss}
            disabled={editStatus === STATUS.WORKING}
            buttonType="text"
            text={t("common.close")}
          />
        </ModalControls>
      </form>
    </>
  );
}

ModalEditTag.displayName = "ModalEditTag";

export default ModalEditTag;
