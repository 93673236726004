import {
  SET_LEDGERS_BY_SOURCE,
  SET_LEDGERS_CREATING,
  SET_LEDGERS_UPDATING,
  SET_SMART_WALLET_CHART_DATA,
  SET_LEDGERS_NEGATIVE,
  SET_SOURCES_IMPORTING,
  SET_FAVORITE_LEDGERS,
  SET_READY_LEDGERS_COUNT,
  SET_READY_LEDGERS,
  SET_TOKEN_LIST,
} from "app.actions/computables";

import { EXCHANGE_LEDGER_IMPORT_REQUEST } from "app.actions/exchangeLedger";
import { requestUpdateExchangeAllLedgers } from "app.reducers/exchanges";
import { reinitializePortfolio } from "app.reducers/portfolios";

const initialState = {
  ledgersBySource: {},
  ledgersCreating: [],
  ledgersUpdating: [],
  ledgersWithNegativeBalance: [],
  smartWalletChartData: [],
  favoriteLedgers: [],
  smartWalletPortfilioSummary: {},
  sourcesImporting: {},
  readyLedgers: [],
  readyLedgersCount: 0,
  tokenList: [],
};

// eslint-disable-next-line default-param-last
const computables = (state = initialState, action) => {
  switch (action.type) {
    case reinitializePortfolio.type: {
      return initialState;
    }
    case SET_LEDGERS_BY_SOURCE: {
      return { ...state, ledgersBySource: action.ledgersBySource };
    }
    case SET_LEDGERS_CREATING: {
      return { ...state, ledgersCreating: action.ledgersCreating };
    }
    case SET_LEDGERS_UPDATING: {
      return { ...state, ledgersUpdating: action.ledgersUpdating };
    }
    case SET_SOURCES_IMPORTING: {
      return { ...state, sourcesImporting: action.sourcesImporting };
    }
    case SET_LEDGERS_NEGATIVE: {
      return {
        ...state,
        ledgersWithNegativeBalance: action.ledgersWithNegativeBalance,
      };
    }
    case SET_SMART_WALLET_CHART_DATA: {
      return {
        ...state,
        smartWalletChartData: action.smartWalletChartData,
        smartWalletPortfilioSummary: action.smartWalletPortfilioSummary,
      };
    }
    case EXCHANGE_LEDGER_IMPORT_REQUEST:
    case requestUpdateExchangeAllLedgers.type: {
      return {
        ...state,
        sourcesImporting: { ...state.sourcesImporting, [action.source]: true },
      };
    }
    case SET_FAVORITE_LEDGERS: {
      return {
        ...state,
        favoriteLedgers: action.favoriteLedgers,
      };
    }
    case SET_READY_LEDGERS_COUNT: {
      return {
        ...state,
        readyLedgersCount: action.readyLedgersCount,
      };
    }
    case SET_READY_LEDGERS: {
      return {
        ...state,
        readyLedgers: action.readyLedgers,
      };
    }
    case SET_TOKEN_LIST: {
      return {
        ...state,
        tokenList: action.tokenList,
      };
    }
    default:
      return state;
  }
};

export default computables;
