import { call, put, all } from "redux-saga/effects";
import {
  requestConnectExchangeViaApiKey,
  receiveConnectExchangeViaApiKeyError,
  receiveConnectExchangeViaApiKey,
  createExchangeLedgers,
} from "app.reducers/exchanges";
import { completeApiKeyModal } from "app.reducers/ui";
import ExchangesAPI from "app.api/ExchangesAPI";
import { FIFO } from "app.constants";

function* connectExchangeViaAPI(action) {
  const { exchange, apiKey, secretKey, passphrase, nickname, autoImport } =
    action.payload;

  yield put(requestConnectExchangeViaApiKey());

  const response = yield call(
    ExchangesAPI.setExchangeApiKey,
    exchange.id,
    apiKey,
    secretKey,
    nickname,
    passphrase
  );

  const { body, error } = response;
  if (error) {
    if (typeof body.metadata !== "undefined") {
      yield put(
        receiveConnectExchangeViaApiKeyError(
          { type: "bad-key", message: body.metadata.message },
          error
        )
      );
    } else {
      yield put(receiveConnectExchangeViaApiKeyError(body, error));
    }
  } else {
    yield all(
      [
        put(completeApiKeyModal(body.linkGUID, autoImport)),
        put(receiveConnectExchangeViaApiKey(body)),
        autoImport
          ? put(createExchangeLedgers(exchange.id, body.linkGUID, [], FIFO))
          : null,
      ].filter(Boolean)
    );
  }
}

export default connectExchangeViaAPI;
