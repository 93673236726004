import React from "react";
import LedgerTablePlaceholder from "app.components/Placeholder/LedgerTablePlaceholder";

const LedgerDataPlaceholder = () => {
  return (
    <div className="mt-4 rounded-sm shadow-sm">
      <LedgerTablePlaceholder />
    </div>
  );
};

LedgerDataPlaceholder.displayName = "LedgerDataPlaceholder";

export default LedgerDataPlaceholder;
