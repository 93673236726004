import { call, put, select } from "redux-saga/effects";
import {
  requestMapTokenToAccount,
  receiveMapTokenToAccount,
  mapTokenToAccountError,
  fetchAccountMappings,
} from "app.reducers/accountingIntegrations";

import ExchangesAPI from "app.api/ExchangesAPI";
import { STATUS } from "app.constants";

function* mapTokenToAccount(action) {
  const { smartWalletGUID, tokenAccountId, connectionId, mapId } =
    action.payload;

  const { accountMappingStatus } = yield select(
    (state) => state.accountingIntegrations
  );

  if (accountMappingStatus === STATUS.WORKING) return;

  yield put(requestMapTokenToAccount());

  const response = yield call(
    ExchangesAPI.tokenAccountMapping,
    smartWalletGUID,
    tokenAccountId,
    connectionId,
    mapId
  );

  if (response.error) {
    console.error("Error in mapTokenToAccount", response.body, response.error);
    yield put(mapTokenToAccountError(response.body.message));
  } else {
    yield put(receiveMapTokenToAccount());
  }
  yield put(fetchAccountMappings(connectionId));
}

export default mapTokenToAccount;
