import { put, all } from "redux-saga/effects";
import * as exchangeActions from "app.reducers/exchanges";
import * as smartWalletActions from "app.reducers/smartWallet";
import * as filesActions from "app.reducers/files";
import * as uiActions from "app.reducers/ui";
import * as tagsActions from "app.reducers/tags";

function* enterPortfolio() {
  yield all([
    put(exchangeActions.fetchSupportedExchanges()),
    put(exchangeActions.fetchLinkedExchanges()),
    put(exchangeActions.fetchLedgers()),
    put(smartWalletActions.pollSmartWalletList()),
    put(uiActions.fetchTimelineLedgersRange()),
  ]);

  // secondary data, app may render when the above are finished,
  // then these can be dispatched
  yield all([
    put(filesActions.fetchFiles()),
    put(uiActions.fetchCustomFieldsData()),
    put(tagsActions.fetchTagsAndRules()),
    put(tagsActions.fetchTagMetadata()),
  ]);
}

export default enterPortfolio;
