import { call, put, select } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";
import { doRedirect } from "app.reducers/ui";

import {
  requestExchangeLedgerSummary,
  receiveExchangeLedgerSummary,
} from "app.actions/exchangeLedger";

import { STATUS } from "app.constants";
import { getLoadingState } from "app.utils/selectors";
import { parseRangeToISO } from "app.utils/dates";

function* fetchExchangeLedgerSummary(action) {
  const { ledgerId, key } = action;

  const { user } = yield select((state) => state.user);

  const range = parseRangeToISO(action.range, user?.preference?.timeZone);

  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.exchangeLedger.ledgerSummaryStatus
  );

  if (isLoading) return;

  yield put(requestExchangeLedgerSummary(nextStatus));

  const response = yield call(
    ExchangeLedgerAPI.getExchangeLedgerSummary,
    ledgerId,
    range
  );
  if (response.error) {
    console.error(
      "Error in fetchExchangeLedgerSummary",
      response.body,
      response.error
    );

    if (response.error === 404) {
      const lastPortfolioTab = yield select(
        (state) => state.ui.lastPortfolioTab
      );
      yield put(doRedirect(lastPortfolioTab));
    }
  } else {
    yield put(
      receiveExchangeLedgerSummary(response.body, range, key, STATUS.LOADED)
    );
  }
}

export default fetchExchangeLedgerSummary;
