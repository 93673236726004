import { call, put, all } from "redux-saga/effects";

import {
  requestRenameLinkedAccount,
  renameLinkedAccountError,
  receiveRenameLinkedAccount,
  fetchLinkedExchanges,
} from "app.reducers/exchanges";
import { dismissModal } from "app.reducers/ui";

import ExchangesAPI from "app.api/ExchangesAPI";

function* renameLinkedAccount(action) {
  const { nickname, linkedAccountId } = action.payload;

  yield put(requestRenameLinkedAccount());

  const response = yield call(
    ExchangesAPI.renameLinkedAccount,
    linkedAccountId,
    nickname
  );

  if (response.error) {
    yield put(
      renameLinkedAccountError(
        { code: response.error, message: response.body.errors[0] },
        response.error
      )
    );
  } else {
    yield all([
      put(receiveRenameLinkedAccount(linkedAccountId, nickname)),
      put(fetchLinkedExchanges()),
      put(dismissModal()),
    ]);
  }
}

export default renameLinkedAccount;
