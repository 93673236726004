import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Button from "app.components/Util/Button";
import { upgradePlan, clearUpgradePlanError } from "app.actions/account";
import { lookupTranslation } from "app.utils";
import {
  BILLING_ERROR_PATH,
  BILLING_ERROR_DEFAULT,
  CARD_ELEMENT_CONFIG,
  ACCOUNT_STATUS,
} from "app.constants";

import { getPlanDetails } from "app.constants/plans";

import LoadingSpinner from "app.components/Util/LoadingSpinner";
import CashMoney from "app.components/Util/CashMoney";

import Label from "app.components/Util/Form/Label";
import Input from "app.components/Util/Form/Input";

import { useTranslation, Trans } from "react-i18next";

const getButtonText = (isDowngrade, isChangePeriod) => {
  if (isDowngrade) {
    return "button.completeDowngrade";
  }
  if (isChangePeriod) {
    return "button.changePlan";
  }
  return "button.completePurchase";
};

const getWorkingText = (isDowngrade, isChangePeriod) => {
  if (isDowngrade) {
    return "button.finalizingDowngrade";
  }
  if (isChangePeriod) {
    return "button.changingPlan";
  }
  return "button.finalizingPurchase";
};

function getCurrentPlan(currentSubscription, plans) {
  const planId = currentSubscription?.planId;

  if (!plans?.length) return undefined;

  for (let i = 0; i < plans.length; i += 1) {
    if (plans[i].planId === planId) {
      return plans[i];
    }
    const subPlans = plans[i].subPlans || [];
    for (let j = 0; j < subPlans.length; j += 1) {
      if (subPlans[j].planId === planId) {
        return subPlans[j];
      }
    }
  }
  return undefined;
}

const PAYMENT_STEPS = {
  BILLING_INFO: "billing-info",
  REVIEW: "review",
};

function PaymentForm({
  useExistingBillingInformation,
  setUseExistingBillingInformation,
  setNewZipCode,
  newPlan,
}) {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const reduxDispatch = useDispatch();

  const {
    upgradeQuote,
    upgradePlanStatus,
    upgradePlanError,
    upgradeQuoteStatus,
    billingInformation,
    currentSubscription,
    plans,
  } = useSelector((state) => state.account);

  const currentPlan = getCurrentPlan(currentSubscription, plans);
  const [currentPlanInterval] = useState(currentPlan?.interval);
  const [currentPlanEndDate] = useState(currentSubscription?.endDate);
  const planDetails = getPlanDetails(currentPlan?.planId);
  const newPlanDetails = getPlanDetails(newPlan.planId);

  // does a user already have billing information?
  const hasBillingInformation =
    billingInformation !== null && typeof billingInformation !== "undefined";

  const [cardholderName, setCardholderName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [stripeStatus, setStripeStatus] = useState(undefined);
  const [validatingCard, setValidatingCard] = useState(false);

  const [stripeToken, setStripeToken] = useState(undefined);
  const [downgradeConfirm, setDowngradeConfirm] = useState(false);
  const [changePeriodConfirm, setChangePeriodConfirm] = useState(false);

  // initial step
  const initialStep = hasBillingInformation
    ? PAYMENT_STEPS.REVIEW
    : PAYMENT_STEPS.BILLING_INFO;

  const [step, setStep] = useState(initialStep);

  const taxPercent = upgradeQuote?.taxPercent || 0;
  const taxText = taxPercent > 0;

  const isLoading = upgradePlanStatus === ACCOUNT_STATUS.LOADING;
  const isUpgradeQuoteLoaded = upgradeQuoteStatus === ACCOUNT_STATUS.LOADED;

  const waiting = isLoading || !isUpgradeQuoteLoaded;
  // if the new plan is the same tier, but a different billing interval
  const isChangePeriod = newPlan?.planRanking === currentPlan?.planRanking;
  // if the new plan is flagged a downgrade and the tiers do not match
  const isDowngrade =
    upgradeQuote?.prorationType === "downgrade" && !isChangePeriod;

  const renewDate = currentPlanEndDate;

  const paymentInfoComplete =
    useExistingBillingInformation ||
    (cardholderName.length > 0 &&
      zipCode.length > 0 &&
      typeof stripeStatus !== "undefined" &&
      stripeStatus.complete === true);

  const canSubmitPaymentInfo =
    !isLoading && isUpgradeQuoteLoaded && paymentInfoComplete;

  const isOnReviewStep = step === PAYMENT_STEPS.REVIEW;

  // reset stripe token when payment changes or any time we go to payment step
  useEffect(() => {
    setStripeToken(undefined);
    setCardholderName("");
    setZipCode("");
  }, [useExistingBillingInformation]);

  // if we get a new stripe token, we can move on to review
  useEffect(() => {
    if (typeof stripeToken !== "undefined") {
      setStep(PAYMENT_STEPS.REVIEW);
    }
  }, [stripeToken]);

  useEffect(() => {
    if (step === PAYMENT_STEPS.BILLING_INFO) setStripeToken(undefined);
  }, [step]);

  useEffect(() => {
    if (typeof upgradePlanError !== "undefined") {
      setStep(PAYMENT_STEPS.BILLING_INFO);
    }
  }, [upgradePlanError]);

  const validatePaymentInfo = async () => {
    try {
      setValidatingCard(true);
      reduxDispatch(clearUpgradePlanError());
      setStripeToken(undefined);
      setNewZipCode(zipCode); // triggers checking for a quote

      setValidatingCard(true);

      const result = await stripe.createToken(
        elements.getElement(CardElement),
        {
          name: cardholderName,
          address_zip: zipCode,
        }
      );

      const { error, token } = result;
      if (typeof error === "undefined") {
        setStripeToken(token);
      }
      setValidatingCard(false);
    } catch (error) {
      console.error(error);
    }
  };

  const completeUpgrade = () => {
    const organizationInfo = undefined; // orgs not supported at the moment

    const withToken = useExistingBillingInformation
      ? undefined
      : stripeToken?.id;

    reduxDispatch(
      upgradePlan(upgradeQuote, zipCode, organizationInfo, withToken)
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (step === PAYMENT_STEPS.BILLING_INFO) {
      if (hasBillingInformation && useExistingBillingInformation) {
        setStep(PAYMENT_STEPS.REVIEW);
        reduxDispatch(clearUpgradePlanError(undefined));
      } else {
        validatePaymentInfo();
      }
    }
    if (step === PAYMENT_STEPS.REVIEW) {
      completeUpgrade();
    }
  };

  const currentPlanName = planDetails.title;
  const currentIntervalText =
    currentPlanInterval === "year"
      ? t("account.billing.yearly")
      : t("account.billing.monthly");
  const newIntervalText =
    newPlan?.interval === "year"
      ? t("account.billing.yearly")
      : t("account.billing.monthly");

  const fakeInput = [
    "rounded-sm",
    "border",
    "border-zinc-300",
    "shadow-inner",
    "leading-7",
    "w-full",
    "h-8",
    "px-2",
    "text-zinc-600",
    "text-sm",
    "py-1.5",
  ].join(" ");

  const fakeInputDisabled = [
    "rounded-sm",
    "border",
    "shadow-inner",
    "leading-7",
    "cursor-not-allowed",
    "bg-zinc-50",
    "opacity-70",
    "border-zinc-300",
    "text-zinc-500",
    "text-sm",
    "h-8",
    "px-2",
    "py-1.5",
  ].join(" ");

  return (
    <form className="basis-2/3" onSubmit={handleSubmit}>
      <h3
        className={
          step === PAYMENT_STEPS.BILLING_INFO
            ? "font-bold text-blue-700 uppercase"
            : "font-bold text-zinc-400 uppercase"
        }
      >
        {t("modals.paymentForm.paymentInformationStep")}
      </h3>
      {hasBillingInformation && useExistingBillingInformation ? (
        <>
          <div className="flex">
            <div className="mb-4 flex basis-11/12 flex-col">
              <Label htmlFor="cardholderName">
                {t("input.label.cardholderName")}
              </Label>
              <Input
                type="text"
                data-testid="cardholderName"
                id="cardholderName"
                name="cardholderName"
                value={billingInformation.name}
                disabled
              />
            </div>
            <div className="mb-4 ml-4 flex basis-1/12 flex-col">
              <Label htmlFor="zipCode">{t("input.label.zipCode")}</Label>
              <Input
                type="text"
                data-testid="zipCode"
                id="zipCode"
                name="zipCode"
                value={billingInformation.zipCode}
                disabled
              />
            </div>
          </div>
          <div className="mb-4 flex flex-col">
            <Label htmlFor="card-body">
              {t("input.label.cardInformation")}
            </Label>
            <div className={fakeInputDisabled}>
              <div className="flex h-4 items-center">
                <div className="leading-none">
                  {t("modals.paymentForm.billingCardInformation", {
                    brand: billingInformation.brand,
                    lastFour: billingInformation.lastFour,
                  })}
                </div>
                <div className="ml-auto leading-none">
                  {billingInformation.expirationMonth}/
                  {billingInformation.expirationYear}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex">
            <div className="mb-4 flex basis-11/12 flex-col">
              <Label htmlFor="cardholderName">
                {t("input.label.cardholderName")}
              </Label>
              <Input
                type="text"
                data-testid="cardholderName"
                id="cardholderName"
                name="cardholderName"
                value={cardholderName}
                disabled={isOnReviewStep || validatingCard}
                onChange={(e) => {
                  e.preventDefault();
                  setCardholderName(e.target.value);
                }}
              />
            </div>
            <div className="mb-4 ml-4 flex basis-1/12 flex-col">
              <Label htmlFor="zipCode">{t("input.label.zipCode")}</Label>
              <Input
                type="text"
                data-testid="zipCode"
                id="zipCode"
                name="zipCode"
                disabled={isOnReviewStep || validatingCard}
                value={zipCode}
                onChange={(e) => {
                  e.preventDefault();
                  if (
                    zipCode !== e.target.value.replace(/[^0-9a-zA-Z\- ]*/g, "")
                  ) {
                    setZipCode(e.target.value.replace(/[^0-9a-zA-Z\- ]*/g, ""));
                  }
                }}
              />
            </div>
          </div>
          <div className="mb-4 flex flex-col">
            <Label htmlFor="card-body">
              {t("input.label.cardInformation")}
            </Label>
            <CardElement
              id="card-body"
              className={
                isOnReviewStep || validatingCard ? fakeInputDisabled : fakeInput
              }
              options={{
                ...CARD_ELEMENT_CONFIG,
                disabled: isOnReviewStep || validatingCard,
              }}
              onChange={(stripeObj) => {
                setStripeStatus(stripeObj);
              }}
            />
          </div>
        </>
      )}
      <div className="mb-4 flex flex-row-reverse items-center justify-between">
        {step === PAYMENT_STEPS.BILLING_INFO ? (
          <>
            {!useExistingBillingInformation ? (
              <Button
                disabled={!canSubmitPaymentInfo || (validatingCard && !stripe)}
                type="submit"
                buttonType="primary"
                working={paymentInfoComplete && waiting}
                text={t("button.continueToReview")}
                workingText={t("button.waiting")}
              />
            ) : null}
            {hasBillingInformation && useExistingBillingInformation ? (
              <Button
                disabled={!canSubmitPaymentInfo || validatingCard}
                type="submit"
                buttonType="primary"
                working={paymentInfoComplete && waiting}
                text={t("button.continueToReview")}
                workingText={t("button.waiting")}
              />
            ) : undefined}
            {hasBillingInformation ? (
              <Button
                disabled={validatingCard || isLoading}
                onClick={(e) => {
                  e.preventDefault();
                  setUseExistingBillingInformation(
                    !useExistingBillingInformation
                  );
                }}
                type="button"
                buttonType="text"
                text={
                  useExistingBillingInformation
                    ? t("button.useADifferentCreditCard")
                    : t("button.useSavedCreditCard", {
                        brand: billingInformation.brand,
                        lastFour: billingInformation.lastFour,
                      })
                }
              />
            ) : undefined}
          </>
        ) : null}
        {step === PAYMENT_STEPS.REVIEW ? (
          <Button
            onClick={() => {
              setStep(PAYMENT_STEPS.BILLING_INFO);
            }}
            type="button"
            buttonType="text"
            text={t("button.editPaymentInformation")}
          />
        ) : null}
      </div>
      <h3
        className={
          step === PAYMENT_STEPS.REVIEW
            ? "font-bold text-blue-700 uppercase"
            : "font-bold text-zinc-400 uppercase"
        }
      >
        {t("modals.paymentForm.reviewStep")}
      </h3>
      {typeof upgradePlanError !== "undefined" ? (
        <p className="rouned my-4 bg-red-50 p-4 text-sm text-red-700">
          {lookupTranslation(
            upgradePlanError,
            BILLING_ERROR_PATH,
            BILLING_ERROR_DEFAULT
          )}
        </p>
      ) : null}
      {step === PAYMENT_STEPS.REVIEW && typeof upgradeQuote === "undefined" ? (
        <div className="flex items-center justify-center p-4">
          <LoadingSpinner />
        </div>
      ) : null}
      {step === PAYMENT_STEPS.REVIEW && typeof upgradeQuote !== "undefined" ? (
        <>
          <div>
            {isDowngrade ? (
              <div className="my-4 rounded-sm bg-amber-50 p-4">
                <Label
                  className="mb-4 flex items-center text-sm"
                  htmlFor="downgrade-confirm"
                >
                  <Input
                    disabled={isLoading}
                    type="checkbox"
                    data-testid="downgrade-confirm"
                    id="downgrade-confirm"
                    name="downgrade-confirm"
                    checked={downgradeConfirm}
                    onChange={(e) => {
                      const value = e.target.checked;
                      setDowngradeConfirm(value);
                    }}
                  />
                  <span className="ml-2">
                    <Trans i18nKey="modals.paymentForm.iWantToDowngradeMyPlan">
                      <strong>{{ currentPlan: currentPlanName }}</strong>
                      <strong>{{ newPlan: newPlanDetails.title }}</strong>
                      <strong>{{ renewDate }}</strong>
                    </Trans>
                  </span>
                </Label>
                <p className="text-xs">
                  {taxText ? (
                    <Trans i18nKey="modals.paymentForm.submittingThisFormYourCurrentPlanWillBeDowngradeWithTaxText">
                      <strong>{{ renewDate }}</strong>
                      <strong>
                        <CashMoney
                          size="none"
                          dollars={upgradeQuote.amountDollars}
                          fiatOverride="USD"
                        />
                      </strong>
                    </Trans>
                  ) : (
                    <Trans i18nKey="modals.paymentForm.submittingThisFormYourCurrentPlanWillBeDowngrade">
                      <strong>{{ renewDate }}</strong>
                      <strong>
                        <CashMoney
                          dollars={upgradeQuote.amountDollars}
                          fiatOverride="USD"
                        />
                      </strong>
                    </Trans>
                  )}
                </p>
              </div>
            ) : null}
            {isChangePeriod ? (
              <div className="my-4 rounded-sm bg-amber-50 p-4">
                <Label
                  className="mb-4 flex items-center text-sm"
                  htmlFor="change-period-confirm"
                >
                  <Input
                    disabled={isLoading}
                    type="checkbox"
                    data-testid="change-period-confirm"
                    id="change-period-confirm"
                    name="change-period-confirm"
                    checked={changePeriodConfirm}
                    onChange={(e) => {
                      const value = e.target.checked;
                      setChangePeriodConfirm(value);
                    }}
                  />
                  <span className="ml-2">
                    <Trans i18nKey="modals.paymentForm.iWantToChangeMyPlan">
                      <strong>{{ currentPlan: currentPlanName }}</strong>
                      <strong>
                        {{ currentPlanInterval: currentIntervalText }}
                      </strong>
                      <strong>{{ newPlanInterval: newIntervalText }}</strong>
                      <strong>{{ renewDate }}</strong>
                    </Trans>
                  </span>
                </Label>
                <p className="text-xs">
                  {taxText ? (
                    <Trans i18nKey="modals.paymentForm.submittingThisFormYourCurrentPlanWillBeChangedToNewIntervalWithTaxText">
                      <strong>
                        <CashMoney
                          size="none"
                          dollars={upgradeQuote.amountDollars}
                          fiatOverride="USD"
                        />
                      </strong>
                      <strong>{{ renewDate }}</strong>
                      {{ newPlanInterval: newIntervalText }}
                    </Trans>
                  ) : (
                    <Trans i18nKey="modals.paymentForm.submittingThisFormYourCurrentPlanWillBeChangedToNewInterval">
                      <strong>
                        <CashMoney
                          dollars={upgradeQuote.amountDollars}
                          fiatOverride="USD"
                        />
                      </strong>
                      {{ newPlanInterval: newIntervalText }}
                      <strong>{{ renewDate }}</strong>
                    </Trans>
                  )}
                </p>
              </div>
            ) : null}
            {!isDowngrade && !isChangePeriod ? (
              <div className="my-4 rounded-sm bg-amber-50 p-4">
                <p>
                  {taxText ? (
                    <Trans i18nKey="modals.paymentForm.submittingThisFormYourCurrentPlanWillBeUpgradedWithTaxText">
                      <strong>
                        <CashMoney
                          size="none"
                          dollars={upgradeQuote.subtotalAmountDollars}
                          fiatOverride="USD"
                        />
                      </strong>
                      <strong>
                        <CashMoney
                          size="none"
                          dollars={upgradeQuote.amountDollars}
                          fiatOverride="USD"
                        />
                      </strong>
                      {{ newPlanInterval: newIntervalText }}
                    </Trans>
                  ) : (
                    <Trans i18nKey="modals.paymentForm.submittingThisFormYourCurrentPlanWillBeUpgraded">
                      <strong>
                        <CashMoney
                          size="none"
                          dollars={upgradeQuote.subtotalAmountDollars}
                          fiatOverride="USD"
                        />
                      </strong>
                      <strong>
                        <CashMoney
                          size="none"
                          dollars={upgradeQuote.amountDollars}
                          fiatOverride="USD"
                        />
                      </strong>
                      {{ newPlanInterval: newIntervalText }}
                    </Trans>
                  )}
                </p>
              </div>
            ) : null}
          </div>
          <div className="flex items-center justify-end">
            <Button
              disabled={
                isLoading ||
                (isChangePeriod && !changePeriodConfirm) ||
                (isDowngrade && !downgradeConfirm)
              }
              type="submit"
              buttonType="primary"
              working={isLoading}
              text={t(getButtonText(isDowngrade, isChangePeriod))}
              workingText={t(getWorkingText(isDowngrade, isChangePeriod))}
            />
          </div>
        </>
      ) : null}
    </form>
  );
}

export default PaymentForm;
