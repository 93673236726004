import React, { useState, useEffect } from "react";

import SvgLoader from "app.components/Util/SvgLoader";
import { lookupTranslation } from "app.utils";
import { BILLING_ERROR_PATH, BILLING_ERROR_DEFAULT } from "app.constants";
import TableListCell from "app.components/TableList/TableListCell";
import Button from "app.components/Util/Button";
import { Input, Label } from "app.components/Util/Form";
import { useTranslation } from "react-i18next";

function Coupon({
  isUpgradeQuoteLoaded,
  availableCouponId,
  applyCoupon,
  couponError,
}) {
  const { t } = useTranslation();
  const [couponCode, setCouponCode] = useState("");

  const submit = (e) => {
    e.preventDefault();
    applyCoupon(couponCode);
  };

  useEffect(() => {
    if (typeof couponError !== "undefined") {
      setCouponCode("");
    }
  }, [couponError]);

  const handleChange = (e) => {
    e.preventDefault();
    setCouponCode(e.target.value);
  };

  const clearCoupon = (e) => {
    e.preventDefault();
    setCouponCode("");
    applyCoupon("");
  };

  return availableCouponId ? (
    <tr data-testid="coupon-row">
      <TableListCell colSpan="2" custom="pl-0 pr-0" size="sm">
        <div className="mx-auto flex h-8 w-fit items-center rounded-sm bg-blue-100 px-2.5">
          <span className="mr-2">{availableCouponId}</span>
          <Button
            disabled={!isUpgradeQuoteLoaded}
            onClick={clearCoupon}
            buttonType="text"
          >
            <SvgLoader
              id="Close"
              title={t("button.clear")}
              className="h-3 w-3"
            />
          </Button>
        </div>
      </TableListCell>
    </tr>
  ) : (
    <tr data-testid="coupon-row">
      <TableListCell custom="pl-0 pr-0" size="sm">
        <form onSubmit={submit}>
          <div className="flex items-center">
            <Label className="sr-only" htmlFor="couponCode">
              {t("modals.couponCode")}
            </Label>
            <Input
              type="text"
              readOnly={
                !isUpgradeQuoteLoaded ||
                (availableCouponId && typeof couponError === "undefined")
              }
              id="couponCode"
              name="couponCode"
              placeholder={t("input.placeholder.couponCode")}
              value={couponCode}
              onChange={handleChange}
            />
            <Button
              customClasses="ml-2"
              disabled={!isUpgradeQuoteLoaded}
              buttonType="default"
              type="submit"
              text={t("button.apply")}
            />
          </div>
          {couponError ? (
            <div className="mt-1 text-xs text-red-700">
              {lookupTranslation(
                couponError,
                BILLING_ERROR_PATH,
                BILLING_ERROR_DEFAULT
              )}
            </div>
          ) : null}
        </form>
      </TableListCell>
    </tr>
  );
}

export default Coupon;
