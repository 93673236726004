import React, { useRef, useEffect } from "react";

const MoreOption = ({
  id,
  label,
  focusedOption,
  optionCallback,
  title,
  disabled = false,
  danger = false,
  className = "pointer-events-auto",
  show = true,
  closeMenu,
}) => {
  const opt = useRef();
  const focused = id === focusedOption;

  useEffect(() => {
    if (focused && !disabled) {
      opt.current.focus();
    }
  }, [focused, disabled, opt]);

  if (show === false) return null;

  const linkClassName = [
    "block",
    "transition-all",
    "hover:bg-blue-50",
    "focus:bg-blue-50",
    "px-2.5 py-2",
    "text-sm",
    "text-left",
    "whitespace-nowrap",
    disabled ? "opacity-50" : null,
    disabled ? "cursor-not-allowed" : null,
    danger ? "text-red-700" : "text-link-blue",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <li className={className} key={id}>
      <a
        ref={opt}
        href={`#${id}`}
        disabled={disabled}
        className={linkClassName}
        title={title || null}
        onClick={(e) => {
          e.preventDefault();
          if (disabled) return;
          if (typeof optionCallback === "object" && optionCallback !== null) {
            optionCallback.callback(...optionCallback.arguments);
          } else if (typeof optionCallback === "function") {
            optionCallback();
          }
          closeMenu();
        }}
      >
        {label}
      </a>
    </li>
  );
};

MoreOption.displayName = "MoreOption";

export default MoreOption;
