/* eslint-disable no-param-reassign */
// this rule is disabled in reducers because the redux toolkit
// explicitly supports direct mutation of the state param
// https://redux-toolkit.js.org/usage/immer-reducers
import { createSlice } from "@reduxjs/toolkit";

import { dismissModal } from "app.reducers/ui";

import { STATUS } from "app.constants";

const initialState = {
  connections: [],
  linkedIntegrations: [],
  connectionsStatus: STATUS.UNINITIALIZED,
  oauthStatus: STATUS.UNINITIALIZED,
  oauthError: undefined,
  accountChanges: undefined,
  accounts: undefined,
  vendors: undefined,
  customers: undefined,
  chartOfAccountsStatus: STATUS.UNINITIALIZED,
  tagGroupsStatus: STATUS.UNINITIALIZED,
  tagGroups: {},
  upsertTagGroupStatus: STATUS.UNINITIALIZED,
  upsertTagGroupError: undefined,
  assignTagStatus: STATUS.UNINITIALIZED,
  addTagToGroupError: undefined,
  deleteTagGroupStatus: STATUS.UNINITIALIZED,
  deleteTagGroupError: undefined,
  updateAccountMappingStatus: STATUS.UNINITIALIZED,
  accountMappings: {},
  accountMappingStatus: STATUS.UNINITIALIZED,
  mapTagGroupStatus: {},
  tagGroupMappingError: undefined,
  syncStatus: STATUS.UNINITIALIZED,
  syncError: undefined,
  deleteGroupMappingStatus: STATUS.UNINITIALIZED,
  deleteGroupMappingError: undefined,
  disconnectingStatus: STATUS.UNINITIALIZED,
  updateCounterpartyStatus: STATUS.UNINITIALIZED,
  counterpartyError: undefined,
};

export const accountingIntegrationsSlice = createSlice({
  name: "accountingIntegrations",
  initialState,
  reducers: {
    // connections
    fetchAccountingIntegrationsConnections: () => {},
    requestAccountingIntegrationsConnections: {
      reducer: (state, action) => {
        state.connectionsStatus = action.payload.nextStatus;
      },
      prepare: (nextStatus) => {
        return { payload: { nextStatus } };
      },
    },
    receiveAccountingIntegrationsConnections: {
      reducer: (state, action) => {
        state.connectionsStatus = STATUS.LOADED;
        state.connections = action.payload.connections;
        state.linkedIntegrations = action.payload.linkedIntegrations;
      },
      prepare: (connections, linkedIntegrations) => {
        return { payload: { connections, linkedIntegrations } };
      },
    },
    accountingIntegrationsConnectionsError: (state) => {
      state.connectionsStatus = STATUS.ERROR;
    },
    // connect to AccountingIntegrations  OAuth
    connectAccountingOAuth: {
      reducer: () => {},
      prepare: (accountingIntegrationName, slug) => ({
        payload: { accountingIntegrationName, slug },
      }),
    },
    requestConnectAccountingOAuth: () => {},

    connectAccountingOAuthError: {
      reducer: (state, action) => {
        state.oauthStatus = STATUS.ERROR;
        state.oauthError = action.payload.response;
      },
      prepare: (response) => ({
        payload: { response },
      }),
    },
    fetchChartOfAccounts: {
      reducer: () => {},
      prepare: (accountingIntegrationName) => ({
        payload: { accountingIntegrationName },
      }),
    },
    requestChartOfAccounts: {
      reducer: (state, action) => {
        state.chartOfAccountsStatus = action.payload.nextStatus;
      },
      prepare: (nextStatus) => {
        return { payload: { nextStatus } };
      },
    },
    receiveChartOfAccounts: {
      reducer: (state, action) => {
        state.chartOfAccountsStatus = STATUS.LOADED;
        // state.accountChanges = action.payload.changes;
        state.accounts = action.payload.accounts;
        state.vendors = action.payload.vendors;
        state.customers = action.payload.customers;
      },
      prepare: (accounts, vendors, customers) => {
        return { payload: { accounts, vendors, customers } };
      },
    },
    chartOfAccountsError: (state) => {
      state.chartOfAccountsStatus = STATUS.ERROR;
    },
    // fetch tag groups
    fetchTagGroups: () => {},
    requestTagGroups: {
      reducer: (state, action) => {
        state.tagGroupsStatus = action.payload.nextStatus;
      },
      prepare: (nextStatus) => {
        return { payload: { nextStatus } };
      },
    },
    receiveTagGroups: {
      reducer: (state, action) => {
        state.tagGroupsStatus = STATUS.LOADED;
        state.tagGroups = action.payload.tagGroups;
      },
      prepare: (tagGroups) => {
        return { payload: { tagGroups } };
      },
    },
    tagGroupsError: (state) => {
      state.tagGroupsStatus = STATUS.ERROR;
    },
    // create tag group
    upsertTagGroup: {
      reducer: () => {},
      prepare: (name, description, id) => ({
        payload: { name, description, id },
      }),
    },
    requestUpsertTagGroup: (state) => {
      state.upsertTagGroupStatus = STATUS.WORKING;
    },
    receiveUpsertTagGroup: (state) => {
      state.upsertTagGroupStatus = STATUS.SUCCESS;
    },
    upsertTagGroupError: {
      reducer: (state, action) => {
        state.upsertTagGroupStatus = STATUS.ERROR;
        state.upsertTagGroupError = action.payload.error;
      },
      prepare: (error) => ({
        payload: { error },
      }),
    },
    // delete tag group
    deleteTagGroup: {
      reducer: () => {},
      prepare: (tagGroupId) => ({
        payload: { tagGroupId },
      }),
    },
    requestDeleteTagGroup: (state) => {
      state.deleteTagGroupStatus = STATUS.WORKING;
    },
    receiveDeleteTagGroup: (state) => {
      state.deleteTagGroupStatus = STATUS.SUCCESS;
    },
    deleteTagGroupError: {
      reducer: (state, action) => {
        state.deleteTagGroupStatus = STATUS.ERROR;
        state.deleteTagGroupError = action.payload.error;
      },
      prepare: (error) => ({
        payload: { error },
      }),
    },
    // add Tag To Group
    addTagToGroup: {
      reducer: (state, action) => {
        const { tagGroups } = state;
        const targetGroup = tagGroups.find(
          (g) => g.id === action.payload.groupId
        );

        targetGroup.tags.push({
          tag: action.payload.tag,
          colorId: action.payload.colorId,
        });
        state.addTagToGroupError = undefined;
      },
      prepare: (groupId, tag) => {
        return { payload: { groupId, tag } };
      },
    },
    requestAddTagToGroup: (state) => {
      state.assignTagStatus = STATUS.WORKING;
    },
    receiveAddTagToGroup: (state) => {
      state.assignTagStatus = STATUS.SUCCESS;
    },
    addTagToGroupError: {
      reducer: (state, action) => {
        state.assignTagStatus = STATUS.ERROR;
        state.addTagToGroupError = action.payload.error;
      },
      prepare: (error) => ({
        payload: { error },
      }),
    },
    // add Tag To Group
    removeTagFromGroup: {
      reducer: (state, action) => {
        const { tagGroups } = state;
        state.tagGroups = tagGroups.map((group) => ({
          ...group,
          tags: group.tags.filter((tag) => tag.tag !== action.payload.tag),
        }));
        state.addTagToGroupError = undefined;
      },
      prepare: (tag) => {
        return { payload: { tag } };
      },
    },
    requestRemoveTagFromGroup: (state) => {
      state.assignTagStatus = STATUS.WORKING;
    },
    receiveRemoveTagFromGroup: (state) => {
      state.assignTagStatus = STATUS.SUCCESS;
    },
    removeTagFromGroupError: {
      reducer: (state, action) => {
        state.assignTagStatus = STATUS.ERROR;
        state.addTagToGroupError = action.payload.error;
      },
      prepare: (error) => ({
        payload: { error },
      }),
    },
    // map token
    mapTokenToAccount: {
      reducer: () => {},
      prepare: (smartWalletGUID, tokenAccountId, connectionId, mapId) => ({
        payload: {
          smartWalletGUID,
          tokenAccountId: parseInt(tokenAccountId, 10),
          connectionId,
          mapId,
        },
      }),
    },
    requestMapTokenToAccount: (state) => {
      state.updateAccountMappingStatus = STATUS.WORKING;
    },
    receiveMapTokenToAccount: (state) => {
      state.updateAccountMappingStatus = STATUS.SUCCESS;
    },
    mapTokenToAccountError: {
      reducer: (state, action) => {
        state.updateAccountMappingStatus = STATUS.ERROR;
        state.accountMappingError = action.payload.error;
      },
      prepare: (error) => ({
        payload: { error },
      }),
    },
    // fetch account mappings
    fetchAccountMappings: {
      reducer: () => {},
      prepare: (connectionId) => {
        return { payload: { connectionId } };
      },
    },
    requestAccountMappings: {
      reducer: (state, action) => {
        state.accountMappingStatus = action.payload.nextStatus;
      },
      prepare: (nextStatus) => {
        return { payload: { nextStatus } };
      },
    },
    receiveAccountMappings: {
      reducer: (state, action) => {
        state.accountMappingStatus = STATUS.LOADED;
        state.accountMappings = action.payload.accountMappings;
      },
      prepare: (accountMappings) => {
        return { payload: { accountMappings } };
      },
    },
    accountMappingsError: (state) => {
      state.accountMappingStatus = STATUS.ERROR;
    },
    // map default account
    mapDefaultAccount: {
      reducer: () => {},
      prepare: (connectionId, feeAccountId, gainLossAccountId, mapId) => ({
        payload: {
          connectionId,
          feeAccountId,
          gainLossAccountId,
          mapId,
        },
      }),
    },
    requestMapDefaultAccount: (state) => {
      state.updateAccountMappingStatus = STATUS.WORKING;
    },
    receiveMapDefaultAccount: (state) => {
      state.updateAccountMappingStatus = STATUS.SUCCESS;
    },
    mapDefaultAccountError: {
      reducer: (state, action) => {
        state.updateAccountMappingStatus = STATUS.ERROR;
        state.accountMappingError = action.payload.error;
      },
      prepare: (error) => ({
        payload: { error },
      }),
    },
    // map tag group
    mapTagGroup: {
      reducer: () => {},
      prepare: (
        tagGroupdId,
        connectionId,
        mapId,
        accountId,
        feeAccountId,
        gainLossAccountId
      ) => ({
        payload: {
          tagGroupdId,
          connectionId,
          mapId,
          accountId,
          feeAccountId,
          gainLossAccountId,
        },
      }),
    },
    requestMapTagGroup: {
      reducer: (state, action) => {
        state.mapTagGroupStatus[action.payload.accountId] = STATUS.WORKING;
      },
      prepare: (accountId) => ({
        payload: { accountId },
      }),
    },
    receiveMapTagGroup: {
      reducer: (state, action) => {
        state.mapTagGroupStatus[action.payload.accountId] = STATUS.SUCCESS;
      },
      prepare: (accountId) => ({
        payload: { accountId },
      }),
    },
    mapTagGroupError: {
      reducer: (state, action) => {
        state.mapTagGroupStatus[action.payload.accountId] = STATUS.ERROR;
        state.tagGroupMappingError = action.payload.error;
      },
      prepare: (accountId, error) => ({
        payload: { accountId, error },
      }),
    },
    // sync
    accountingIntegrationSync: {
      reducer: (state) => {
        state.syncError = undefined;
      },
      prepare: (accountingIntegrationName, connectionId) => ({
        payload: {
          accountingIntegrationName,
          connectionId,
        },
      }),
    },
    requestAccountingIntegrationSync: (state) => {
      state.syncStatus = STATUS.WORKING;
    },
    receiveAccountingIntegrationSync: (state) => {
      state.syncStatus = STATUS.SUCCESS;
    },
    accountingIntegrationSyncError: {
      reducer: (state, action) => {
        state.syncStatus = STATUS.ERROR;
        state.syncError = action.payload.error;
      },
      prepare: (error) => ({
        payload: { error },
      }),
    },
    // delete mapping account to tag group
    deleteGroupMapping: {
      reducer: () => {},
      prepare: (mappingId, linkId, tagGroupId) => ({
        payload: { mappingId, linkId, tagGroupId },
      }),
    },
    requestDeleteGroupMapping: (state) => {
      state.deleteGroupMappingStatus = STATUS.WORKING;
    },
    receiveDeleteGroupMapping: (state) => {
      state.deleteGroupMappingStatus = STATUS.SUCCESS;
    },
    deleteGroupMappingError: {
      reducer: (state, action) => {
        state.deleteGroupMappingStatus = STATUS.ERROR;
        state.deleteGroupMappingError = action.payload.error;
      },
      prepare: (error) => ({
        payload: { error },
      }),
    },
    bulkMapTokenAccounts: {
      reducer: () => {},
      prepare: (connectionId, mappings) => ({
        payload: { connectionId, mappings },
      }),
    },
    requestBulkMapTokenAccounts: (state) => {
      state.updateAccountMappingStatus = STATUS.WORKING;
    },
    receiveBulkMapTokenAccounts: (state) => {
      state.updateAccountMappingStatus = STATUS.SUCCESS;
    },
    // disconnect integration
    disconnectIntegration: {
      reducer: () => {},
      prepare: (connectionId) => ({
        payload: { connectionId },
      }),
    },
    requestDisconnectIntegration: (state) => {
      state.disconnectingStatus = STATUS.WORKING;
    },
    receiveDisconnectIntegration: (state) => {
      state.disconnectingStatus = STATUS.SUCCESS;
    },
    disconnectIntegrationError: {
      reducer: (state, action) => {
        state.disconnectingStatus = STATUS.ERROR;
        state.disconnectingError = action.payload.error;
      },
      prepare: (error) => ({
        payload: { error },
      }),
    },
    // map Counterparty
    mapCounterparty: {
      reducer: () => {},
      prepare: (tag, linkId, counterpartyId) => ({
        payload: {
          tag,
          linkId,
          counterpartyId,
        },
      }),
    },
    requestMapCounterparty: (state) => {
      state.updateCounterpartyStatus = STATUS.WORKING;
    },
    receiveMapCounterparty: (state) => {
      state.updateCounterpartyStatus = STATUS.SUCCESS;
    },
    mapCounterpartyError: {
      reducer: (state, action) => {
        state.updateCounterpartyStatus = STATUS.ERROR;
        state.counterpartyError = action.payload.error;
      },
      prepare: (error) => ({
        payload: { error },
      }),
    },
  },
  extraReducers: (builder) => {
    builder.addCase(dismissModal, (state) => {
      state.upsertTagGroupError = undefined;
      state.upsertTagGroupStatus = STATUS.UNINITIALIZED;
    });
  },
});

// all actions
export const { actions } = accountingIntegrationsSlice;

// individual actions
export const {
  // connections
  fetchAccountingIntegrationsConnections,
  requestAccountingIntegrationsConnections,
  receiveAccountingIntegrationsConnections,
  accountingIntegrationsConnectionsError,
  // connect AccountingIntegrations  OAuth
  connectAccountingOAuth,
  requestConnectAccountingOAuth,
  connectAccountingOAuthError,
  // chart of accounts
  fetchChartOfAccounts,
  requestChartOfAccounts,
  receiveChartOfAccounts,
  chartOfAccountsError,
  // fetch tag groups
  fetchTagGroups,
  requestTagGroups,
  receiveTagGroups,
  tagGroupsError,
  // create Tag Group
  upsertTagGroup,
  requestUpsertTagGroup,
  receiveUpsertTagGroup,
  upsertTagGroupError,
  // delete Tag Group
  deleteTagGroup,
  requestDeleteTagGroup,
  receiveDeleteTagGroup,
  deleteTagGroupError,
  // add Tag To Group
  addTagToGroup,
  requestAddTagToGroup,
  receiveAddTagToGroup,
  addTagToGroupError,
  // remove Tag From Group
  removeTagFromGroup,
  requestRemoveTagFromGroup,
  receiveRemoveTagFromGroup,
  removeTagFromGroupError,
  // map token to asset account
  mapTokenToAccount,
  requestMapTokenToAccount,
  receiveMapTokenToAccount,
  mapTokenToAccountError,
  // account mappings
  fetchAccountMappings,
  requestAccountMappings,
  receiveAccountMappings,
  accountMappingsError,
  // map default account
  mapDefaultAccount,
  requestMapDefaultAccount,
  receiveMapDefaultAccount,
  mapDefaultAccountError,
  // map tag group
  mapTagGroup,
  requestMapTagGroup,
  receiveMapTagGroup,
  mapTagGroupError,
  // sync
  accountingIntegrationSync,
  requestAccountingIntegrationSync,
  receiveAccountingIntegrationSync,
  accountingIntegrationSyncError,
  // delete mapping account to tag group
  deleteGroupMapping,
  requestDeleteGroupMapping,
  receiveDeleteGroupMapping,
  deleteGroupMappingError,
  // bulk Map Token Accounts
  bulkMapTokenAccounts,
  requestBulkMapTokenAccounts,
  receiveBulkMapTokenAccounts,
  // disconnect integration
  disconnectIntegration,
  requestDisconnectIntegration,
  receiveDisconnectIntegration,
  disconnectIntegrationError,
  // map Counterparty
  mapCounterparty,
  requestMapCounterparty,
  receiveMapCounterparty,
  mapCounterpartyError,
} = accountingIntegrationsSlice.actions;

export default accountingIntegrationsSlice.reducer;
