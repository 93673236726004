import React, { useState, useRef, useEffect, useMemo } from "react";
import MoreOption from "app.components/Util/MoreOptions/MoreOption";

const UP = "up";
const DOWN = "down";

const BASE_COLOR = {
  toggle: "bg-transparent hover:bg-zinc-200 focus:bg-zinc-200",
  open: "bg-zinc-200",
  dot: "bg-zinc-500",
};

const COLORS = {
  blue: {
    toggle: "bg-blue-100 hover:bg-blue-200/50 focus:bg-blue-200/50",
    open: "bg-blue-200/50",
    dot: "bg-blue-500",
  },
  darkBlue: {
    toggle: "bg-blue-600 hover:bg-blue-605 focus:bg-blue-605",
    open: "bg-blue-605",
    dot: "bg-white",
  },
};

const MoreOptions = ({
  bound,
  label,
  heading = undefined,
  disabled = false,
  options = [],
  color = "",
  showOptionsDivision,
  small = false,
  toggleAsCaret = false,
}) => {
  const dropdownMenuContainer = useRef();
  const dropdownMenuElement = useRef();
  const dropdownMenuContent = useRef();
  const [menuOpen, setMenuOpen] = useState(false);

  const [focusedOption, setFocusedOption] = useState(undefined);

  const openMenu = () => {
    setMenuOpen(true);
    setFocusedOption(undefined);
  };

  const closeMenu = () => {
    setMenuOpen(false);
    setFocusedOption(undefined);
  };

  const shownGroupOptions = useMemo(() => {
    return options.reduce((group, option) => {
      if (option.options) {
        const filteredOptions = option.options.filter((i) => i.show !== false);
        if (filteredOptions.length) {
          group.push({ ...option, options: filteredOptions });
        }
      } else if (option.show !== false) group.push(option);
      return group;
    }, []);
  }, [options]);

  const childrenOptions = useMemo(() => {
    return shownGroupOptions.reduce((group, option) => {
      if (option.options?.length) {
        group.push(...option.options);
      } else {
        group.push(option);
      }
      return group;
    }, []);
  }, [shownGroupOptions]);

  // remove event handlers when un-mounting and handle key presses etc
  useEffect(() => {
    let isMounted = true; // note this flag denote mount status

    function doOptionCallback() {
      const option = childrenOptions.find((opt) => opt.id === focusedOption);
      if (!option) return;
      if (option.disabled) return;
      if (
        typeof option.optionCallback === "object" &&
        option.optionCallback !== null
      ) {
        option.optionCallback.callback(...option.optionCallback.arguments);
      } else if (typeof option.optionCallback === "function") {
        option.optionCallback();
      }
    }

    function focusOption(dir) {
      if (typeof focusedOption === "undefined") {
        if (dir === DOWN) {
          setFocusedOption(childrenOptions[0]?.id);
        } else {
          setFocusedOption(childrenOptions[childrenOptions.length - 1]?.id);
        }
      } else {
        // where is it?
        const idx = childrenOptions.findIndex((o) => o.id === focusedOption);
        if (dir === DOWN) {
          if (idx < childrenOptions.length - 1) {
            setFocusedOption(childrenOptions[idx + 1]?.id);
          } else {
            setFocusedOption(childrenOptions[0]?.id);
          }
        } else if (idx > 0) {
          setFocusedOption(childrenOptions[idx - 1]?.id);
        } else {
          setFocusedOption(childrenOptions[childrenOptions.length - 1]?.id);
        }
      }
    }

    // this function watches for key presses to make sure some behaviors happen
    // if ESC (27) is pressed, we dismiss the dropdown
    const detectKey = (e) => {
      // esc press, dismiss menu
      if (menuOpen && e.key === "Escape") {
        closeMenu();
      }

      if (
        e.target !== document.body &&
        !dropdownMenuContainer.current.contains(e.target)
      ) {
        // We only want to process the target is in the current element (that makes this a selection, search, etc)
        // or when the document.body is the target (open, close)
        return true;
      }

      if (e.metaKey || e.altKey || e.ctrlKey) {
        return true;
      }

      if (e.shiftKey) {
        if (e.key === "Tab") {
          e.preventDefault();
          focusOption(UP);
          return true;
        }
        return true;
      }

      switch (e.key) {
        case "ArrowDown":
        case "Tab":
          e.preventDefault();
          focusOption(DOWN);
          break;
        case "ArrowUp":
          e.preventDefault();
          focusOption(UP);
          break;
        case "Enter":
        case " ":
          e.preventDefault();
          doOptionCallback();
          closeMenu();
          break;
        default:
          break;
      }
      return undefined;
    };

    const handleClick = (event) => {
      // no action on disabled links
      const isDisabled = event.target.getAttribute("data-disabled") || false;
      if (isDisabled) {
        return;
      }

      if (event.target === dropdownMenuElement.current) {
        return;
      }

      if (menuOpen) {
        setTimeout(() => isMounted && setMenuOpen(false), 0);
      }
    };

    if (!menuOpen) {
      window.removeEventListener("keydown", detectKey, false);
      document.body.removeEventListener("click", handleClick, true);
    } else {
      document.body.addEventListener("click", handleClick, true);
      window.addEventListener("keydown", detectKey, false);
    }
    return () => {
      isMounted = false;
      window.removeEventListener("keydown", detectKey, false);
      document.body.removeEventListener("click", handleClick, true);
    };
  }, [
    menuOpen,
    dropdownMenuElement,
    dropdownMenuContent,
    childrenOptions,
    focusedOption,
  ]);

  const moreOptionsMenuClass = [
    "shadow-menu",
    "rounded-sm",
    "bg-white",
    "w-fit",
    "min-w-[208px]", // =w-52, or 208px
    "absolute",
    "transition-all",
    "origin-top",
    "mt-1",
    "z-10",
    "overflow-hidden",
    menuOpen
      ? "opacity-100 scale-x-100 scale-y-100"
      : "opacity-0 scale-x-100 scale-y-0",
    bound === "left" ? "left-0" : null,
    bound === "right" ? "right-0" : null,
  ]
    .filter(Boolean)
    .join(" ");

  const colors = COLORS[color] ? COLORS[color] : BASE_COLOR;

  const toggleClassName = [
    "transition-all flex items-center justify-around disabled:opacity-50 rounded-sm",
    colors.toggle,
    small ? "h-5 w-5 p-0.5" : "h-8 w-8 p-1",
    menuOpen ? colors.open : null,
  ]
    .filter(Boolean)
    .join(" ");

  const nextMenuState = menuOpen ? closeMenu : openMenu;

  const dotClass = [
    small ? "h-1 w-1 basis-1" : "h-1.5 w-1.5 basis-1.5",
    colors.dot,
    "rounded-full transition-all",
  ].join(" ");

  const caretToggleClassName = [
    "caret border-t-white mt-0.5 transition-all group-hover:opacity-100",
    menuOpen ? "opacity-100" : "opacity-30",
  ].join(" ");

  const containerClass = [
    "relative inline-block",
    !toggleAsCaret && small ? "h-5 w-5" : "",
    !toggleAsCaret && !small ? "h-8 w-8" : "",
  ].join(" ");

  return (
    <div
      data-component="more-options"
      ref={dropdownMenuContainer}
      className={containerClass}
    >
      {toggleAsCaret ? (
        <button
          type="button"
          disabled={disabled}
          className="flex h-3 w-3 items-center"
          onClick={(e) => {
            e.preventDefault();
            nextMenuState();
          }}
          ref={dropdownMenuElement}
          aria-haspopup="true"
          aria-expanded={menuOpen}
        >
          <span className={caretToggleClassName} />
          <span className="sr-only">{label}</span>
        </button>
      ) : (
        <button
          type="button"
          disabled={disabled}
          className={toggleClassName}
          onClick={(e) => {
            e.preventDefault();
            nextMenuState();
          }}
          ref={dropdownMenuElement}
          aria-haspopup="true"
          aria-expanded={menuOpen}
        >
          <span className={dotClass} />
          <span className={dotClass} />
          <span className={dotClass} />
          <span className="sr-only">{label}</span>
        </button>
      )}
      <div className={moreOptionsMenuClass} ref={dropdownMenuContent}>
        {menuOpen && (
          <>
            {heading && <div className="border-b px-3.5 py-2">{heading}</div>}
            <ul
              className={[
                "pointer-events-none flex flex-col",
                heading ? "pt-0" : "",
                showOptionsDivision ? "divide-y divide-zinc-200" : "",
              ].join(" ")}
              data-testid="menu-options"
            >
              {shownGroupOptions.map((option) =>
                option.options ? (
                  <ul key={option.id}>
                    {option.options.map((subOption) => (
                      <MoreOption
                        key={subOption.id}
                        {...subOption}
                        closeMenu={closeMenu}
                        focusedOption={focusedOption}
                      />
                    ))}
                  </ul>
                ) : (
                  <MoreOption
                    key={option.id}
                    {...option}
                    closeMenu={closeMenu}
                    focusedOption={focusedOption}
                  />
                )
              )}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

MoreOptions.displayName = "MoreOptions";

export default MoreOptions;
