export const getLinkedExchangeByLinkGUID = (linkGUID, linkedExchanges) => {
  let linked;
  for (let i = 0; i < linkedExchanges.length; i += 1) {
    const linkedExchange = linkedExchanges[i];
    for (let j = 0; j < linkedExchange.linked.length; j += 1) {
      const link = linkedExchange.linked[j];
      if (link.linkGUID === linkGUID) {
        linked = link;
      }
    }
  }

  return linked;
};
