import { call, put } from "redux-saga/effects";
import {
  requestChartOfAccounts,
  receiveChartOfAccounts,
  chartOfAccountsError,
} from "app.reducers/accountingIntegrations";
import ExchangesAPI from "app.api/ExchangesAPI";
import { getLoadingState } from "app.utils/selectors";
import { STATUS } from "app.constants";

function* fetchChartOfAccounts(action) {
  const { accountingIntegrationName } = action.payload;

  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.accountingIntegrations.chartOfAccountsStatus
  );

  if (isLoading) return;

  yield put(requestChartOfAccounts(nextStatus));

  const response = yield call(
    ExchangesAPI.getAccountingEntities,
    accountingIntegrationName
  );

  if (response.error) {
    console.error(
      "Error in fetchChartOfAccounts",
      response.body,
      response.error
    );
    yield put(chartOfAccountsError(response.body, STATUS.ERROR));
  } else {
    yield put(
      receiveChartOfAccounts(
        response.body.accounts,
        response.body.vendors,
        response.body.customers
      )
    );
  }
}

export default fetchChartOfAccounts;
