import React, { useState } from "react"; // , PropTypes
import { useDispatch, useSelector } from "react-redux";
import { dismissModal } from "app.reducers/ui";
import StripeContainer from "app.components/Util/StripeContainer";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { CARD_ELEMENT_CONFIG } from "app.constants/stripe";
import { updateBilling } from "app.actions/account";
import { ACCOUNT_STATUS } from "app.constants/loadingStates";
import Button from "app.components/Util/Button";
import { useTranslation } from "react-i18next";

import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";
import Input from "app.components/Util/Form/Input";
import Label from "app.components/Util/Form/Label";

function ModalUpdateBillingContent() {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const { billingInformation, updateBillingError, updateBillingStatus } =
    useSelector((state) => state.account);
  const [cardholderName, setCardholderName] = useState(
    billingInformation?.name ?? ""
  );

  const [zipCode, setZipCode] = useState(billingInformation?.zipCode ?? "");
  const blockSubmit = updateBillingStatus === ACCOUNT_STATUS.LOADING;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (blockSubmit) return;

    const cardInstance = elements.getElement(CardElement);
    reduxDispatch(updateBilling(stripe, cardInstance, cardholderName, zipCode));
  };

  const isLoading = updateBillingStatus === ACCOUNT_STATUS.LOADING;
  const fakeInput = [
    "rounded-sm",
    "border",
    "border-zinc-300",
    "shadow-inner",
    "leading-7",
    "w-full",
    "h-8",
    "px-2",
    "text-zinc-600",
    "text-sm",
    "py-1.5",
  ].join(" ");

  return (
    <form onSubmit={handleSubmit}>
      <div className="m-4">
        <div className="mb-4 flex flex-col">
          <Label htmlFor="cardholderName">
            {t("input.label.cardholderName")}
          </Label>
          <Input
            type="text"
            id="cardholderName"
            name="cardholderName"
            value={cardholderName}
            onChange={(e) => {
              e.preventDefault();
              setCardholderName(e.target.value);
            }}
          />
        </div>

        <div className="mb-4 flex flex-col">
          <Label htmlFor="card-body">
            {t("input.label.cardInformation")}
            <span className="text-xxs mb-4 ml-1 font-normal text-red-700">
              {updateBillingError}
            </span>
          </Label>
          <CardElement
            id="card-body"
            className={fakeInput}
            options={CARD_ELEMENT_CONFIG}
          />
        </div>

        <div className="mb-4 flex w-24 flex-col">
          <Label htmlFor="zipCode">{t("input.label.zipCode")}</Label>
          <Input
            type="text"
            id="zipCode"
            name="zipCode"
            value={zipCode}
            onChange={(e) => {
              e.preventDefault();
              setZipCode(e.target.value);
            }}
          />
        </div>
      </div>
      <ModalControls>
        <Button
          type="submit"
          disabled={isLoading || blockSubmit}
          data-tabbable
          buttonType="primary"
          customClasses="ml-4"
          working={isLoading}
          text={t("button.updateBillingInformation")}
          workingText={t("modals.updateBilling.validatingCard")}
        />
        <Button
          onClick={(e) => {
            e.preventDefault();
            reduxDispatch(dismissModal());
          }}
          data-tabbable
          buttonType="text"
          text={t("button.cancel")}
        />
      </ModalControls>
    </form>
  );
}

function ModalUpdateBilling() {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();

  return (
    <StripeContainer>
      <ModalHeader
        title={t("modals.updateBilling.updateBillingInformation")}
        closeCallback={(event) => {
          event.preventDefault();
          reduxDispatch(dismissModal());
        }}
      />
      <ModalUpdateBillingContent />
    </StripeContainer>
  );
}

ModalUpdateBilling.displayName = "ModalUpdateBilling";

export default ModalUpdateBilling;
