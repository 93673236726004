import { call, put } from "redux-saga/effects";
import {
  requestConnectExchangeOAuth,
  receiveConnectExchangeOAuthError,
} from "app.reducers/exchanges";
import ExchangesAPI from "app.api/ExchangesAPI";

function* connectExchangeViaOAuth(action) {
  const { exchange, slug } = action.payload;

  yield put(requestConnectExchangeOAuth());

  const response = yield call(ExchangesAPI.initConnectExchange, exchange, slug);

  if (response.error) {
    yield put(receiveConnectExchangeOAuthError(response.body, response.error));
  } else {
    window.location = response.body.exchangeOAuthURI;
  }
}

export default connectExchangeViaOAuth;
