import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { dismissModal, createApiKey } from "app.reducers/ui";

import { useTranslation } from "react-i18next";
import Button from "app.components/Util/Button";
import Input from "app.components/Util/Form/Input";
import Label from "app.components/Util/Form/Label";
import Select from "app.components/Util/Form/Select";
import SvgLoader from "app.components/Util/SvgLoader";
import CopyToClipboard from "app.components/Util/CopyToClipboard";

import { COMPANY_NAME } from "app.constants";

import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";

import { getSortBy } from "app.utils";
import { useIsOrganizationUser } from "app.hooks";

function ModalNewApiKey({ canDismissCallback }) {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();

  const [apiKeyLabel, setApiKeyLabel] = useState("");
  const [readOnlyApiKey, setReadOnlyApiKey] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState("ALL");

  const { user } = useSelector((state) => state.user);
  const organizations = useSelector((state) => state.organizations);
  const isOrganizationUser = useIsOrganizationUser();

  const userList = isOrganizationUser
    ? [...organizations.organization.profiles]
    : [];

  const sortedUserList = userList.sort(
    getSortBy((x) => (x.profileDisplayName || "").toLowerCase(), true)
  );

  const { modalWorking, apiKeyWorking, apiKeyResponse, apiKeyError } =
    useSelector((state) => state.ui);

  useEffect(() => {
    canDismissCallback(!apiKeyWorking === true || !modalWorking === true);
  }, [modalWorking, apiKeyWorking, canDismissCallback]);

  let canSubmit = false;
  if (isOrganizationUser) {
    canSubmit = apiKeyLabel !== "" && selectedProfile !== "";
  } else {
    canSubmit = apiKeyLabel !== "";
  }

  const handleDismiss = (e) => {
    e.preventDefault();
    reduxDispatch(dismissModal());
  };

  return (
    <>
      <ModalHeader
        title={
          apiKeyResponse
            ? t("modals.apiKeys.apiKeyCreated")
            : t("modals.apiKeys.createAnApiKey")
        }
        closeCallback={handleDismiss}
      />
      {apiKeyResponse ? (
        <>
          <div className="m-4">
            <div className="lead mb-4 flex flex-row items-center gap-2 rounded-sm bg-amber-100 p-4 text-amber-800">
              <SvgLoader id="Warning" className="h-8 w-8 fill-amber-700" />
              <p className="flex-1">
                {t("modals.apiKeys.saveSecretKey", {
                  companyName: COMPANY_NAME,
                })}
              </p>
            </div>
            <div className="mb-4 rounded-sm bg-zinc-100 p-4">
              <p>
                <strong>{t("modals.apiKeys.apiKey")}</strong>
              </p>
              <p className="flex flex-row gap-1 font-mono text-sm">
                {apiKeyResponse.apiKey}
                <CopyToClipboard
                  text={apiKeyResponse.apiKey}
                  label={apiKeyResponse.apiKey}
                />
              </p>
            </div>
            <div className="mb-4 rounded-sm bg-zinc-100 p-4">
              <p>
                <strong>{t("modals.apiKeys.secretKey")}</strong>
              </p>
              <p className="flex flex-row gap-1 font-mono text-sm">
                {apiKeyResponse.secretKeyPlain}
                <CopyToClipboard
                  text={apiKeyResponse.secretKeyPlain}
                  label={apiKeyResponse.secretKeyPlain}
                />
              </p>
            </div>
          </div>
          <ModalControls>
            <Button
              onClick={handleDismiss}
              disabled={modalWorking || apiKeyWorking}
              buttonType="text"
              text={t("common.close")}
            />
          </ModalControls>
        </>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (isOrganizationUser) {
              const orgGUID =
                selectedProfile === "ALL"
                  ? organizations.organizationUser.organizationGUID
                  : undefined;
              const profileGUID =
                selectedProfile === "ALL" ? undefined : selectedProfile;
              reduxDispatch(
                createApiKey(readOnlyApiKey, apiKeyLabel, profileGUID, orgGUID)
              );
            } else {
              const { profileGUID } = user;
              reduxDispatch(
                createApiKey(readOnlyApiKey, apiKeyLabel, profileGUID)
              );
            }
          }}
        >
          <div className="m-4">
            {apiKeyError ? (
              <p className="mb-4 rounded-sm bg-red-100 p-4 text-red-700">
                {apiKeyError.message}
              </p>
            ) : null}
            <div className="mb-4 flex flex-col">
              <Label htmlFor="apiKeyLabel">
                {t("input.label.apiKeyLabel")}
              </Label>
              <Input
                data-tabbable
                type="text"
                data-testid="apiKeyLabel"
                id="apiKeyLabel"
                name="apiKeyLabel"
                autoComplete="off"
                maxLength="100"
                value={apiKeyLabel}
                onChange={(e) => {
                  e.preventDefault();
                  setApiKeyLabel(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="m-4">
            <Label htmlFor="readOnlyApiKey">
              <Input
                data-tabbable
                type="checkbox"
                data-testid="readOnlyApiKey"
                id="readOnlyApiKey"
                name="readOnlyApiKey"
                checked={readOnlyApiKey}
                onChange={() => {
                  const nextVal = !readOnlyApiKey;
                  setReadOnlyApiKey(nextVal);
                }}
              />
              <span className="ms-1">{t("input.label.readOnlyApiKey")}</span>
            </Label>
          </div>

          {isOrganizationUser ? (
            <div className="m-4 flex flex-1 flex-col">
              <Label htmlFor="selectedProfile">
                {t("modals.apiKeys.managedAccounAccess")}
              </Label>
              <Select
                data-testid="selectedProfile"
                id="selectedProfile"
                value={selectedProfile}
                onChange={(e) => {
                  e.preventDefault();
                  setSelectedProfile(e.target.value);
                }}
                onBlur={(e) => {
                  e.preventDefault();
                  setSelectedProfile(e.target.value);
                }}
              >
                <option value="ALL">{t("modals.apiKeys.allProfiles")}</option>
                <option value="" disabled="disabled">
                  ─────────────────────────
                </option>
                {sortedUserList.map((u) => {
                  return (
                    <option key={u.profileGUID} value={u.profileGUID}>
                      {u.profileDisplayName}
                    </option>
                  );
                })}
              </Select>
            </div>
          ) : null}

          <ModalControls>
            <Button
              type="submit"
              disabled={modalWorking || apiKeyWorking || !canSubmit}
              working={modalWorking || apiKeyWorking}
              workingText={t("button.saving")}
              buttonType="primary"
              customClasses="ml-4"
              text={t("modals.apiKeys.createApiKey")}
            />
            <Button
              onClick={handleDismiss}
              disabled={modalWorking || apiKeyWorking}
              buttonType="text"
              text={t("common.close")}
            />
          </ModalControls>
        </form>
      )}
    </>
  );
}

ModalNewApiKey.displayName = "ModalNewApiKey";

export default ModalNewApiKey;
