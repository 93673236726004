import React, { forwardRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import SvgLoader from "app.components/Util/SvgLoader";

import MoreOptions from "app.components/Util/MoreOptions";

const FALLBACK_COLOR = "#4A95FF";

function getColor(colorId, colors) {
  const color = colors.find((c) => c.id === colorId);
  if (color) {
    return color.hex;
  }

  return FALLBACK_COLOR;
}

const Tag = forwardRef(
  (
    {
      onClick,
      label,
      disabled,
      colorId = 1,
      className,
      small = false,
      tagsApplied,
      deleteCallback,
      addToCallback,
      editCallback,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { colors } = useSelector((state) => state.tags);

    const color = useMemo(() => {
      return getColor(colorId, colors);
    }, [colorId, colors]);

    const tagClass = [
      "tranisiton-all",
      "group",
      "flex",
      "max-w-fit",
      "items-center",
      "rounded-sm",
      "",
      small ? "px-1.5" : "px-2",
      small ? "py-0.5" : "py-1",
      small ? "text-xs" : "text-sm",
      "text-black",
      "shadow-sm",
      "whitespace-nowrap",
      typeof className === "string" ? className : "",
    ].join(" ");

    if (typeof onClick === "function") {
      return (
        <button
          ref={ref}
          onClick={onClick}
          type="button"
          style={{ background: color }}
          className={tagClass}
          disabled={disabled}
          {...props}
        >
          {label}
          {typeof tagsApplied !== "undefined" ? ` (${tagsApplied})` : null}
        </button>
      );
    }

    const hasMultipleCallbacks =
      (typeof deleteCallback !== "undefined" ? 1 : 0) +
        (typeof editCallback !== "undefined" ? 1 : 0) +
        (typeof addToCallback !== "undefined" ? 1 : 0) >
      1;

    const options = hasMultipleCallbacks
      ? [
          {
            id: `editTag-${label}`,
            optionCallback: editCallback,
            label: (
              <span>{t("portfolio.tags.editTagName", { tagName: label })}</span>
            ),
          },
          {
            id: "disconnect",
            optionCallback: deleteCallback,
            danger: true,
            label: (
              <span>
                {t("portfolio.tags.deleteTagName", { tagName: label })}
              </span>
            ),
          },
        ].filter(Boolean)
      : [];

    const toggle =
      deleteCallback && editCallback ? (
        <MoreOptions toggleAsCaret bound="right" options={options} />
      ) : null;

    return (
      <span ref={ref} style={{ background: color }} className={tagClass}>
        {label}
        {typeof tagsApplied !== "undefined" ? ` (${tagsApplied})` : null}
        {!hasMultipleCallbacks && addToCallback ? (
          <button
            type="button"
            className="ml-1 cursor-pointer"
            onClick={addToCallback}
          >
            <span className="block flex h-3 w-3 min-w-3 items-center justify-center leading-none text-white opacity-30 group-hover:opacity-100">
              +
            </span>
          </button>
        ) : null}
        {!hasMultipleCallbacks && deleteCallback ? (
          <button
            type="button"
            className="ml-1 cursor-pointer"
            onClick={deleteCallback}
          >
            <SvgLoader
              id="Close"
              className="h-3 w-3 stroke-white opacity-30 transition-all group-hover:opacity-100"
            />
          </button>
        ) : null}
        {!hasMultipleCallbacks && editCallback ? (
          <button
            type="button"
            className="ml-1 cursor-pointer"
            onClick={editCallback}
          >
            <SvgLoader
              id="PencilSquare"
              className="h-3 w-3 stroke-white opacity-30 transition-all group-hover:opacity-100"
            />
          </button>
        ) : null}
        {toggle}
      </span>
    );
  }
);

Tag.displayName = "Tag";

export default Tag;
