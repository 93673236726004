import { call, put } from "redux-saga/effects";
import {
  requestAccountingIntegrationSync,
  receiveAccountingIntegrationSync,
  accountingIntegrationSyncError,
} from "app.reducers/accountingIntegrations";

import ExchangesAPI from "app.api/ExchangesAPI";
import { getLoadingState } from "app.utils/selectors";

function* accountingIntegrationSync(action) {
  const { accountingIntegrationName, connectionId } = action.payload;

  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.tags.syncStatus
  );

  if (isLoading) return;

  yield put(requestAccountingIntegrationSync(nextStatus));

  const response = yield call(
    ExchangesAPI.syncAccountingIntegration,
    accountingIntegrationName,
    connectionId
  );

  if (response.error) {
    console.error(
      "Error in accountingIntegrationSync",
      response.body,
      response.error
    );

    const keys = Object.keys(response.body);

    const errors = keys.map((key) => response.body[key]);

    yield put(accountingIntegrationSyncError(errors));
  } else {
    yield put(receiveAccountingIntegrationSync());
  }
}

export default accountingIntegrationSync;
