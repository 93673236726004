import { call, put } from "redux-saga/effects";
import { getLoadingState } from "app.utils/selectors";
import {
  requestSettings,
  settingsError,
  receiveSettings,
} from "app.reducers/exchanges";
import SettingsAPI from "app.api/SettingsAPI";
// import handleUserLanguage from "app.sagas/account/handleUserLanguage";
import { STATUS } from "app.constants";

function* fetchSettings() {
  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.exchanges.settingsStatus
  );

  if (isLoading) return;

  yield put(requestSettings(nextStatus));

  const response = yield call(SettingsAPI.getSettings);

  if (response.error) {
    yield put(settingsError(response.body, STATUS.ERROR));
  } else {
    yield put(receiveSettings(response.body, STATUS.LOADED));
  }
}

export default fetchSettings;
