import ModalAbout from "app.components/Modals/ModalAbout";
import ModalAcceptTOS from "app.components/Modals/ModalAcceptTOS";
import ModalAddExchangeImport from "app.components/Modals/ModalAddExchangeImport";
import ModalAddManagedAccount from "app.components/Modals/ModalAddManagedAccount";
import ModalAddressLabel from "app.components/Modals/ModalAddressLabel";
import ModalAddTeamMember from "app.components/Modals/ModalAddTeamMember";
import ModalBulkTags from "app.components/Modals/ModalBulkTags";
import ModalChangePlan from "app.components/Modals/ModalChangePlan";
import ModalConfirmDialog from "app.components/Modals/ModalConfirmDialog";
import ModalEditManagedAccount from "app.components/Modals/ModalEditManagedAccount";
import ModalEditTag from "app.components/Modals/ModalEditTag";
import ModalExchangeApiKeyPopUp from "app.components/Modals/ModalExchangeApiKeyPopUp";
import ModalExchangeConnectionSelect from "app.components/Modals/ModalExchangeConnectionSelect";
import ModalExchangeOauthPopUp from "app.components/Modals/ModalExchangeOauthPopUp";
import ModalExchangeWalletTokenPopUp from "app.components/Modals/ModalExchangeWalletTokenPopUp";
import ModalExternalTransfer from "app.components/Modals/ModalExternalTransfer";
import ModalInfo from "app.components/Modals/ModalInfo";
import ModalImportAssetAccounts from "app.components/Modals/ModalImportAssetAccounts";
import ModalImportHistory from "app.components/Modals/ModalImportHistory";
import ModalLedgerMemo from "app.components/Modals/ModalLedgerMemo";
import ModalMFADisabled from "app.components/Modals/ModalMFADisabled";
import ModalMFAEnabled from "app.components/Modals/ModalMFAEnabled";
import ModalNewApiKey from "app.components/Modals/ModalNewApiKey";
import ModalNFT from "app.components/Modals/ModalNFT";
import ModalPasswordSettings from "app.components/Modals/ModalPasswordSettings";
import ModalPayable from "app.components/Modals/ModalPayable";
import ModalProfileInfo from "app.components/Modals/ModalProfileInfo";
import ModalProfileSettings from "app.components/Modals/ModalProfileSettings";
import ModalReinvite from "app.components/Modals/ModalReinvite";
import ModalRenameLinkedAccount from "app.components/Modals/ModalRenameLinkedAccount";
import ModalRenameWallet from "app.components/Modals/ModalRenameWallet";
import ModalSelectiveArchive from "app.components/Modals/ModalSelectiveArchive";
import ModalSelectiveDelete from "app.components/Modals/ModalSelectiveDelete";
import ModalShowKeys from "app.components/Modals/ModalShowKeys";
import ModalSourcelessTransferIn from "app.components/Modals/ModalSourcelessTransferIn";
import ModalSuggestedMappings from "app.components/Modals/ModalSuggestedMappings";
import ModalSyncAll from "app.components/Modals/ModalSyncAll";
import ModalTagGroup from "app.components/Modals/ModalTagGroup";
import ModalUpdateBilling from "app.components/Modals/ModalUpdateBilling";
import ModalUploadBulkAddressLabels from "app.components/Modals/ModalUploadBulkAddressLabels";
import ModalUpgrade from "app.components/Modals/ModalUpgrade";

export const MODAL_ABOUT = "MODAL_ABOUT";
export const MODAL_ACCEPT_TOS = "MODAL_ACCEPT_TOS";
export const MODAL_ADDRESS_LABEL = "MODAL_ADDRESS_LABEL";
export const MODAL_ADD_EXCHANGE_IMPORT = "MODAL_ADD_EXCHANGE_IMPORT";
export const MODAL_ADD_IMPORT = "MODAL_ADD_IMPORT";
export const MODAL_ADD_MANAGED_ACCOUNT = "MODAL_ADD_MANAGED_ACCOUNT";
export const MODAL_ADD_TEAM_MEMBER = "MODAL_ADD_TEAM_MEMBER";
export const MODAL_BULK_TAGS = "MODAL_BULK_TAGS";
export const MODAL_BULK_ADDRESS_LABELS = "MODAL_BULK_ADDRESS_LABELS";
export const MODAL_CHANGE_PLAN = "MODAL_CHANGE_PLAN";
export const MODAL_CONFIRM_DIALOG = "MODAL_CONFIRM_DIALOG";
export const MODAL_DATA_EXPORT = "MODAL_DATA_EXPORT";
export const MODAL_EDIT_MANAGED_ACCOUNT = "MODAL_EDIT_MANAGED_ACCOUNT";
export const MODAL_EDIT_TAG = "MODAL_EDIT_TAG";
export const MODAL_EXCHANGE_API_KEY_POP_UP = "MODAL_EXCHANGE_API_KEY_POP_UP";
export const MODAL_EXCHANGE_CONNECTION_SELECT =
  "MODAL_EXCHANGE_CONNECTION_SELECT";
export const MODAL_EXCHANGE_OAUTH_POP_UP = "MODAL_EXCHANGE_OAUTH_POP_UP";
export const MODAL_EXCHANGE_WALLET_TOKEN_POP_UP =
  "MODAL_EXCHANGE_WALLET_TOKEN_POP_UP";
export const MODAL_EXTERNAL_TRANSFER = "MODAL_EXTERNAL_TRANSFER";
export const MODAL_IMPORT_ASSET_ACCOUNTS = "MODAL_IMPORT_ASSET_ACCOUNTS";
export const MODAL_IMPORT_HISTORY = "MODAL_IMPORT_HISTORY";
export const MODAL_INFO = "MODAL_INFO";
export const MODAL_LEDGER_MEMO = "MODAL_LEDGER_MEMO";
export const MODAL_MFA_DISABLED = "MODAL_MFA_DISABLED";
export const MODAL_MFA_ENABLED = "MODAL_MFA_ENABLED";
export const MODAL_NEW_API_KEY = "MODAL_NEW_API_KEY";
export const MODAL_NFT = "MODAL_NFT";
export const MODAL_ORGANIZATION_TEAM_MEMBERS =
  "MODAL_ORGANIZATION_TEAM_MEMBERS";
export const MODAL_PASSWORD_SETTINGS = "MODAL_PASSWORD_SETTINGS";
export const MODAL_PAYABLE = "MODAL_PAYABLE";
export const MODAL_PROFILE_INFO = "MODAL_PROFILE_INFO";
export const MODAL_PROFILE_SETTINGS = "MODAL_PROFILE_SETTINGS";
export const MODAL_REINVITE = "MODAL_REINVITE";
export const MODAL_RENAME_LINKED_ACCOUNT = "MODAL_RENAME_LINKED_ACCOUNT";
export const MODAL_RENAME_WALLET = "MODAL_RENAME_WALLET";
export const MODAL_SHOW_KEYS = "MODAL_SHOW_KEYS";
export const MODAL_TRANSACTION = "MODAL_TRANSACTION";
export const MODAL_UPDATE_BILLING = "MODAL_UPDATE_BILLING";
export const MODAL_UPGRADE = "MODAL_UPGRADE";
export const MODAL_UPLOAD_ADDRESSES = "MODAL_UPLOAD_ADDRESSES";
export const MODAL_UPLOAD_TRANSACTION_ANNOTATIONS =
  "MODAL_UPLOAD_TRANSACTION_ANNOTATIONS";
export const MODAL_WALLET_DOCUMENTS = "MODAL_WALLET_DOCUMENTS";
export const MODAL_SYNC_ALL = "MODAL_SYNC_ALL";
export const MODAL_SELECTIVE_DELETE_LEDGERS = "MODAL_SELECTIVE_DELETE_LEDGERS";
export const MODAL_SELECTIVE_ARCHIVE_LEDGERS =
  "MODAL_SELECTIVE_ARCHIVE_LEDGERS";
export const MODAL_SOURCELESS_TRANSFER_IN = "MODAL_SOURCELESS_TRANSFER_IN";
export const MODAL_SUGGESTED_MAPPINGS = "MODAL_SUGGESTED_MAPPINGS";
export const MODAL_TAG_GROUP = "MODAL_TAG_GROUP";

const ModalComponents = {
  [MODAL_ABOUT]: ModalAbout,
  [MODAL_ACCEPT_TOS]: ModalAcceptTOS,
  [MODAL_ADDRESS_LABEL]: ModalAddressLabel,
  [MODAL_ADD_EXCHANGE_IMPORT]: ModalAddExchangeImport,
  [MODAL_ADD_MANAGED_ACCOUNT]: ModalAddManagedAccount,
  [MODAL_ADD_TEAM_MEMBER]: ModalAddTeamMember,
  [MODAL_BULK_TAGS]: ModalBulkTags,
  [MODAL_BULK_ADDRESS_LABELS]: ModalUploadBulkAddressLabels,
  [MODAL_CHANGE_PLAN]: ModalChangePlan,
  [MODAL_CONFIRM_DIALOG]: ModalConfirmDialog,
  [MODAL_EDIT_MANAGED_ACCOUNT]: ModalEditManagedAccount,
  [MODAL_EDIT_TAG]: ModalEditTag,
  [MODAL_EXCHANGE_API_KEY_POP_UP]: ModalExchangeApiKeyPopUp,
  [MODAL_EXCHANGE_CONNECTION_SELECT]: ModalExchangeConnectionSelect,
  [MODAL_EXCHANGE_OAUTH_POP_UP]: ModalExchangeOauthPopUp,
  [MODAL_EXCHANGE_WALLET_TOKEN_POP_UP]: ModalExchangeWalletTokenPopUp,
  [MODAL_EXTERNAL_TRANSFER]: ModalExternalTransfer,
  [MODAL_IMPORT_ASSET_ACCOUNTS]: ModalImportAssetAccounts,
  [MODAL_IMPORT_HISTORY]: ModalImportHistory,
  [MODAL_INFO]: ModalInfo,
  [MODAL_LEDGER_MEMO]: ModalLedgerMemo,
  [MODAL_MFA_DISABLED]: ModalMFADisabled,
  [MODAL_MFA_ENABLED]: ModalMFAEnabled,
  [MODAL_NEW_API_KEY]: ModalNewApiKey,
  [MODAL_NFT]: ModalNFT,
  [MODAL_PASSWORD_SETTINGS]: ModalPasswordSettings,
  [MODAL_PAYABLE]: ModalPayable,
  [MODAL_PROFILE_INFO]: ModalProfileInfo,
  [MODAL_PROFILE_SETTINGS]: ModalProfileSettings,
  [MODAL_REINVITE]: ModalReinvite,
  [MODAL_RENAME_LINKED_ACCOUNT]: ModalRenameLinkedAccount,
  [MODAL_RENAME_WALLET]: ModalRenameWallet,
  [MODAL_SHOW_KEYS]: ModalShowKeys,
  [MODAL_UPDATE_BILLING]: ModalUpdateBilling,
  [MODAL_UPGRADE]: ModalUpgrade,
  [MODAL_SYNC_ALL]: ModalSyncAll,
  [MODAL_SELECTIVE_DELETE_LEDGERS]: ModalSelectiveDelete,
  [MODAL_SELECTIVE_ARCHIVE_LEDGERS]: ModalSelectiveArchive,
  [MODAL_SOURCELESS_TRANSFER_IN]: ModalSourcelessTransferIn,
  [MODAL_SUGGESTED_MAPPINGS]: ModalSuggestedMappings,
  [MODAL_TAG_GROUP]: ModalTagGroup,
};

export default ModalComponents;
