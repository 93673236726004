import React, { useState } from "react";
import { Input, Label } from "app.components/Util/Form";
import { useTranslation } from "react-i18next";
import { checkPasswordsMatch, checkPasswordLength } from "app.utils";
import { PASSWORD_MIN_LENGTH } from "app.constants";

function getAttrs(passwwordAttrs) {
  if (passwwordAttrs) {
    const { label, labelDescription, value, callback, ...rest } =
      passwwordAttrs;
    return { label, labelDescription, value, callback, rest };
  }

  return undefined;
}

const PasswordInput = ({
  className,
  disabled,
  currentPassword,
  newPassword,
  confirmNewPassword,
}) => {
  const { t } = useTranslation();
  const [showText, setShowText] = useState(false);

  const [passwordError, setPasswordError] = useState();
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState();

  const checkConfirmNewPassword = () => {
    if (
      newPassword.value &&
      confirmNewPassword.value &&
      !checkPasswordsMatch(newPassword.value, confirmNewPassword.value)
    ) {
      setConfirmNewPasswordError(
        t("constants.error.account.password_mismatch")
      );
    }
  };

  const checkPassword = () => {
    if (newPassword.value && !checkPasswordLength(newPassword.value)) {
      setPasswordError(
        t("constants.error.account.password_too_weak", {
          num: PASSWORD_MIN_LENGTH,
        })
      );
    }
    checkConfirmNewPassword();
  };

  const currentAttr = getAttrs(currentPassword);
  const passwordAttr = getAttrs(newPassword);
  const confirmNewPasswordAttr = getAttrs(confirmNewPassword);

  return (
    <div className={className}>
      {currentPassword ? (
        <>
          <div className="flex items-end justify-between md:items-center">
            <Label htmlFor="password">{currentAttr.label}</Label>
            <button
              className="text-xxs flex justify-end text-zinc-500 underline"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setShowText((prev) => !prev);
              }}
            >
              {t(showText ? "button.hide" : "button.reveal")}
            </button>
          </div>
          <Input
            type={showText ? "text" : "password"}
            value={currentAttr.value}
            className="mb-3 w-full"
            name="currentPassword"
            data-testid="currentPassword"
            id="currentPassword"
            autoComplete="currentPassword"
            disabled={disabled}
            onChange={(e) => {
              e.preventDefault();
              currentAttr.callback(e.target.name, e.target.value);
            }}
            {...currentAttr.rest}
          />
        </>
      ) : null}

      {newPassword ? (
        <>
          <div className="flex items-end justify-between md:items-center">
            <Label
              htmlFor="newPassword"
              description={!passwordError && passwordAttr.labelDescription}
              error={passwordError}
            >
              {passwordAttr.label}
            </Label>
            {!currentPassword ? (
              <button
                className="text-xxs flex justify-end text-zinc-500 underline"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setShowText((prev) => !prev);
                }}
              >
                {t(showText ? "button.hide" : "button.reveal")}
              </button>
            ) : null}
          </div>
          <Input
            type={showText ? "text" : "password"}
            value={passwordAttr.value}
            className="mb-3 w-full"
            name="newPassword"
            id="newPassword"
            data-testid="newPassword"
            autoComplete="password"
            disabled={disabled}
            onBlur={checkPassword}
            onChange={(e) => {
              e.preventDefault();
              setPasswordError("");
              setConfirmNewPasswordError("");
              passwordAttr.callback(e.target.name, e.target.value);
            }}
            hasError={passwordError}
            {...passwordAttr.rest}
          />
        </>
      ) : null}

      {confirmNewPassword ? (
        <>
          <Label
            htmlFor="confirmNewPassword"
            description={
              !confirmNewPasswordError &&
              confirmNewPasswordAttr.labelDescription
            }
            error={confirmNewPasswordError}
          >
            {confirmNewPasswordAttr.label}
          </Label>
          <Input
            className="mb-3 w-full"
            type={showText ? "text" : "password"}
            value={confirmNewPasswordAttr.value}
            name="confirmNewPassword"
            id="confirmNewPassword"
            data-testid="confirmNewPassword"
            autoComplete="confirmNewPassword"
            disabled={disabled}
            onBlur={checkConfirmNewPassword}
            onChange={(e) => {
              e.preventDefault();
              setConfirmNewPasswordError("");
              confirmNewPasswordAttr.callback(e.target.name, e.target.value);
            }}
            hasError={confirmNewPasswordError}
            {...confirmNewPasswordAttr.rest}
          />
        </>
      ) : null}
    </div>
  );
};

PasswordInput.displayName = "PasswordInput";

export default PasswordInput;
