import { call, put, all } from "redux-saga/effects";
import {
  requestApiKeyVaults,
  receiveApiKeyVaults,
  receiveApiKeyVaultsError,
} from "app.reducers/exchanges";
import { dismissModal, doRedirect } from "app.reducers/ui";
import ExchangesAPI from "app.api/ExchangesAPI";

function* fetchApiKeyVault(action) {
  const { exchange, apiKey, secretKey } = action.payload;

  yield put(requestApiKeyVaults());

  const response = yield call(
    ExchangesAPI.getVaultList,
    exchange.id,
    apiKey,
    secretKey
  );

  const { body, error } = response;
  if (error) {
    if (typeof body.metadata !== "undefined") {
      yield put(
        receiveApiKeyVaultsError(
          { type: "bad-key", message: body.metadata.message },
          error
        )
      );
    } else {
      yield put(receiveApiKeyVaults(body, error));
    }
  } else {
    yield all([
      put(receiveApiKeyVaults(body, apiKey, secretKey)),
      put(dismissModal()),
      put(doRedirect(`/portfolio/add-ledger/select-vaults/${exchange.id}`)),
    ]);
  }
}

export default fetchApiKeyVault;
