import React from "react";
import Button from "app.components/Util/Button";

const GroupButton = ({
  groupType = "square",
  selectOptions,
  buttonSize = "md",
  selectOption,
  selectedOption,
  customClasses,
}) => {
  const isGroupCircle = groupType === "circle";

  const className = [
    "flex",
    "shadow-sm",
    isGroupCircle
      ? "items-center rounded-full bg-blue-600 p-0.5"
      : "rounded-sm flex-initial",
    customClasses,
  ].join(" ");

  const baseGroupType = isGroupCircle
    ? "secondaryControlGroup"
    : "controlGroup";

  const activeGroupType = isGroupCircle
    ? "secondaryControlGroupActive"
    : "controlGroupActive";

  return (
    <div className={className}>
      {selectOptions.map((option) => (
        <Button
          key={option.id}
          onClick={(e) => {
            e.stopPropagation();
            selectOption(option.id);
          }}
          buttonSize={buttonSize}
          buttonType={
            selectedOption === option.id ? activeGroupType : baseGroupType
          }
          customClasses={!isGroupCircle ? "first:rounded-l last:rounded-r" : ""}
        >
          {option.icon ? (
            <span className="flex flex-row items-center gap-1">
              <span
                className={
                  selectedOption === option.id ? "fill-white" : "fill-blue-500"
                }
              >
                {option.icon}
              </span>
              <span>{option.text}</span>
            </span>
          ) : (
            option.text
          )}
        </Button>
      ))}
    </div>
  );
};

export default GroupButton;
