import { call, put, select, all } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import { STATUS } from "app.constants";

import {
  receiveEditTag,
  requestEditTag,
  editTagError,
  fetchTagsAndRules,
} from "app.reducers/tags";

import {
  dismissModal,
  startModalWorking,
  endModalWorking,
} from "app.reducers/ui";

function* EditTag(action) {
  const { tag, newTagName, colorId, description } = action.payload;

  const { editStatus } = yield select((state) => state.tags);

  if (editStatus === STATUS.WORKING) return;

  yield put(startModalWorking());
  yield put(requestEditTag());

  const response = yield call(
    ExchangeLedgerAPI.editTag,
    tag,
    newTagName,
    colorId,
    description
  );

  const { error } = response;
  if (error) {
    yield put(endModalWorking());
    yield put(editTagError());
  } else {
    yield put(receiveEditTag());
    yield all([
      put(endModalWorking()),
      put(dismissModal()),
      put(fetchTagsAndRules()),
    ]);
  }
}

export default EditTag;
