import { call, put } from "redux-saga/effects";
import * as tagsActions from "app.reducers/tags";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";
import { getLoadingState } from "app.utils/selectors";
import { STATUS, CONDITIONS } from "app.constants";

function* fetchTagMetadata() {
  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.tags.metadataStatus
  );

  if (isLoading) return;

  yield put(tagsActions.requestTagMetadata(nextStatus));

  const response = yield call(ExchangeLedgerAPI.getTagsMetadata);

  if (response.error) {
    console.error("Error in fetchTagMetadata", response.body, response.error);
    yield put(tagsActions.tagMetadataError(response.body, STATUS.ERROR));
  } else {
    const conditions = response.body.conditions.filter(
      (cond) => cond.condition.toUpperCase() !== CONDITIONS.NO_CONDITION
    );

    yield put(tagsActions.receiveTagMetadata(conditions, response.body.colors));
  }
}

export default fetchTagMetadata;
