import { call, put, all, select } from "redux-saga/effects";
import {
  requestDisconnectIntegration,
  receiveDisconnectIntegration,
  disconnectIntegrationError,
  fetchAccountingIntegrationsConnections,
} from "app.reducers/accountingIntegrations";

import {
  dismissModal,
  startModalWorking,
  endModalWorking,
} from "app.reducers/ui";

import ExchangesAPI from "app.api/ExchangesAPI";
import { STATUS } from "app.constants";

function* disconnectIntegration(action) {
  const { connectionId } = action.payload;

  const { disconnectingStatus } = yield select(
    (state) => state.accountingIntegrations
  );

  if (disconnectingStatus === STATUS.WORKING) return;

  yield put(requestDisconnectIntegration());
  yield put(startModalWorking());

  const response = yield call(
    ExchangesAPI.disconnnectConnectIntegration,
    connectionId
  );

  if (response.error) {
    console.error(
      "Error in disconnectIntegration",
      response.body,
      response.error
    );
    yield put(disconnectIntegrationError(response.body.message));
  } else {
    yield all([
      put(receiveDisconnectIntegration()),
      put(fetchAccountingIntegrationsConnections()),
      put(dismissModal()),
    ]);
  }
  yield put(endModalWorking());
}

export default disconnectIntegration;
