import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { dismissModal } from "app.reducers/ui";

import { upsertTagGroup } from "app.reducers/accountingIntegrations";

import { useTranslation } from "react-i18next";
import Button from "app.components/Util/Button";

import Input from "app.components/Util/Form/Input";
import Label from "app.components/Util/Form/Label";

import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";

function ModalTagGroup({
  tagGroups,
  tagGroup = undefined,
  canDismissCallback,
}) {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();

  const [name, setName] = useState(
    typeof tagGroup !== "undefined" ? tagGroup.name : ""
  );
  const [description, setDescription] = useState(
    typeof tagGroup !== "undefined" ? tagGroup.description : ""
  );

  const { modalWorking } = useSelector((state) => state.ui);
  const { upsertTagGroupError } = useSelector(
    (state) => state.accountingIntegrations
  );

  useEffect(() => {
    canDismissCallback(!modalWorking === true);
  }, [modalWorking, canDismissCallback]);

  const handleDismiss = (e) => {
    e.preventDefault();
    reduxDispatch(dismissModal());
  };

  // if a tag group with this name already exists, if it is a different group
  // that the one we are editing (if we are creating one, it will be undefined)
  const foundName = tagGroups.filter((g) => g.name === name);
  const nameExists = foundName.length && foundName[0].id !== tagGroup?.id;

  return (
    <>
      <ModalHeader
        title={
          typeof tagGroup !== "undefined"
            ? t("accountingIntegrations.editTagGroup")
            : t("accountingIntegrations.createTagGroup")
        }
        closeCallback={handleDismiss}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          reduxDispatch(upsertTagGroup(name, description, tagGroup?.id));
        }}
      >
        <div className="m-4">
          {upsertTagGroupError && (
            <p className="mb-2 text-red-700">{upsertTagGroupError}</p>
          )}
          <div>
            <Label
              required
              error={
                nameExists
                  ? t("accountingIntegrations.aGroupWithThisNameAlreadyExists")
                  : null
              }
              htmlFor="groupName"
            >
              {t("common.name")}
            </Label>
            <Input
              hasError={nameExists}
              maxLength="64"
              data-tabbable
              className="w-full"
              type="text"
              id="groupName"
              name="groupName"
              value={name}
              onChange={(e) => {
                e.preventDefault();
                setName(e.target.value);
              }}
            />
          </div>
          <div>
            <Label optional htmlFor="groupDescr">
              {t("common.description")}
            </Label>
            <Input
              maxLength="128"
              data-tabbable
              className="w-full"
              type="text"
              id="groupDescr"
              name="groupDescr"
              value={description}
              onChange={(e) => {
                e.preventDefault();
                setDescription(e.target.value);
              }}
            />
          </div>
        </div>

        <ModalControls>
          <Button
            type="submit"
            disabled={modalWorking || nameExists}
            working={modalWorking}
            workingText={t("button.saving")}
            buttonType="primary"
            customClasses="ml-4"
            text={
              typeof tagGroup !== "undefined"
                ? t("button.save")
                : t("accountingIntegrations.createTagGroup")
            }
          />
          <Button
            onClick={handleDismiss}
            disabled={modalWorking}
            buttonType="text"
            text={t("common.close")}
          />
        </ModalControls>
      </form>
    </>
  );
}

ModalTagGroup.displayName = "ModalTagGroup";

export default ModalTagGroup;
