import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { dismissModal } from "app.reducers/ui";
import { bulkCreateTag } from "app.reducers/tags";

import { useTranslation } from "react-i18next";
import Button from "app.components/Util/Button";
import Label from "app.components/Util/Form/Label";
import Input from "app.components/Util/Form/Input";
import Tag from "app.components/Settings/SettingsTags/Tag";

import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";

import { STATUS } from "app.constants";

function ModalBulkTags({ entryIds, isTimeline = true }) {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();
  const [newTag, setNewTag] = useState("");
  const [newTagColor, setNewTagColor] = useState(1);

  const { bulkCreateStatus, tags, colors } = useSelector((state) => state.tags);

  const existingTag = tags.find((curTag) => newTag === curTag.tag);
  const isSelectedExistingTag = typeof existingTag !== "undefined";

  const handleDismiss = (e) => {
    e.preventDefault();
    reduxDispatch(dismissModal());
  };

  const submitTag = () => {
    reduxDispatch(bulkCreateTag(entryIds, newTag, isTimeline, newTagColor));
  };

  const count = entryIds.length || 0;

  return (
    <>
      <ModalHeader
        title={t("modals.bulkTags.applyTagToTransactions", { count })}
        closeCallback={handleDismiss}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitTag();
        }}
      >
        <div className="m-4">
          <p className="mb-4 text-lg">
            {t("modals.bulkTags.bulkTagsDescription", { count })}
          </p>
          <div className="mb-4 flex items-end gap-3">
            <div className="flex w-52 flex-col gap-1">
              <Label htmlFor="newTag">{t("modals.bulkTags.enterATag")}</Label>
              <Input
                id="newTag"
                name="newTag"
                type="text"
                disabled={bulkCreateStatus === STATUS.WORKING}
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
              />
            </div>
            <div
              className={`${isSelectedExistingTag ? "opacity-70" : "opacity-100"} flex flex-col`}
            >
              <p className="text-sm leading-5 font-bold">
                {t("portfolio.tags.color")}
              </p>
              <ul className="mb-1 flex h-8 items-center gap-2 md:mb-0">
                {colors.map((c) => {
                  let className = "h-4 w-4 rounded-sm";
                  if (isSelectedExistingTag) {
                    if (existingTag.colorId === c.id) {
                      className = "h-4 w-4 rounded-sm ring-2 ring-black";
                    }
                  } else if (newTagColor === c.id) {
                    className = "h-4 w-4 rounded-sm ring-2 ring-black";
                  }

                  return (
                    <li key={c.hex} className="flex h-4 items-center">
                      <button
                        type="button"
                        disabled={
                          isSelectedExistingTag ||
                          bulkCreateStatus === STATUS.WORKING
                        }
                        className={className}
                        onClick={() => {
                          setNewTagColor(c.id);
                        }}
                        style={{ backgroundColor: c.hex }}
                      >
                        <span className="sr-only">{c.label}</span>
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          {tags.length > 0 ? (
            <div>
              <p className="mb-4">
                {t("modals.bulkTags.orChooseAnExistingTag")}
              </p>
              <div className="flex flex-wrap gap-2">
                {tags.map((tag) => (
                  <Tag
                    key={tag.tag}
                    disabled={bulkCreateStatus === STATUS.WORKING}
                    onClick={() => {
                      setNewTag(tag.tag);
                      setNewTagColor(tag.colorId);
                    }}
                    label={tag.tag}
                    colorId={tag.colorId}
                  />
                ))}
              </div>
            </div>
          ) : null}
        </div>

        <ModalControls>
          <Button
            type="submit"
            disabled={bulkCreateStatus === STATUS.WORKING || newTag === ""}
            buttonType="primary"
            customClasses="ml-4"
            text={t("modals.bulkTags.applyButton", { count })}
          />
          <Button
            onClick={handleDismiss}
            disabled={bulkCreateStatus === STATUS.WORKING}
            buttonType="text"
            text={t("common.close")}
          />
        </ModalControls>
      </form>
    </>
  );
}

ModalBulkTags.displayName = "ModalBulkTags";

export default ModalBulkTags;
