import { call, put, select, all } from "redux-saga/effects";
import {
  requestDeleteExchangeLedger,
  deleteExchangeLedgerError,
  receiveDeleteExchangeLedger,
} from "app.reducers/exchanges";
import {
  startModalWorking,
  endModalWorking,
  dismissModal,
  doRedirect,
} from "app.reducers/ui";
import ExchangesAPI from "app.api/ExchangesAPI";

function* deleteExchangeLedger(action) {
  const { ledgerId, redirectAfterDelete = false } = action.payload;

  const deletingLedger = yield select(
    (state) => state.exchanges.deletingLedger
  );

  const lastPortfolioTab = yield select((state) => state.ui.lastPortfolioTab);

  if (deletingLedger) return;

  yield all([put(requestDeleteExchangeLedger()), put(startModalWorking())]);

  const response = yield call(ExchangesAPI.deleteExchangeLedger, ledgerId);

  if (response.error) {
    yield all([
      put(deleteExchangeLedgerError(response.body, response.error)),
      put(endModalWorking()),
    ]);
  } else {
    if (redirectAfterDelete) {
      yield put(doRedirect(lastPortfolioTab));
    }
    yield all([
      put(receiveDeleteExchangeLedger(ledgerId)),
      put(dismissModal()),
    ]);
  }
}

export default deleteExchangeLedger;
