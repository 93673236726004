import { select, put, fork, cancel, take } from "redux-saga/effects";
import { selectDateRange } from "app.reducers/ui";

import {
  fetchExchangeLedgerSummary,
  fetchExchangeLedgerRange,
  EXCHANGE_LEDGER_IMPORT_RECEIVE,
  EXCHANGE_CHANGE_EXEMPT_STATUS_RECEIVE,
  EDIT_EXCHANGE_VALUE_RECEIVE,
  EDIT_EXCHANGE_ENTRY_DESCR_RECEIVE,
  EXCHANGE_TABBED_LEDGER_SECTION_LEAVE,
  EXCHANGE_TABBED_LEDGER_SECTION_CHANGE,
} from "app.actions/exchangeLedger";

import { receiveUpdateExchangeLedgersViaUpload } from "app.reducers/exchanges";

function* syncData(ledgerId) {
  while (true) {
    const { selectedDatePreset, selectedDateRange } = yield select(
      (state) => state.ui
    );

    yield put(
      fetchExchangeLedgerSummary(
        ledgerId,
        selectedDateRange,
        selectedDatePreset
      )
    );

    yield take([
      EXCHANGE_LEDGER_IMPORT_RECEIVE,
      receiveUpdateExchangeLedgersViaUpload,
      EXCHANGE_CHANGE_EXEMPT_STATUS_RECEIVE,
      EDIT_EXCHANGE_VALUE_RECEIVE,
      EDIT_EXCHANGE_ENTRY_DESCR_RECEIVE,
      selectDateRange,
    ]);
  }
}

function* fetchExchangeTabbedLedgerData(action) {
  const { ledgerId } = action;

  const dateRangeSync = yield fork(syncData, ledgerId);

  yield put(fetchExchangeLedgerRange(ledgerId));

  yield take([
    EXCHANGE_TABBED_LEDGER_SECTION_LEAVE,
    EXCHANGE_TABBED_LEDGER_SECTION_CHANGE,
  ]);

  yield cancel(dateRangeSync);
}

export default fetchExchangeTabbedLedgerData;
