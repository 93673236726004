/* eslint-disable no-param-reassign */
// this rule is disabled in reducers because the redux toolkit
// explicitly supports direct mutation of the state param
// https://redux-toolkit.js.org/usage/immer-reducers
import { createSlice } from "@reduxjs/toolkit";

import { STATUS } from "app.constants";

const initialState = {
  metadataStatus: STATUS.UNINITIALIZED,
  conditions: {},
  colors: {},
  tagsAndRulesStatus: STATUS.UNINITIALIZED,
  tags: [],
  rules: [],
  allTagsAndRules: [],
  bulkCreateStatus: STATUS.UNINITIALIZED,
  bulkCreateResponse: undefined,
  deleteStatus: STATUS.UNINITIALIZED,
  editStatus: STATUS.UNINITIALIZED,
};

export const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    // tags
    fetchTagsAndRules: () => {},
    requestTagsAndRules: {
      reducer: (state, action) => {
        state.tagsAndRulesStatus = action.payload.nextStatus;
      },
      prepare: (nextStatus) => {
        return { payload: { nextStatus } };
      },
    },
    receiveTagsAndRules: {
      reducer: (state, action) => {
        state.tagsAndRulesStatus = STATUS.LOADED;
        state.tags = action.payload.tags;
        state.rules = action.payload.rules;
        state.allTagsAndRules = action.payload.allTagsAndRules;
      },
      prepare: (tags, rules, allTagsAndRules) => {
        return { payload: { tags, rules, allTagsAndRules } };
      },
    },
    tagsAndRulesError: (state) => {
      state.tagsAndRulesStatus = STATUS.ERROR;
    },
    // metadata
    fetchTagMetadata: () => {},
    requestTagMetadata: {
      reducer: (state, action) => {
        state.metadataStatus = action.payload.nextStatus;
      },
      prepare: (nextStatus) => {
        return { payload: { nextStatus } };
      },
    },
    receiveTagMetadata: {
      reducer: (state, action) => {
        state.metadataStatus = STATUS.LOADED;
        state.conditions = action.payload.conditions;
        state.colors = action.payload.colors;
      },
      prepare: (conditions, colors) => {
        return { payload: { conditions, colors } };
      },
    },
    tagMetadataError: (state) => {
      state.metadataStatus = STATUS.ERROR;
    },

    // create tags
    resetBulkCreate: (state) => {
      state.bulkCreateStatus = STATUS.UNINITIALIZED;
      state.bulkCreateResponse = undefined;
    },
    bulkCreateTag: {
      reducer: (state) => {
        state.bulkCreateStatus = STATUS.UNINITIALIZED;
      },
      prepare(
        ledgerItemIds,
        tag,
        isTimeline,
        colorId,
        conditions,
        description,
        skipDataReload
      ) {
        return {
          payload: {
            ledgerItemIds,
            tag,
            isTimeline,
            colorId,
            conditions,
            description,
            skipDataReload,
          },
        };
      },
    },
    requestBulkCreateTag: (state) => {
      state.bulkCreateStatus = STATUS.WORKING;
    },
    receiveBulkCreateTag: {
      reducer: (state, action) => {
        state.bulkCreateStatus = STATUS.SUCCESS;
        state.bulkCreateResponse = action.payload.data;
      },
      prepare(data) {
        return { payload: { data } };
      },
    },
    bulkCreateTagError: {
      reducer: (state) => {
        state.bulkCreateStatus = STATUS.ERROR;
      },
      prepare(data) {
        return { payload: { data } };
      },
    },
    // delete tag
    deleteTag: {
      reducer: () => {},
      prepare(ledgerItemId, conditionId, tag) {
        return { payload: { ledgerItemId, conditionId, tag } };
      },
    },
    requestDeleteTag: {
      reducer: (state) => {
        state.deleteStatus = STATUS.WORKING;
      },
    },
    receiveDeleteTag: {
      reducer: (state) => {
        state.deleteStatus = STATUS.SUCCESS;
      },
      prepare(updatedItems) {
        return { payload: { updatedItems } };
      },
    },
    deleteTagError: {
      reducer: (state) => {
        state.deleteStatus = STATUS.ERROR;
      },
    },
    // edit tag
    editTag: {
      reducer: () => {},
      prepare: (tag, newTagName, colorId, description) => {
        return { payload: { tag, newTagName, colorId, description } };
      },
    },
    requestEditTag: {
      reducer: (state) => {
        state.editStatus = STATUS.WORKING;
      },
    },
    receiveEditTag: {
      reducer: (state) => {
        state.editStatus = STATUS.SUCCESS;
      },
    },
    editTagError: {
      reducer: (state) => {
        state.editStatus = STATUS.ERROR;
      },
    },
  },
});

// all actions
export const { actions } = tagsSlice;

// individual actions
export const {
  // tags
  fetchTagsAndRules,
  requestTagsAndRules,
  receiveTagsAndRules,
  tagsAndRulesError,
  // metadata
  fetchTagMetadata,
  requestTagMetadata,
  receiveTagMetadata,
  tagMetadataError,
  // create tags
  resetBulkCreate,
  bulkCreateTag,
  requestBulkCreateTag,
  receiveBulkCreateTag,
  bulkCreateTagError,
  // delete tags
  deleteTag,
  receiveDeleteTag,
  requestDeleteTag,
  deleteTagError,
  // edit tag
  editTag,
  requestEditTag,
  receiveEditTag,
  editTagError,
} = tagsSlice.actions;

export default tagsSlice.reducer;
