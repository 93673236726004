import { call, put, all, select, delay } from "redux-saga/effects";
import {
  requestBulkMapTokenAccounts,
  receiveBulkMapTokenAccounts,
  fetchAccountMappings,
} from "app.reducers/accountingIntegrations";

import {
  dismissModal,
  startModalWorking,
  endModalWorking,
  addFlashMessage,
  removeFlashMessage,
} from "app.reducers/ui";

import i18n from "i18next";

import { generateGUID, numToWord } from "app.utils";

import ExchangesAPI from "app.api/ExchangesAPI";

import { TEN_SECONDS, STATUS } from "app.constants";

import {
  STATUS_WARNING,
  STATUS_SUCCESS,
} from "app.components/Messages/FlashMessage";

function* safeCall(fn, ...args) {
  try {
    const result = yield call(fn, ...args);
    return { result, args }; // Store success result
  } catch (error) {
    return { error, args }; // Store error instead of throwing
  }
}

function* bulkMapTokenAccounts(action) {
  const { connectionId, mappings } = action.payload;

  const { accountMappingStatus } = yield select(
    (state) => state.accountingIntegrations
  );

  if (accountMappingStatus === STATUS.WORKING) return;

  yield put(requestBulkMapTokenAccounts());
  yield put(startModalWorking());

  const toDoList = mappings.map((mapping) =>
    safeCall(
      ExchangesAPI.tokenAccountMapping,
      mapping.smartWalletGUID,
      parseInt(mapping.matchingAccount.id, 10),
      connectionId,
      null
    )
  );

  const results = yield all(toDoList); // All calls execute regardless of failure

  const successes = results
    .filter((res) => {
      return !res.result.error;
    })
    .map((res) => res);

  const failures = results.filter((res) => res.result.error).map((res) => res);

  yield put(receiveBulkMapTokenAccounts());
  yield put(fetchAccountMappings(connectionId));
  yield put(endModalWorking());
  yield put(dismissModal());

  if (failures.length) {
    const { smartWalletList } = yield select((state) => state.smartWallet);

    const tokens = failures
      .map((f) => {
        const smartWallet = smartWalletList.find(
          (sw) => sw.smartWalletGUID === f.args[0]
        );
        return smartWallet.tokenName;
      })
      .join(", ");

    const messageId = generateGUID();
    const failureMessage = i18n.t(
      "accountingIntegrations.bulkMappingFailures",
      {
        tokens,
        countAsWord: numToWord(failures.length),
        count: failures.length,
      }
    );

    yield put(addFlashMessage(failureMessage, messageId, STATUS_WARNING));
    yield delay(TEN_SECONDS);
    yield put(removeFlashMessage(messageId));
  }

  if (successes.length) {
    const messageId = generateGUID();
    const successMessage = i18n.t("accountingIntegrations.bulkMappingSuccess", {
      countAsWord: numToWord(successes.length),
      count: successes.length,
    });

    yield put(addFlashMessage(successMessage, messageId, STATUS_SUCCESS));
    yield delay(TEN_SECONDS);
    yield put(removeFlashMessage(messageId));
  }
}

export default bulkMapTokenAccounts;
