import api from "../clients/exchange";

/**
 * Submits a request to initial an exchange connection
 * @async
 * @function
 * @param {number} accountingIntegrationName - The id of the integration
 * @param {string} slug - The exchange slug
 * @returns {object}  response - The Response or Error message
 */
export const initConnectIntegration = (accountingIntegrationName, slug) => {
  const params = new URLSearchParams({ slug });
  return api.get(
    `/accountingIntegration/${accountingIntegrationName}/initOAuth`,
    {
      params,
      authenticated: true,
    }
  );
};

/**
 * Submits a request to disconnection an accounting integration connection
 * @async
 * @function
 * @param {number} integrationLinkId - The id of the connection
 * @returns {object}  response - The Response or Error message
 */
export const disconnnectConnectIntegration = (integrationLinkId) => {
  const params = new URLSearchParams({ integrationLinkId });
  return api.post(`/accountingIntegration/disconnect`, {
    params,
    authenticated: true,
  });
};

/**
 * Submits a request to get the list of accounting integration connections
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getConnections = () => {
  return api.get(`/accountingIntegration/connections`, {
    authenticated: true,
  });
};

/**
 * Submits a request to get the chart of accounts, vendors and customers
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getAccountingEntities = (accountingIntegrationName) => {
  return api.get(
    `/accountingIntegration/${accountingIntegrationName}/accountingEntities`,
    {
      authenticated: true,
    }
  );
};

export const getTagGroups = () => {
  return api.get(`/tag/tagGroups`, {
    authenticated: true,
  });
};

export const upsertTagGroup = (name, description, id = undefined) => {
  const data = {
    name,
    description,
  };

  if (typeof id !== "undefined") {
    data.id = id;
  }

  return api.post(`/tag/upsertTagGroup`, {
    data,
    authenticated: true,
  });
};

export const deleteTagGroup = (tagGroupId) => {
  const query = new URLSearchParams();
  query.append("tagGroupId", tagGroupId);

  return api.post(`/tag/deleteTagGroup`, {
    params: query,
    authenticated: true,
  });
};

// to remove a tag from a group - send groupId: null
export const assignTagToGroup = (tag, groupId) => {
  const data = {
    tag,
    groupId,
  };

  return api.post(`/tag/assignGroups`, {
    data,
    authenticated: true,
  });
};

export const tokenAccountMapping = (
  smartWalletGUID,
  tokenAccountId,
  connectionId,
  mapId
) => {
  const data = {
    id: mapId || null,
    tokenAccountId,
    smartWalletGUID,
    linkId: connectionId,
  };

  return api.post(`/accountingIntegration/upsertTokenAccountMapping`, {
    data,
    authenticated: true,
  });
};

export const getAccountMappings = (connectionId) => {
  const query = new URLSearchParams();
  query.append("linkId", connectionId);

  return api.get(`/accountingIntegration/accountMappings`, {
    params: query,
    authenticated: true,
  });
};

export const defaultAccountMapping = (
  feeAccountId,
  gainLossAccountId,
  connectionId,
  mapId
) => {
  const data = {
    id: mapId || null,
    gainLossAccountId: gainLossAccountId || null,
    feeAccountId: feeAccountId || null,
    linkId: connectionId,
  };

  return api.post(`/accountingIntegration/upsertDefaultTokenMapping`, {
    data,
    authenticated: true,
  });
};

export const tagGroupToAccountMapping = (
  tagGroupId,
  connectionId,
  mapId,
  accountId,
  counterPartyId,
  feeAccountId,
  gainLossAccountId
) => {
  const data = {
    id: mapId || null,
    linkId: connectionId,
    accountId, // id from quickbooks
    tagGroupId,
    counterPartyId: counterPartyId || null, // customer or vendor
    gainLossAccountId: gainLossAccountId || null,
    feeAccountId: feeAccountId || null,
  };

  return api.post(`/accountingIntegration/upsertTagGroupAccountMapping`, {
    data,
    authenticated: true,
  });
};

export const syncAccountingIntegration = (
  accountingIntegrationName,
  connectionId
) => {
  const query = new URLSearchParams();
  query.append("integrationLinkId", connectionId);

  return api.post(`/accountingIntegration/${accountingIntegrationName}/sync`, {
    params: query,
    authenticated: true,
  });
};

/**
 * Submits a request to update a tag
 *
 * @async
 * @function
 * @param {string} tag - The id of the transaction/item
 * @returns {object}  response - The Response or Error message
 */
export const mapCounterParty = (tag, counterPartyMappings) => {
  const data = {
    tag,
    counterPartyMappings,
  };

  return api.post(`/tag/update`, {
    authenticated: true,
    data,
  });
};
