/* eslint-disable no-param-reassign */
// this rule is disabled in reducers because the redux toolkit
// explicitly supports direct mutation of the state param
// https://redux-toolkit.js.org/usage/immer-reducers

import { createSlice } from "@reduxjs/toolkit";

// import * as portfolioActions from "app.reducers/portfolios";
import * as uiActions from "app.reducers/ui";
import * as exchangeActions from "app.reducers/exchanges";

import { STATUS } from "app.constants";

const initialState = {
  renamingWallet: false,
  renameWalletError: undefined,
  portfolioStatus: STATUS.UNINITIALIZED,
};

export const portfoliosSlice = createSlice({
  name: "portfolios",
  initialState,
  reducers: {
    reinitializePortfolio: () => initialState,
    enterPortfolioSection: (state) => {
      state.portfolioStatus = STATUS.INITIAL;
    },
    leavePortfolioSection: (state) => {
      state.portfolioStatus = STATUS.UNINITIALIZED;
    },
    resetPortfolioData: () => {},
    resetAddLedger: () => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(exchangeActions.requestRenameLedger, (state) => {
        state.renamingWallet = true;
      })
      .addCase(exchangeActions.receiveRenameLedger, (state) => {
        state.renamingWallet = false;
      })
      .addCase(exchangeActions.renameLedgerError, (state, action) => {
        state.renamingWallet = false;
        state.renameWalletError = action.response;
      })
      .addCase(uiActions.dismissModal, (state) => {
        state.renameWalletError = undefined;
      });
  },
});

// all actions
export const { actions } = portfoliosSlice;

// individual actions
export const {
  reinitializePortfolio,
  enterPortfolioSection,
  leavePortfolioSection,
  resetPortfolioData,
  resetAddLedger,
} = portfoliosSlice.actions;

export default portfoliosSlice.reducer;
