import React from "react";
import PlaceholderLine from "app.components/Placeholder/PlaceholderLine";

import landing from "app.images/landing.svg";

const PortfolioNewPlaceholder = () => (
  <div className="container mx-auto max-w-screen-lg py-8">
    <img className="mx-auto mt-6" src={landing} alt="" />
    <div className="my-4 flex justify-center">
      <PlaceholderLine width="w-96" />
    </div>
    <div className="mt-4 flex h-52">
      <div className="mr-4 flex-1 rounded-sm bg-white p-4 shadow-sm">
        <PlaceholderLine width="w-1/2" />
      </div>
      <div className="mr-4 flex-1 rounded-sm bg-white p-4 shadow-sm">
        <PlaceholderLine width="w-1/2" />
      </div>
    </div>
  </div>
);

PortfolioNewPlaceholder.displayName = "PortfolioNewPlaceholder";

export default PortfolioNewPlaceholder;
