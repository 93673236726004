import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { dismissModal } from "app.reducers/ui";
import { bulkMapTokenAccounts } from "app.reducers/accountingIntegrations";
import { useTranslation } from "react-i18next";
import Button from "app.components/Util/Button";

import BaseTable from "app.components/TableList/BaseTable";

import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";

import ChainLogo from "app.components/Images/ChainLogo";
import CardIndicator from "app.components/Util/CardIndicator";

import { APP_NAME, STATUS } from "app.constants";

const SelectedColumn = ({ isSelected }) => {
  return (
    <span className="flex justify-end">
      <CardIndicator disabled={false} type="square" active={isSelected} />
    </span>
  );
};

const SmartGroupColumn = ({ smartWallet }) => {
  return (
    <div className="flex items-center gap-2">
      <ChainLogo coin={smartWallet.currencyType} small />
      <div className="max-w-[200px]">
        <p
          title={smartWallet.tokenName || smartWallet.currencyType}
          className="truncate p-0"
        >
          {smartWallet.tokenName || smartWallet.currencyType}
        </p>
        <p
          title={smartWallet.currencyType}
          className="truncate p-0 text-xs whitespace-nowrap text-black/75"
        >
          {smartWallet.currencyType}
        </p>
      </div>
    </div>
  );
};

function ModalSuggestedMappings({
  smartWalletList,
  suggestedMappings,
  connectionId,
}) {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();
  const { bulkMappingStatus } = useSelector(
    (state) => state.accountingIntegrations
  );

  const [selectedMappings, setSelectedMappings] = useState(
    suggestedMappings.map((m) => m.matchingAccount.id)
  );
  const allMappingsSelected =
    suggestedMappings.length === selectedMappings.length;

  const handleDismiss = (e) => {
    e.preventDefault();
    reduxDispatch(dismissModal());
  };

  const selectAllItems = () => {
    if (allMappingsSelected) {
      setSelectedMappings([]);
    } else {
      setSelectedMappings(suggestedMappings.map((m) => m.matchingAccount.id));
    }
  };

  const selectItem = (id, isSelected) => {
    setSelectedMappings(
      isSelected
        ? selectedMappings.filter((mapping) => mapping !== id)
        : [...selectedMappings, id]
    );
  };

  const bulkMapAccounts = () => {
    const idsAsInts = selectedMappings.map((i) => parseInt(i, 10));
    const mappings = suggestedMappings.filter((s) =>
      idsAsInts.includes(parseInt(s.matchingAccount.id, 10))
    );

    reduxDispatch(bulkMapTokenAccounts(connectionId, mappings));
  };

  const columns = [
    {
      label: t("common.token"),
      left: true,
      column: "smartWallet",
      sticky: true,
      sortable: true,
      searchable: true,
      customSearch: (smartWallet, searchString) =>
        smartWallet.name.toLowerCase().includes(searchString),
      customRender: (smartWallet) => SmartGroupColumn({ smartWallet }),
    },
    {
      label: <span className="sr-only">{t("common.selected")}</span>,
      column: "isSelected",
      sticky: true,
      customClass: "w-16",
      customRender: (isSelected) => SelectedColumn({ isSelected }),
    },
  ];

  const data = suggestedMappings.map((mapping) => {
    const smartWallet = smartWalletList.find(
      (sw) => sw.smartWalletGUID === mapping.smartWalletGUID
    );

    return {
      smartWallet,
      id: mapping.matchingAccount.id,
      isSelected: selectedMappings.includes(mapping.matchingAccount.id),
    };
  });

  return (
    <>
      <ModalHeader
        title={t("accountingIntegrations.suggestedMappings")}
        closeCallback={handleDismiss}
      />
      <div className="m-4">
        <p className="mb-2">
          {t("accountingIntegrations.suggestedMappingsDescription", {
            appName: APP_NAME,
          })}
        </p>
        <BaseTable
          tableCustomClass="min-h-[300px] max-h-[300px] overflow-y-scroll"
          columns={columns}
          data={data}
          sortable
          handleColumnClick={(e, x) => {
            e.preventDefault();
            selectItem(x.id, x.isSelected);
          }}
          recordsShowing
        />
        <div className="flex items-center justify-end border-t px-4 py-2">
          <Button buttonSize="sm" buttonType="text" onClick={selectAllItems}>
            <div className="justify- flex items-center">
              <p className="mr-2">
                {t("accountingIntegrations.selectAllAccounts", {
                  count: suggestedMappings.length,
                })}
              </p>
              <CardIndicator
                disabled={false}
                type="square"
                active={allMappingsSelected}
              />
            </div>
          </Button>
        </div>
      </div>
      <ModalControls>
        <Button
          type="submit"
          data-tabbable
          buttonType="primary"
          customClasses="ml-4"
          onClick={(e) => {
            e.preventDefault();
            bulkMapAccounts();
          }}
          disabled={
            !suggestedMappings.length || bulkMappingStatus === STATUS.WORKING
          }
          text={t("accountingIntegrations.mapSelectedAccounts")}
        />
        <Button
          data-testid="close-modal"
          onClick={handleDismiss}
          buttonType="text"
          text={t("common.close")}
        />
      </ModalControls>
    </>
  );
}

ModalSuggestedMappings.displayName = "ModalSuggestedMappings";

export default ModalSuggestedMappings;
