import { takeLatest } from "redux-saga/effects";

import {
  receiveRenameLedger,
  receiveLedgers,
  receiveSupportedExchanges,
  receiveDeleteExchangeLedger,
  receiveBulkDeleteExchangeLedgers,
  receiveBulkUpdateLedgersBySource,
  receiveUpdateExchangeAllLedgers,
  receiveRebuildExchangeLedgers,
} from "app.reducers/exchanges";

import {
  EXCHANGE_LEDGER_IMPORT_RECEIVE,
  FAVORITE_LEDGER_RECEIVE,
} from "app.actions/exchangeLedger";

import { receiveSmartWalletList } from "app.reducers/smartWallet";

import runLedgerComputations from "./runLedgerComputations";
import runComputeChartData from "./runComputeChartData";

const recomputeLedgers = [
  receiveRenameLedger,
  receiveLedgers,
  receiveSupportedExchanges,
  receiveDeleteExchangeLedger,
  receiveBulkDeleteExchangeLedgers,
  receiveBulkUpdateLedgersBySource,
  receiveUpdateExchangeAllLedgers,
  receiveRebuildExchangeLedgers,
  EXCHANGE_LEDGER_IMPORT_RECEIVE,
  FAVORITE_LEDGER_RECEIVE,
];

const computeChartData = [receiveSmartWalletList];

// WATCHERS
const computableSagas = [
  takeLatest([...recomputeLedgers], runLedgerComputations),
  takeLatest([...computeChartData], runComputeChartData),
];

export default computableSagas;
