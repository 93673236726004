import { call, put } from "redux-saga/effects";
import * as accountingIntegrationsActions from "app.reducers/accountingIntegrations";
import ExchangesAPI from "app.api/ExchangesAPI";
import { getLoadingState } from "app.utils/selectors";
import { STATUS } from "app.constants";

function* fetchAccountingIntegrationsConnections() {
  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.accountingIntegrations.connectionsStatus
  );

  if (isLoading) return;

  yield put(
    accountingIntegrationsActions.requestAccountingIntegrationsConnections(
      nextStatus
    )
  );

  const response = yield call(ExchangesAPI.getConnections);

  if (response.error) {
    console.error(
      "Error in fetchAccountingIntegrationsConnections",
      response.body,
      response.error
    );
    yield put(
      accountingIntegrationsActions.accountingIntegrationsConnectionsError(
        response.body,
        STATUS.ERROR
      )
    );
  } else {
    const linkedIntegrations = response.body.flatMap((item) =>
      item.connections.length > 0
        ? item.connections.map((conn) => ({
            ...item,
            connectionId: conn.id, // Rename `id` from connections to `connectionId`
            ...conn,
          }))
        : []
    );

    yield put(
      accountingIntegrationsActions.receiveAccountingIntegrationsConnections(
        response.body,
        linkedIntegrations
      )
    );
  }
}

export default fetchAccountingIntegrationsConnections;
