import { takeEvery } from "redux-saga/effects";
import * as tagsActions from "app.reducers/tags";

import fetchTagMetadata from "./fetchTagMetadata";
import fetchTagsAndRules from "./fetchTagsAndRules";
import bulkCreateTag from "./bulkCreateTag";
import deleteTag from "./deleteTag";
import editTag from "./editTag";

// WATCHERS
const tagSagas = [
  takeEvery(tagsActions.fetchTagMetadata, fetchTagMetadata),
  takeEvery(tagsActions.fetchTagsAndRules, fetchTagsAndRules),
  takeEvery(tagsActions.bulkCreateTag, bulkCreateTag),
  takeEvery(tagsActions.deleteTag, deleteTag),
  takeEvery(tagsActions.editTag, editTag),
];

// export all watcher sagas as an array to be composed in the top level
// root saga
export default tagSagas;
