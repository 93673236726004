import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { dismissModal } from "app.reducers/ui";
import { renameLedger } from "app.reducers/exchanges";
import { LEDGER_NAME_MAX_LENGTH } from "app.constants";
import { ledgerNameExists } from "app.utils";
import Button from "app.components/Util/Button";
import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";
import Input from "app.components/Util/Form/Input";
import Label from "app.components/Util/Form/Label";
import ErrorMessage from "app.components/Util/ErrorMessage";

const ModalRenameWallet = ({ wallet, canDismissCallback }) => {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();
  const [name, setName] = useState(wallet.name || wallet.userLedgerName);

  const { ledgers } = useSelector((state) => state.exchanges);
  const { renamingWallet, renameWalletError } = useSelector(
    (state) => state.portfolios
  );

  const disableButtons = useMemo(() => {
    if (renamingWallet) return true;
    return false;
  }, [renamingWallet]);

  useEffect(() => {
    canDismissCallback(!disableButtons);
  }, [canDismissCallback, disableButtons]);

  const handleUpdate = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const doesLedgerNameExist = useMemo(() => {
    const currentWalletName = wallet.userLedgerName;
    if (name === currentWalletName) return false; // if the ledger name is the same as the source ledger name, that is OK
    return ledgerNameExists(name, ledgers);
  }, [ledgers, name, wallet.userLedgerName]);

  const submit = (e) => {
    e.preventDefault();
    if (doesLedgerNameExist) return;
    const currentWalletName = wallet.userLedgerName;
    if (name === currentWalletName) {
      reduxDispatch(dismissModal());
      return;
    }
    reduxDispatch(renameLedger(name, wallet.id));
  };

  return (
    <>
      <ModalHeader
        title={t("modals.renameLedger")}
        closeCallback={(e) => {
          e.preventDefault();
          reduxDispatch(dismissModal());
        }}
      />
      <form onSubmit={submit}>
        <div className="m-4">
          <ErrorMessage {...renameWalletError} />
          <p className="mb-4 text-lg">{t("modals.enterNewNameForLedger")}</p>

          <div className="mb-4 flex flex-col">
            <Label htmlFor="name">
              {t("input.label.ledgerName")}
              {doesLedgerNameExist && (
                <span className="ml-2 text-xs font-normal text-red-700">
                  {t("modals.ledgerWithThisNameAlreadyExists")}
                </span>
              )}
            </Label>
            <Input
              maxLength={LEDGER_NAME_MAX_LENGTH}
              hasError={doesLedgerNameExist}
              disabled={disableButtons}
              placeholder={t("input.placeholder.ledgerName")}
              data-tabbable
              type="text"
              data-testid="name"
              id="name"
              name="name"
              value={name}
              onChange={handleUpdate}
            />
          </div>
        </div>
        <ModalControls>
          <Button
            type="submit"
            disabled={disableButtons || doesLedgerNameExist}
            data-tabbable
            buttonType="primary"
            customClasses="ml-4"
            working={renamingWallet}
            text={t("button.save")}
            workingText={t("button.saving")}
          />
          <Button
            disabled={disableButtons}
            onClick={(e) => {
              e.preventDefault();
              reduxDispatch(dismissModal());
            }}
            data-tabbable
            buttonType="text"
            text={t("button.close")}
          />
        </ModalControls>
      </form>
    </>
  );
};

ModalRenameWallet.displayName = "ModalRenameWallet";

export default ModalRenameWallet;
