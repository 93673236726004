import { takeEvery, takeLeading } from "redux-saga/effects";
import { exchangesSlice } from "app.reducers/exchanges";

// sagas

import bulkDeleteExchangeLedgers from "./bulkDeleteExchangeLedgers";
import bulkDeleteExchangeLedgersByIds from "./bulkDeleteExchangeLedgersByIds";
import bulkUpdateLedgersBySource from "./bulkUpdateLedgersBySource";
import connectExchangeViaAPI from "./connectExchangeViaAPI";
import connectExchangeViaOAuth from "./connectExchangeViaOAuth";
import createExchangeLedgers from "./createExchangeLedgers";
import createWalletLedgers from "./createWalletLedgers";
import createExchangeLedgersViaUpload from "./createExchangeLedgersViaUpload";
import deleteExchangeLedger from "./deleteExchangeLedger";
import fetchApiKeyVaults from "./fetchApiKeyVaults";
import importApiKeyVaults from "./importApiKeyVaults";
import fetchExchangeAccounts from "./fetchExchangeAccounts";
import fetchLedgers from "./fetchLedgers";
import fetchLinkedExchanges from "./fetchLinkedExchanges";
import fetchSettings from "./fetchSettings";
import fetchSupportedExchanges from "./fetchSupportedExchanges";
import pollSettings from "./pollSettings";
import rebuildExchangeLedgers from "./rebuildExchangeLedgers";
import renameLedger from "./renameLedger";
import renameLinkedAccount from "./renameLinkedAccount";
import unlinkExchange from "./unlinkExchange";
import updateExchangeAllLedgers from "./updateExchangeAllLedgers";
import updateExchangeAPI from "./updateExchangeAPI";
import updateExchangeAPIToken from "./updateExchangeAPIToken";
import updateExchangeLedgersViaAddressGroupings from "./updateExchangeLedgersViaAddressGroupings";
import updateExchangeLedgersViaUpload from "./updateExchangeLedgersViaUpload";
import verifyExchangeLink from "./verifyExchangeLink";
import updateRollforwardViaUpload from "./updateRollforwardViaUpload";
import migrateSources from "./migrateSources";
import migrateViaUpload from "./migrateViaUpload";

// watchers
const exchangeSagas = [
  takeLeading(exchangesSlice.actions.fetchSettings, fetchSettings),
  takeEvery(
    exchangesSlice.actions.bulkDeleteExchangeLedgers,
    bulkDeleteExchangeLedgers
  ),
  takeEvery(
    exchangesSlice.actions.bulkDeleteExchangeLedgersByIds,
    bulkDeleteExchangeLedgersByIds
  ),
  takeEvery(
    exchangesSlice.actions.bulkUpdateLedgersBySource,
    bulkUpdateLedgersBySource
  ),
  takeLeading(
    exchangesSlice.actions.fetchSupportedExchanges,
    fetchSupportedExchanges
  ),
  takeLeading(
    exchangesSlice.actions.fetchLinkedExchanges,
    fetchLinkedExchanges
  ),
  takeEvery(
    exchangesSlice.actions.connectExchangeViaApiKey,
    connectExchangeViaAPI
  ),
  takeEvery(
    exchangesSlice.actions.connectExchangeOAuth,
    connectExchangeViaOAuth
  ),
  takeEvery(
    exchangesSlice.actions.createExchangeLedgers,
    createExchangeLedgers
  ),
  takeEvery(
    exchangesSlice.actions.createExchangeLedgersViaUpload,
    createExchangeLedgersViaUpload
  ),
  takeEvery(exchangesSlice.actions.createWalletLedgers, createWalletLedgers),
  takeEvery(exchangesSlice.actions.deleteExchangeLedger, deleteExchangeLedger),
  takeEvery(
    exchangesSlice.actions.fetchExchangeAccounts,
    fetchExchangeAccounts
  ),
  takeEvery(
    exchangesSlice.actions.updateExchangeLedgersViaAddressGroupings,
    updateExchangeLedgersViaAddressGroupings
  ),
  takeEvery(
    exchangesSlice.actions.updateExchangeLedgersViaUpload,
    updateExchangeLedgersViaUpload
  ),
  takeEvery(exchangesSlice.actions.migrateViaUpload, migrateViaUpload),
  takeLeading(exchangesSlice.actions.fetchLedgers, fetchLedgers),
  takeEvery(exchangesSlice.actions.renameLedger, renameLedger),
  takeEvery(exchangesSlice.actions.renameLinkedAccount, renameLinkedAccount),
  takeEvery(exchangesSlice.actions.unlinkExchange, unlinkExchange),
  takeEvery(exchangesSlice.actions.pollSettings, pollSettings),
  takeEvery(exchangesSlice.actions.updateExchangeApiKey, updateExchangeAPI),
  takeEvery(
    exchangesSlice.actions.updateExchangeApiToken,
    updateExchangeAPIToken
  ),
  takeEvery(
    exchangesSlice.actions.updateExchangeAllLedgers,
    updateExchangeAllLedgers
  ),
  takeEvery(exchangesSlice.actions.verifyExchangeLink, verifyExchangeLink),
  takeEvery(
    exchangesSlice.actions.rebuildExchangeLedgers,
    rebuildExchangeLedgers
  ),
  takeEvery(
    exchangesSlice.actions.updateRollforwardOpenLotsViaUpload,
    updateRollforwardViaUpload
  ),
  takeEvery(exchangesSlice.actions.fetchApiKeyVaults, fetchApiKeyVaults),
  takeEvery(exchangesSlice.actions.importApiKeyVaults, importApiKeyVaults),
  takeEvery(exchangesSlice.actions.migrateSourcesViaUpload, migrateSources),
];

export default exchangeSagas;
