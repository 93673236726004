import { call, put, all } from "redux-saga/effects";
import {
  requestDeleteTagGroup,
  receiveDeleteTagGroup,
  deleteTagGroupError,
  fetchTagGroups,
} from "app.reducers/accountingIntegrations";

import {
  dismissModal,
  startModalWorking,
  endModalWorking,
} from "app.reducers/ui";

import ExchangesAPI from "app.api/ExchangesAPI";
import { getLoadingState } from "app.utils/selectors";

function* deleteTagGroup(action) {
  const { tagGroupId } = action.payload;

  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.tags.deleteTagGroupStatus
  );

  if (isLoading) return;

  yield put(requestDeleteTagGroup(nextStatus));
  yield put(startModalWorking());

  const response = yield call(ExchangesAPI.deleteTagGroup, tagGroupId);

  if (response.error) {
    console.error("Error in deleteTagGroup", response.body, response.error);
    yield put(deleteTagGroupError(response.body));
  } else {
    yield all([
      put(receiveDeleteTagGroup()),
      put(fetchTagGroups()),
      put(dismissModal()),
    ]);
  }
  yield put(endModalWorking());
}

export default deleteTagGroup;
