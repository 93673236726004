import React from "react";
import { useDispatch } from "react-redux";

import { dismissModal, setModalActionCallback } from "app.reducers/ui";
import { useTranslation, Trans } from "react-i18next";
import { lookupTranslation } from "app.utils";
import {
  FILE_UPLOAD,
  FILE_UPLOAD_MIGRATION,
  API_KEY,
  API_OAUTH,
} from "app.constants";
import SvgLoader from "app.components/Util/SvgLoader";
import Button from "app.components/Util/Button";
import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";

const INTEGRATION_TYPE_ICON = {
  [API_KEY]: (
    <SvgLoader
      id="Key"
      className="h-7 w-7 stroke-blue-600 group-hover:stroke-blue-800"
    />
  ),
  [API_OAUTH]: (
    <SvgLoader
      id="Lock"
      className="h-7 w-7 stroke-blue-600 group-hover:stroke-blue-800"
    />
  ),
};

function ModalExchangeConnectionSelect({
  exchanges,
  linkCallback,
  selectExchangeCallback,
}) {
  const { t } = useTranslation();

  const reduxDispatch = useDispatch();
  const handleDismiss = (e) => {
    e.preventDefault();
    reduxDispatch(dismissModal());
  };

  const { label } = exchanges[0];

  return (
    <>
      <ModalHeader
        title={t("modals.connectWithLabel", { label })}
        closeCallback={handleDismiss}
      />

      <div className="m-4">
        <p className="mt-4 text-lg">
          {t("modals.chooseHowToConnectWith", { label })}
        </p>
        <div className="flex flex-wrap">
          {exchanges.map((exchange) => {
            if (
              exchange.integrationType === FILE_UPLOAD ||
              exchange.integrationType === FILE_UPLOAD_MIGRATION
            ) {
              return (
                <React.Fragment key={exchange.id}>
                  <p className="basis-full text-sm">
                    <Trans i18nKey="modals.youCanUploadYourTransactionHistory">
                      <a
                        href="#upload"
                        className="text-link-blue text-link-blue-active hover:underline"
                        onClick={(e) => {
                          e.preventDefault();
                          reduxDispatch(
                            setModalActionCallback({
                              type: selectExchangeCallback,
                              exchange,
                            })
                          );
                          reduxDispatch(dismissModal());
                        }}
                      >
                        {{ label }}
                      </a>
                    </Trans>
                  </p>
                </React.Fragment>
              );
            }

            const icon = INTEGRATION_TYPE_ICON[exchange.integrationType];
            return (
              <div className="my-4 basis-1/2 pl-4 first:pl-0" key={exchange.id}>
                <button
                  type="button"
                  className="group flex w-full items-center justify-center rounded-sm border border-blue-200 p-4 text-blue-600 transition-all hover:border-blue-400 hover:bg-blue-50 hover:text-blue-800"
                  onClick={() => {
                    reduxDispatch(
                      setModalActionCallback({ type: linkCallback, exchange })
                    );
                  }}
                >
                  {icon}
                  <span className="ml-2">
                    {lookupTranslation(
                      exchange.integrationType,
                      "integrationType"
                    )}
                  </span>
                </button>
              </div>
            );
          })}
        </div>
      </div>
      <ModalControls>
        <Button
          onClick={handleDismiss}
          buttonType="text"
          text={t("common.close")}
        />
      </ModalControls>
    </>
  );
}

ModalExchangeConnectionSelect.displayName = "ModalExchangeConnectionSelect";

export default ModalExchangeConnectionSelect;
