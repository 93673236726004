import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import MessageBanner from "app.components/Util/MessageBanner";
import { Label, TextArea } from "app.components/Util/Form";

import Big from "big.js";
import { APP_NAME, DASH_CORE_SUPPORT_URL } from "app.constants";

const AddressGroupings = ({
  selectedCurrency,
  addressGroupings,
  updateAddressGroupingCallback,
}) => {
  const { t } = useTranslation();

  const [addressData, setAddressData] = useState("");
  const [addressCount, setAddressCount] = useState(0);
  const [balance, setBalance] = useState(0);

  const updateAddresses = (rawData) => {
    setAddressData(rawData);

    if (rawData.trim() === "") {
      updateAddressGroupingCallback([]);
      return;
    }

    try {
      // Step 1: Extract the JSON-like structure
      const structureRegex = /\[\s*(\[[\s\S]*\])\s*\]/;
      const data = rawData.match(structureRegex);

      if (data && data.length > 0) {
        // Step 2: Parse the data as JSON
        const jsonString = data[0];
        const parsedData = JSON.parse(jsonString);
        // Step 2a: Sum up the balances (build user confidence)
        setBalance(
          parsedData[0].reduce((sum, addr) => {
            return sum.plus(addr[1] || 0);
          }, new Big(0))
        );
        // Step 2b: Store the addresses
        const addresses = parsedData[0].map((addr) => addr[0]);
        setAddressCount(addresses.length);
        updateAddressGroupingCallback(addresses);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const textAreaHelpMessage =
    addressGroupings.length === 0 && addressData.trim() !== "" ? (
      <p className="text-xs text-red-700">
        {t("addLedger.address.noAddressesFound")}
      </p>
    ) : (
      <p className="text-xs">
        {addressGroupings.length === 0 ? (
          <Trans i18nKey="addLedger.address.howToGetYourDashCoreWalletAddress">
            <strong />
            <a
              href={DASH_CORE_SUPPORT_URL}
              rel="noopener noreferrer"
              target="_blank"
              className="text-link-blue hover:text-link-blue-active hover:underline"
            >
              text
            </a>
          </Trans>
        ) : (
          <Trans
            i18nKey="addLedger.address.addressWithBalance"
            count={addressCount}
          >
            <strong />
            <strong>
              {{ balance: balance.toString() }}
              {{ selectedCurrency }}
            </strong>
          </Trans>
        )}
      </p>
    );

  return (
    <div className="mt-2">
      <MessageBanner
        customClass="mb-2"
        type="info"
        text={t("addLedger.address.appUsesYourAddressGroupingsFor", {
          appName: APP_NAME,
        })}
      />
      <div className="mt-2">
        <Label htmlFor="addressData">
          {t("addLedger.address.pasteAddressGroupingsBelow")}
        </Label>
        <TextArea
          id="addressData"
          name="addressData"
          data-tabbable
          value={addressData}
          className="w-full"
          onChange={(e) => updateAddresses(e.target.value)}
          clearButton={addressData !== ""}
          onClear={() => updateAddresses("")}
          helpMessage={textAreaHelpMessage}
        />
      </div>
    </div>
  );
};

AddressGroupings.displayName = "AddressGroupings";

export default AddressGroupings;
