import { call, put, all } from "redux-saga/effects";
import { getLoadingState } from "app.utils/selectors";
import {
  requestUnlinkExchange,
  unlinkExchangeError,
  receiveUnlinkExchange,
  fetchLinkedExchanges,
} from "app.reducers/exchanges";
import { dismissModal, endModalWorking } from "app.reducers/ui";
import ExchangesAPI from "app.api/ExchangesAPI";

import { STATUS } from "app.constants";

function* unlinkExchange(action) {
  const { exchangeId, linkGUID } = action.payload;
  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.exchanges.unlinkExchangeStatus
  );

  if (isLoading) return;

  yield put(requestUnlinkExchange(nextStatus));

  const response = yield call(
    ExchangesAPI.unlinkExchange,
    exchangeId,
    linkGUID
  );

  if (response.error) {
    yield all([
      put(unlinkExchangeError(response.body, response.error, STATUS.ERROR)),
      put(endModalWorking()),
    ]);
  } else {
    yield all([
      put(receiveUnlinkExchange(STATUS.LOADED)),
      put(fetchLinkedExchanges()),
      put(dismissModal()),
    ]);
  }
}

export default unlinkExchange;
