import { call, put } from "redux-saga/effects";
import {
  requestRemoveTagFromGroup,
  receiveRemoveTagFromGroup,
  removeTagFromGroupError,
  fetchTagGroups,
} from "app.reducers/accountingIntegrations";

import ExchangesAPI from "app.api/ExchangesAPI";
import { getLoadingState } from "app.utils/selectors";

function* removeTagFromGroup(action) {
  const { tag } = action.payload;

  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.tags.assignTagStatus
  );

  if (isLoading) return;

  yield put(requestRemoveTagFromGroup(nextStatus));

  const response = yield call(ExchangesAPI.assignTagToGroup, tag, null);

  if (response.error) {
    console.error("Error in removeTagFromGroup", response.body, response.error);
    yield put(removeTagFromGroupError(response.body.message));
  } else {
    yield put(receiveRemoveTagFromGroup());
  }
  yield put(fetchTagGroups());
}

export default removeTagFromGroup;
