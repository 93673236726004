import { lookupTranslation } from "app.utils";

export const STEPS = {
  PLANS: "PLANS",
  PAYMENT: "PAYMENT",
};

export const DEFAULT_PLAN_ID = "default";
export const ESSENTIALS_PLAN_ID = "node40_essentials";
export const PROFESSIONAL_PLAN_ID = "node40_professionals";
export const PROYEARLY_PLAN_ID = "balance_professionals_yearly_5000";

export const ESSENTIALS_MONTHLY_PLAN_ID = "node40_essentials_monthly";
export const ESSENTIALS_YEARLY_PLAN_ID = "node40_essentials_yearly";

function getDiscountedPrice(basePrice, discountPercent) {
  return basePrice - (basePrice / 100) * discountPercent;
}

export const getPlanDetails = (id) => {
  const PATH = `plans.${id}`;

  const PLAN_DETAILS = {
    [DEFAULT_PLAN_ID]: {
      limits: {
        transactions: 1500,
        wallets: 1,
        exchanges: 1,
      },
    },
    [ESSENTIALS_YEARLY_PLAN_ID]: {
      limits: {
        transactions: Infinity,
        wallets: Infinity,
        exchanges: Infinity,
      },
    },
    [ESSENTIALS_MONTHLY_PLAN_ID]: {
      limits: {
        transactions: Infinity,
        wallets: Infinity,
        exchanges: Infinity,
      },
    },
    [PROYEARLY_PLAN_ID]: {
      limits: {
        transactions: Infinity,
        wallets: Infinity,
        exchanges: Infinity,
      },
    },
    [PROFESSIONAL_PLAN_ID]: {
      limits: {
        transactions: Infinity,
        wallets: Infinity,
        exchanges: Infinity,
      },
      seatBased: true,
      volumeDiscountAvailable: true,
      pricingTable: [
        {
          seats: 1,
          percentDiscount: null,
          perSeat: (seatPrice) => seatPrice,
          displayTotal: (seatPrice) => seatPrice,
        },
        {
          seats: 3,
          percentDiscount: 5.0,
          perSeat: (seatPrice) =>
            Math.round(getDiscountedPrice(seatPrice, 5.0)),
          displayTotal: (seatPrice) =>
            Math.round(getDiscountedPrice(seatPrice, 5.0) * 3),
        },
        {
          seats: 5,
          percentDiscount: 10.0,
          perSeat: (seatPrice) =>
            Math.round(getDiscountedPrice(seatPrice, 10.0)),
          displayTotal: (seatPrice) =>
            Math.round(getDiscountedPrice(seatPrice, 10.0) * 5),
        },
        {
          seats: 10,
          percentDiscount: 15.0,
          perSeat: (seatPrice) =>
            Math.round(getDiscountedPrice(seatPrice, 15.0)),
          displayTotal: (seatPrice) =>
            Math.round(getDiscountedPrice(seatPrice, 15.0) * 10),
        },
        {
          seats: 25,
          percentDiscount: 20.0,
          perSeat: (seatPrice) =>
            Math.round(getDiscountedPrice(seatPrice, 20.0)),
          displayTotal: (seatPrice) =>
            Math.round(getDiscountedPrice(seatPrice, 20.0) * 25),
        },
      ],
    },
  };

  return {
    ...PLAN_DETAILS[id],
    title: lookupTranslation("title", PATH),
    fullTitle: lookupTranslation("fullTitle", PATH),
    description: lookupTranslation("description", PATH),
    longDescription: lookupTranslation("longDescription", PATH),
    summary: lookupTranslation("summary", PATH),
  };
};

export const SUBSCRIPTION_EMAIL = "sales@node40.com";

export const PLAN_LEVELS = {
  [DEFAULT_PLAN_ID]: 1,
  [ESSENTIALS_MONTHLY_PLAN_ID]: 2,
  [ESSENTIALS_YEARLY_PLAN_ID]: 3,
  [PROYEARLY_PLAN_ID]: 3,
  [PROFESSIONAL_PLAN_ID]: 4,
};

export const PLAN_INTERVALS = {
  YEAR: "year",
  MONTH: "month",
};

export const AVAILABLE_PLANS = [
  { id: DEFAULT_PLAN_ID, level: 1 },
  {
    id: PROYEARLY_PLAN_ID,
    level: 2,
  },
  { id: PROFESSIONAL_PLAN_ID, level: 3 },
];
