import React from "react";

import ChainLogo from "app.components/Images/ChainLogo";

const CurrencyColumn = ({ currency, row }) => {
  return (
    <div className="flex items-center gap-2">
      <ChainLogo coin={currency} small />
      <div className="max-w-[200px]">
        <p title={row.name} className="truncate p-0">
          {row.name}
        </p>
        <p
          title={currency}
          className="truncate p-0 text-xs whitespace-nowrap text-black/75"
        >
          {currency}
        </p>
      </div>
    </div>
  );
};

export default CurrencyColumn;
