import { store } from "app.config/configureStore";
import { resetUser } from "app.reducers/user";

import {
  BALANCE_SERVICE_ID,
  SSO_BASE,
  LOCAL_STORAGE_USER_TOKEN_KEY,
  LOCAL_STORAGE_SSO_TOKEN_KEY,
  LOCAL_STORAGE_ORG_TOKEN_KEY,
  SESSION_STORAGE_KEY_ORGS,
} from "app.constants";

let isLogoutInProgress = false;

function logout(redirectTo) {
  // prevent calling log out twice, not sure if this causes any actual issues,
  // but it makes debugging where logout was called easier.
  if (isLogoutInProgress) return;
  isLogoutInProgress = true;

  const params = new URLSearchParams({ service: BALANCE_SERVICE_ID });
  if (redirectTo) {
    params.set("redirect", redirectTo);
  }

  store.dispatch({ type: resetUser.type });

  localStorage.removeItem(LOCAL_STORAGE_USER_TOKEN_KEY);
  localStorage.removeItem(LOCAL_STORAGE_SSO_TOKEN_KEY);
  localStorage.removeItem(LOCAL_STORAGE_ORG_TOKEN_KEY);
  sessionStorage.removeItem(SESSION_STORAGE_KEY_ORGS);

  // Router will just use empty strings for
  const redirectUrl = `${SSO_BASE}/logout?${params.toString()}`;
  window.location = redirectUrl;
}

export default logout;
