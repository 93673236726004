import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { dismissModal } from "app.reducers/ui";

import { useTranslation } from "react-i18next";
import Button from "app.components/Util/Button";

import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";

import SvgLoader from "app.components/Util/SvgLoader";
import Input from "app.components/Util/Form/Input";
import Label from "app.components/Util/Form/Label";

import { APP_NAME } from "app.constants";

import { downloadAssetCsv } from "app.components/Settings/SettingsAccountingIntegrations/helpers";

function ModalImportAssetAccounts({ smartWalletList, connectionName }) {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();
  const [parentAccount, setParentAccount] = useState(
    t("accountingIntegrations.digitalAssets")
  );

  const handleDismiss = (e) => {
    e.preventDefault();
    reduxDispatch(dismissModal());
  };

  const hasInvalidChars = /[*?"<>|]/.test(parentAccount);

  return (
    <>
      <ModalHeader
        title={t("accountingIntegrations.importAccountsInto", {
          integrationName: connectionName,
        })}
        closeCallback={handleDismiss}
      />
      <div className="m-4">
        <p className="mb-4">
          {t("accountingIntegrations.downloadTemplateDescriptionPartOne", {
            connectionName,
            appName: APP_NAME,
          })}
        </p>
        <p className="mb-4">
          {t("accountingIntegrations.downloadTemplateDescriptionPartTwo", {
            connectionName,
          })}
        </p>
        <div className="flex flex-col">
          <Label
            optional
            description='cannot contain *, ?, ", &lt;, &gt;, |, or :'
            htmlFor="parentAccount"
          >
            {t("accountingIntegrations.parentAccount")}
          </Label>
          <Input
            id="parentAccount"
            name="parentAccount"
            maxLength="40"
            hasError={hasInvalidChars}
            className="max-w-84"
            onChange={(e) => {
              setParentAccount(e.target.value);
            }}
            value={parentAccount}
          />
        </div>
      </div>

      <ModalControls>
        <Button
          type="submit"
          data-tabbable
          buttonType="primary"
          customClasses="ml-4"
          disabled={hasInvalidChars}
          onClick={() => {
            downloadAssetCsv(smartWalletList, parentAccount, connectionName);
          }}
        >
          <SvgLoader id="CloudArrowDown" className="mr-1 h-4 w-4 fill-white" />
          {t("accountingIntegrations.downloadTemplate")}
        </Button>
        <Button
          data-testid="close-modal"
          onClick={handleDismiss}
          buttonType="text"
          text={t("common.close")}
        />
      </ModalControls>
    </>
  );
}

ModalImportAssetAccounts.displayName = "ModalImportAssetAccounts";

export default ModalImportAssetAccounts;
