/* eslint-disable no-param-reassign */
// this rule is disabled in reducers because the redux toolkit
// explicitly supports direct mutation of the state param
// https://redux-toolkit.js.org/usage/immer-reducers

// reducer for exchanges state
import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "app.constants";
import { updateLedgerSyncState } from "app.utils";
import {
  reinitializePortfolio,
  resetPortfolioData,
  resetAddLedger,
} from "app.reducers/portfolios";
import { dismissModal } from "app.reducers/ui";

const initialState = {
  supportedExchanges: undefined,
  supportedCurrencies: undefined,
  supportedCustodialWallets: undefined,
  supportedEvmWallets: undefined,
  supportedExchangesStatus: STATUS.UNINITIALIZED,
  loadingExchangeAccounts: false,
  linkedExchanges: undefined,
  linkedExchangesStatus: STATUS.UNINITIALIZED,
  selectedExchange: 0,
  exchangeAccounts: {},
  addingLedger: false,
  addLedgerError: undefined,
  ledgers: undefined,
  archivedLedgers: undefined,
  exchangeLedgerCurrentlyInProcess: [],
  ledgersStatus: STATUS.UNINITIALIZED,
  ledgersInitialLoad: false,
  ledgersError: undefined,
  deletingLedger: false,
  creatingViaUpload: false,
  migrateResponseId: undefined,
  migrateSources: undefined,
  creatingViaApi: false,
  creatingWallet: false,
  invalidKeys: false,
  invalidAddressMessage: undefined,
  invalidToken: false,
  contract: undefined,
  duplicate: undefined,
  unauthorized: undefined,
  createdLedgers: undefined,
  asyncCreationInProgress: undefined,
  asyncUploadExchangeInProgress: undefined,
  failedCurrencies: undefined,
  skippedCurrencies: undefined,
  uploading: false,
  updating: false,
  apiKeySavingStatus: STATUS.INITIAL,
  apiKeyError: undefined,
  apiTokenSavingStatus: STATUS.INITIAL,
  apiTokenError: undefined,
  settings: undefined,
  settingsStatus: STATUS.UNINITIALIZED,
  unlinkExchangeStatus: STATUS.UNINITIALIZED,
  userReadableErrors: undefined,
  flattenedLinkedExchanges: [],
  apiBasedExchanges: [],
  verifyingExchangeLink: {},
  renamingLinkedAccount: false,
  renamingLinkedAccountError: undefined,
  bulkUpdateStatus: STATUS.UNINITIALIZED,
  rebuildingLedgers: [],
  rollforwardCreatingViaUpload: false,
  rollforwardCreatingViaUploadError: undefined,
  rollforwardCreated: false,
  apiKeyVault: {},
};

export const exchangesSlice = createSlice({
  name: "exchanges",
  initialState,
  reducers: {
    // Ledgers
    fetchLedgers: () => {},
    requestLedgers: {
      reducer: (state, action) => {
        state.ledgersStatus = action.payload.nextStatus;
      },
      prepare: (nextStatus) => ({
        payload: { nextStatus },
      }),
    },

    receiveLedgers: {
      reducer: (state, action) => {
        state.ledgersInitialLoad = true;
        state.ledgersStatus = action.payload.nextStatus;
        state.ledgers = action.payload.response;
        state.archivedLedgers = action.payload.archivedLedgers;
        state.exchangeLedgerCurrentlyInProcess = action.payload.response
          .filter((value) => value.creationInProgress || value.importInProgress)
          .map((ledger) => ledger.exchangeId);
      },
      prepare: (response, archivedLedgers, nextStatus) => ({
        payload: { response, archivedLedgers, nextStatus },
      }),
    },

    ledgersError: {
      reducer: (state, action) => {
        state.ledgersInitialLoad = true;
        state.ledgersStatus = STATUS.ERROR;
        state.ledgersError = action.payload.response;
      },
      prepare: (response, error) => ({
        payload: { response, error },
      }),
    },

    // Supported Exchanges
    fetchSupportedExchanges: () => {},
    requestSupportedExchanges: {
      reducer: (state, action) => {
        state.supportedExchangesStatus = action.payload.nextStatus;
      },
      prepare: (nextStatus) => ({
        payload: { nextStatus },
      }),
    },

    receiveSupportedExchanges: {
      reducer: (state, action) => {
        state.supportedExchanges = action.payload.sortedExchanges;
        state.supportedCurrencies = action.payload.supportedCurrencies;
        state.supportedCustodialWallets = action.payload.custodialWallets;
        state.supportedEvmWallets = action.payload.evmWallets;
        state.apiBasedExchanges = action.payload.apiBasedExchanges;
        state.supportedExchangesStatus = action.payload.nextStatus;
      },
      prepare: (
        sortedExchanges,
        supportedCurrencies,
        custodialWallets,
        evmWallets,
        apiBasedExchanges,
        nextStatus
      ) => ({
        payload: {
          sortedExchanges,
          supportedCurrencies,
          custodialWallets,
          evmWallets,
          apiBasedExchanges,
          nextStatus,
        },
      }),
    },

    receiveSupportedExchangesError: {
      reducer: (state) => {
        state.supportedExchangesStatus = STATUS.ERROR;
      },
      prepare: (response) => ({
        payload: { response },
      }),
    },

    // Linked exchanges
    linkedExchangesRequest: {
      reducer: (state, action) => {
        state.linkedExchangesStatus = action.payload.nextStatus;
      },
      prepare: (nextStatus) => ({
        payload: { nextStatus },
      }),
    },

    linkedExchangesReceive: {
      reducer: (state, action) => {
        state.linkedExchanges = action.payload.response.linked;
        state.flattenedLinkedExchanges =
          action.payload.flattenedLinkedExchanges;
        state.linkedExchangesStatus = action.payload.nextStatus;
      },
      prepare: (response, flattenedLinkedExchanges, nextStatus) => ({
        payload: { response, flattenedLinkedExchanges, nextStatus },
      }),
    },
    // Bulk Delete Exchange Ledgers
    bulkDeleteExchangeLedgers: {
      reducer: () => {},
      prepare: (exchangeId, linkGUID) => ({
        payload: { exchangeId, linkGUID },
      }),
    },

    requestbulkDeleteExchangeLedgers: {
      reducer: (state) => {
        // Update any loading/status states for bulk delete
        state.deletingLedger = true;
      },
      prepare: (nextStatus) => ({
        payload: { nextStatus },
      }),
    },

    receiveBulkDeleteExchangeLedgers: {
      reducer: (state, action) => {
        // Filter out the deleted ledgers from state
        state.deletingLedger = false;
        state.ledgers = state.ledgers.filter(
          (ledger) => !action.payload.ledgerIds.includes(ledger.id)
        );
      },
      prepare: (nextStatus, ledgerIds) => ({
        payload: { nextStatus, ledgerIds },
      }),
    },

    bulkDeleteExchangeLedgersError: {
      reducer: (state) => {
        state.deletingLedger = false;
        // Could add error handling state here if needed
      },
      prepare: (nextStatus) => ({
        payload: { nextStatus },
      }),
    },

    // Bulk Delete Exchange Ledgers By Ids
    bulkDeleteExchangeLedgersByIds: {
      reducer: () => {},
      prepare: (ledgerIds) => ({
        payload: { ledgerIds },
      }),
    },

    requestBulkDeleteExchangeLedgersByIds: {
      reducer: (state) => {
        state.deletingLedger = true;
      },
      prepare: (nextStatus) => ({
        payload: { nextStatus },
      }),
    },

    receiveBulkDeleteExchangeLedgersByIds: {
      reducer: (state, action) => {
        state.deletingLedger = false;
        state.ledgers = state.ledgers.filter(
          (ledger) => !action.payload.ledgerIds.includes(ledger.id)
        );
      },
      prepare: (nextStatus, ledgerIds) => ({
        payload: { nextStatus, ledgerIds },
      }),
    },

    bulkDeleteExchangeLedgersByIdsError: {
      reducer: (state) => {
        state.deletingLedger = false;
      },
      prepare: (nextStatus) => ({
        payload: { nextStatus },
      }),
    },
    // Bulk Update Ledgers By Source
    bulkUpdateLedgersBySource: {
      reducer: () => {},
      prepare: (sources) => ({
        payload: { sources },
      }),
    },

    requestBulkUpdateLedgersBySource: {
      reducer: (state) => {
        state.bulkUpdateStatus = STATUS.LOADING;
      },
    },

    receiveBulkUpdateLedgersBySource: {
      reducer: (state, action) => {
        state.bulkUpdateStatus = STATUS.SUCCESS;
        // Update ledger sync state if needed

        if (action.payload.syncList) {
          state.ledgers = updateLedgerSyncState(
            action.payload.syncList,
            state.ledgers
          );
        }
      },
      prepare: (syncList) => ({
        payload: { syncList },
      }),
    },

    // Connect Exchange API
    connectExchangeViaApiKey: {
      reducer: () => {},
      prepare: (
        exchange,
        apiKey,
        secretKey,
        passphrase,
        nickname,
        autoImport
      ) => ({
        payload: {
          exchange,
          apiKey,
          secretKey,
          passphrase,
          nickname,
          autoImport,
        },
      }),
    },

    requestConnectExchangeViaApiKey: {
      reducer: (state) => {
        state.apiKeySavingStatus = STATUS.WORKING;
        state.apiKeyError = undefined;
      },
      prepare: () => ({
        payload: {},
      }),
    },

    receiveConnectExchangeViaApiKey: {
      reducer: (state) => {
        state.apiKeySavingStatus = STATUS.SUCCESS;
      },
      prepare: (response) => ({
        payload: { response },
      }),
    },

    receiveConnectExchangeViaApiKeyError: {
      reducer: (state, action) => {
        state.apiKeySavingStatus = STATUS.ERROR;
        state.apiKeyError = {
          message: action.payload.response.message,
          type: "apiKeyInvalid",
        };
      },
      prepare: (response) => ({
        payload: { response },
      }),
    },

    // Connect Exchange OAuth
    connectExchangeOAuth: {
      reducer: () => {},
      prepare: (exchange, slug) => ({
        payload: { exchange, slug },
      }),
    },

    requestConnectExchangeOAuth: () => {},

    receiveConnectExchangeOAuthError: {
      reducer: (state, action) => {
        state.apiKeySavingStatus = STATUS.ERROR;
        state.apiKeyError = action.payload.response;
      },
      prepare: (response) => ({
        payload: { response },
      }),
    },

    // Create Exchange Ledgers
    createExchangeLedgers: {
      reducer: () => {},
      prepare: (
        exchangeId,
        linkGUID,
        accountIds,
        accountingStrategy,
        oauthRequiredCallback,
        apiLinkSuccessCallback,
        apiDismissCallback
      ) => ({
        payload: {
          exchangeId,
          linkGUID,
          accountIds,
          accountingStrategy,
          oauthRequiredCallback,
          apiLinkSuccessCallback,
          apiDismissCallback,
        },
      }),
    },

    requestCreateExchangeLedgers: {
      reducer: (state) => {
        state.creatingViaApi = true;
      },
      prepare: () => ({
        payload: {},
      }),
    },

    receiveCreateExchangeLedgers: {
      reducer: (state, action) => {
        state.creatingViaApi = false;
        state.createdLedgers = action.payload.response.ledgersCreated;
        state.asyncCreationInProgress =
          action.payload.response.asyncCreationInProgress;
      },
      prepare: (response, exchangeId) => ({
        payload: { response, exchangeId },
      }),
    },

    createExchangeLedgersError: {
      reducer: (state) => {
        state.creatingViaApi = false;
        state.createdLedgers = undefined;
        state.asyncCreationInProgress = false;
      },
      prepare: (response, error) => ({
        payload: { response, error },
      }),
    },
    // Create Exchange Ledgers Via Upload
    createExchangeLedgersViaUpload: {
      reducer: () => {},
      prepare: (files, exchangeId, accountingStrategy) => ({
        payload: { files, exchangeId, accountingStrategy },
      }),
    },

    requestCreateExchangeLedgersViaUpload: {
      reducer: (state) => {
        state.creatingViaUpload = true;
        state.asyncUploadExchangeInProgress = undefined;
        state.uploadError = undefined;
      },
      prepare: () => ({
        payload: {},
      }),
    },

    receiveCreateExchangeLedgersViaUpload: {
      reducer: (state, action) => {
        state.creatingViaUpload = false;
        state.asyncUploadExchangeInProgress = action.payload.exchangeId;
        state.asyncCreationInProgress = true;
      },
      prepare: (exchangeId) => ({
        payload: { exchangeId },
      }),
    },

    createExchangeLedgersViaUploadError: {
      reducer: (state, action) => {
        if (typeof action.payload.error !== "undefined") {
          state.uploadError = action.payload.response;
        }

        state.creatingViaUpload = false;
        state.failedCurrencies = action.payload.response.failedCurrencies;
        state.userReadableErrors = action.payload.response.userReadableErrors;
      },
      prepare: (response, error) => ({
        payload: { response, error },
      }),
    },

    createExchangeLedgersViaUploadReset: {
      reducer: (state) => {
        state.creatingViaUpload = false;
        state.failedCurrencies = undefined;
        state.userReadableErrors = undefined;
        state.skippedCurrencies = undefined;
        state.uploading = undefined;
      },
      prepare: () => ({
        payload: {},
      }),
    },

    // Create Wallet Ledgers
    createWalletLedgers: {
      reducer: (state) => {
        state.creatingWallet = true;
        state.asyncCreationInProgress = false;
        state.invalidKeys = undefined;
        state.invalidToken = undefined;
        state.contract = undefined;
        state.duplicate = undefined;
        state.unauthorized = undefined;
        state.invalidAddressMessage = undefined;
      },
      prepare: (
        exchange,
        xpubs,
        addresses,
        token,
        file,
        signatures,
        addressGroupings,
        ledgerName
      ) => ({
        payload: {
          exchange,
          xpubs,
          addresses,
          token,
          file,
          signatures,
          addressGroupings,
          ledgerName,
        },
      }),
    },

    requestCreateWalletLedgers: {
      reducer: (state) => {
        state.creatingWallet = true;
      },
      prepare: () => ({
        payload: {},
      }),
    },

    receiveCreateWalletLedgers: {
      reducer: (state, action) => {
        state.createdLedgers = action.payload.response.ledgersCreated;
        state.creatingWallet = false;
        state.asyncCreationInProgress =
          action.payload.response.asyncCreationInProgress;
        state.invalidKeys = undefined;
        state.invalidToken = undefined;
        state.contract = undefined;
        state.duplicate = undefined;
        state.unauthorized = undefined;
        state.invalidAddressMessage = undefined;
      },
      prepare: (response) => ({
        payload: { response },
      }),
    },

    createWalletLedgersError: {
      reducer: (state, action) => {
        state.creatingWallet = false;
        state.createdLedgers = undefined;
        state.asyncCreationInProgress = false;
        state.invalidKeys = action.payload.response.invalidKeys;
        state.invalidToken = action.payload.response.invalidToken;
        state.contract = action.payload.response.contract;
        state.duplicate = action.payload.response.duplicate;
        state.unauthorized = action.payload.response.unauthorized;
        state.invalidAddressMessage =
          action.payload.response.invalidAddressMessage;
      },
      prepare: (response, error) => ({
        payload: { response, error },
      }),
    },

    resetWallletLedgerState: {
      reducer: (state) => {
        state.creatingWallet = false;
        state.addLedgerError = undefined;
      },
      prepare: () => ({
        payload: {},
      }),
    },

    // Delete Exchange Ledger
    deleteExchangeLedger: {
      reducer: () => {},
      prepare: (ledgerId, redirectAfterDelete) => ({
        payload: { ledgerId, redirectAfterDelete },
      }),
    },

    requestDeleteExchangeLedger: {
      reducer: (state) => {
        state.deletingLedger = true;
      },
      prepare: () => ({
        payload: {},
      }),
    },

    receiveDeleteExchangeLedger: {
      reducer: (state, action) => {
        state.deletingLedger = false;
        state.ledgers = state.ledgers.filter(
          (ledger) => ledger.id !== parseInt(action.payload.ledgerId, 10)
        );
      },
      prepare: (ledgerId) => ({
        payload: { ledgerId },
      }),
    },

    deleteExchangeLedgerError: {
      reducer: (state) => {
        state.deletingLedger = false;
      },
      prepare: (response, error) => ({
        payload: { response, error },
      }),
    },

    // Exchange Accounts
    fetchExchangeAccounts: {
      reducer: () => {},
      prepare: (
        exchange,
        linkGUID,
        apiLinkSuccessCallback,
        apiDismissCallback,
        oauthRequiredCallback
      ) => ({
        payload: {
          exchange,
          linkGUID,
          apiLinkSuccessCallback,
          apiDismissCallback,
          oauthRequiredCallback,
        },
      }),
    },

    requestExchangeAccounts: {
      reducer: (state) => {
        state.loadingExchangeAccounts = true;
      },
      prepare: () => ({
        payload: {},
      }),
    },

    receiveExchangeAccounts: {
      reducer: (state, action) => {
        state.loadingExchangeAccounts = false;
        state.exchangeAccounts[action.payload.exchange] =
          action.payload.response.accounts;
      },
      prepare: (exchange, response) => ({
        payload: { exchange, response },
      }),
    },

    exchangeAccountsError: {
      reducer: (state) => {
        state.loadingExchangeAccounts = false;
      },
      prepare: (response) => ({
        payload: { response },
      }),
    },

    // Fetch API Key Vaults
    fetchApiKeyVaults: {
      reducer: () => {},
      prepare: (exchange, apiKey, secretKey) => ({
        payload: { exchange, apiKey, secretKey },
      }),
    },

    requestApiKeyVaults: {
      reducer: (state) => {
        state.apiKeySavingStatus = STATUS.LOADING;
      },
      prepare: () => ({
        payload: {},
      }),
    },

    receiveApiKeyVaults: {
      reducer: (state, action) => {
        state.apiKeySavingStatus = STATUS.SUCCESS;
        state.apiKeyVault = {
          ...state.apiKeyVault,
          [action.payload.apiKey]: action.payload.response,
        };
      },
      prepare: (response, apiKey, secretKey) => ({
        payload: { response, apiKey, secretKey },
      }),
    },

    receiveApiKeyVaultsError: {
      reducer: (state, action) => {
        state.apiKeySavingStatus = STATUS.ERROR;
        state.apiKeyError = {
          message: action.payload.response.message,
          type: "apiKeyInvalid",
        };
      },
      prepare: (response) => ({
        payload: { response },
      }),
    },

    // Import API Key Vaults
    importApiKeyVaults: {
      reducer: () => {},
      prepare: (exchange, vaults) => ({
        payload: { exchange, vaults },
      }),
    },

    requestImportApiKeyVaults: {
      reducer: (state) => {
        state.apiKeySavingStatus = STATUS.LOADING;
      },
      prepare: () => ({
        payload: {},
      }),
    },

    receiveImportApiKeyVaults: {
      reducer: (state, action) => {
        state.apiKeySavingStatus = STATUS.SUCCESS;
        if (action.payload.response) {
          state.apiKeyVault = {
            ...state.apiKeyVault,
            ...action.payload.response,
          };
        }
      },
      prepare: (response) => ({
        payload: { response },
      }),
    },

    receiveImportApiKeyVaultsError: {
      reducer: (state) => {
        state.creatingViaApi = false;
        state.createdLedgers = undefined;
        state.asyncCreationInProgress = false;
      },
      prepare: (response) => ({
        payload: { response },
      }),
    },
    // Linked Exchanges
    fetchLinkedExchanges: () => {},
    requestLinkedExchanges: {
      reducer: (state, action) => {
        state.linkedExchangesStatus = action.payload.nextStatus;
      },
      prepare: (nextStatus) => ({
        payload: { nextStatus },
      }),
    },

    receiveLinkedExchanges: {
      reducer: (state, action) => {
        state.linkedExchanges = action.payload.response.linked;
        state.flattenedLinkedExchanges =
          action.payload.flattenedLinkedExchanges;
        state.linkedExchangesStatus = action.payload.nextStatus;
      },
      prepare: (response, flattenedLinkedExchanges, nextStatus) => ({
        payload: { response, flattenedLinkedExchanges, nextStatus },
      }),
    },

    receiveLinkedExchangesError: {
      reducer: (state) => {
        state.linkedExchangesStatus = STATUS.ERROR;
      },
      prepare: (response) => ({
        payload: { response },
      }),
    },

    // Poll Settings
    pollSettings: () => {},

    // Migrate Sources Via Upload
    migrateSourcesViaUpload: {
      reducer: () => {},
      prepare: (exchange, responseId, sources) => ({
        payload: { exchange, responseId, sources },
      }),
    },

    requestMigrateSourcesViaUpload: {
      reducer: (state) => {
        state.creatingViaUpload = true;
      },
      prepare: () => ({
        payload: {},
      }),
    },

    receiveMigrateSourcesViaUpload: {
      reducer: (state, action) => {
        state.creatingViaUpload = false;
        state.asyncUploadExchangeInProgress = action.payload.exchangeId;
        state.asyncCreationInProgress = true;
      },
      prepare: (exchangeId) => ({
        payload: { exchangeId },
      }),
    },

    receiveMigrateSourcesViaUploadError: {
      reducer: (state) => {
        state.creatingViaUpload = false;
        state.asyncCreationInProgress = false;
      },
      prepare: (response, error) => ({
        payload: { response, error },
      }),
    },

    // Rebuild Exchange Ledgers
    rebuildExchangeLedgers: {
      reducer: () => {},
      prepare: (exchangeId, linkGUID) => ({
        payload: { exchangeId, linkGUID },
      }),
    },

    requestRebuildExchangeLedgers: {
      reducer: (state, action) => {
        const { linkGUID } = action.payload;
        if (!state.rebuildingLedgers.includes(linkGUID)) {
          state.rebuildingLedgers.push(linkGUID);
        }
      },
      prepare: (linkGUID) => ({
        payload: { linkGUID },
      }),
    },
    receiveRebuildExchangeLedgers: {
      reducer: (state, action) => {
        const { linkGUID } = action.payload;

        // Remove `linkGUID` from `rebuildingLedgers`
        state.rebuildingLedgers = state.rebuildingLedgers.filter(
          (guid) => guid !== linkGUID
        );

        // Filter out ledgers associated with the `linkGUID`
        state.ledgers = state.ledgers.filter(
          (ledger) => ledger.linked?.linkGUID !== linkGUID
        );
      },
      prepare: (exchangeId, linkGUID, ledgerIds) => ({
        payload: { exchangeId, linkGUID, ledgerIds },
      }),
    },

    rebuildExchangeLedgersError: {
      reducer: (state, action) => {
        const { linkGUID } = action.payload;

        // Remove `linkGUID` from `rebuildingLedgers` if it exists
        state.rebuildingLedgers = state.rebuildingLedgers.filter(
          (guid) => guid !== linkGUID
        );
      },
      prepare: (linkGUID) => ({
        payload: { linkGUID },
      }),
    },

    // Migrate Via Upload
    migrateViaUpload: {
      reducer: () => {},
      prepare: (files, exchangeId) => ({
        payload: { files, exchangeId },
      }),
    },

    requestMigrateViaUpload: {
      reducer: (state) => {
        state.creatingViaUpload = true;
        state.uploadError = undefined;
        state.migrateResponseId = undefined;
        state.migrate = undefined;
      },
      prepare: () => ({
        payload: {},
      }),
    },

    receiveMigrateViaUpload: {
      reducer: (state, action) => {
        state.creatingViaUpload = false;
        state.uploadError = undefined;
        state.migrateResponseId = action.payload.responseId;
        state.migrateSources = action.payload.sources;
      },
      prepare: (exchangeId, responseId, sources) => ({
        payload: { exchangeId, responseId, sources },
      }),
    },

    migrateViaUploadError: {
      reducer: (state, action) => {
        state.creatingViaUpload = false;
        state.migrateResponseId = undefined;
        state.migrate = undefined;
        state.uploadError = action.payload.error;
      },
      prepare: (response, error) => ({
        payload: { response, error },
      }),
    },

    // Rename Ledger
    renameLedger: {
      reducer: () => {},
      prepare: (name, ledgerId) => ({
        payload: { name, ledgerId },
      }),
    },

    requestRenameLedger: {
      reducer: (state) => {
        state.renamingLedger = true;
      },
      prepare: () => ({
        payload: {},
      }),
    },

    receiveRenameLedger: {
      reducer: (state, action) => {
        const { ledgerId, name } = action.payload;

        // Find the index of the ledger to rename
        const renamedLedgerIndex = state.ledgers.findIndex(
          (ledger) => ledger.id === ledgerId
        );

        if (renamedLedgerIndex > -1) {
          // Update the ledger's `userLedgerName`
          state.ledgers[renamedLedgerIndex].userLedgerName = name;
        }

        // Set `renamingLedger` to false
        state.renamingLedger = false;
      },
      prepare: (name, ledgerId) => ({
        payload: { name, ledgerId },
      }),
    },

    renameLedgerError: {
      reducer: (state, action) => {
        state.renamingLinkedAccount = false;
        state.renamingLinkedAccountError = action.payload.error;
      },
      prepare: (response, error) => ({
        payload: { response, error },
      }),
    },
    // Rename Linked Account
    renameLinkedAccount: {
      reducer: () => {},
      prepare: (linkedAccountId, nickname) => ({
        payload: { linkedAccountId, nickname },
      }),
    },

    requestRenameLinkedAccount: {
      reducer: (state) => {
        state.renamingLinkedAccount = true;
        state.renamingLinkedAccountError = undefined;
      },
      prepare: () => ({
        payload: {},
      }),
    },

    receiveRenameLinkedAccount: {
      reducer: (state, action) => {
        state.renamingLinkedAccount = false;
        // Update the nickname in linkedExchanges if it exists
        if (state.linkedExchanges) {
          state.linkedExchanges = state.linkedExchanges.map((exchange) =>
            exchange.id === action.payload.linkedAccountId
              ? { ...exchange, nickname: action.payload.nickname }
              : exchange
          );
        }
      },
      prepare: (linkedAccountId, nickname) => ({
        payload: { linkedAccountId, nickname },
      }),
    },

    renameLinkedAccountError: {
      reducer: (state, action) => {
        state.renamingLinkedAccount = false;
        state.renamingLinkedAccountError = action.payload.error;
      },
      prepare: (response, error) => ({
        payload: { response, error },
      }),
    },

    // Reset Add Exchange Upload
    resetAddExchangeUpload: {
      reducer: (state) => {
        state.uploadError = undefined;
      },
      prepare: () => ({
        payload: {},
      }),
    },

    // Settings
    fetchSettings: () => {},
    requestSettings: {
      reducer: (state, action) => {
        state.settingsStatus = action.payload.nextStatus;
      },
      prepare: (nextStatus) => ({
        payload: { nextStatus },
      }),
    },

    receiveSettings: {
      reducer: (state, action) => {
        state.settings = action.payload.body;
        state.settingsStatus = action.payload.nextStatus;
      },
      prepare: (body, nextStatus) => ({
        payload: { body, nextStatus },
      }),
    },

    settingsError: {
      reducer: (state, action) => {
        state.settingsStatus = action.payload.nextStatus;
      },
      prepare: (body, nextStatus) => ({
        payload: { body, nextStatus },
      }),
    },

    // Unlink Exchange
    unlinkExchange: {
      reducer: () => {},
      prepare: (exchangeId, linkGUID) => ({
        payload: { exchangeId, linkGUID },
      }),
    },

    requestUnlinkExchange: {
      reducer: (state, action) => {
        state.unlinkExchangeStatus = action.payload.nextStatus;
      },
      prepare: (nextStatus) => ({
        payload: { nextStatus },
      }),
    },

    receiveUnlinkExchange: {
      reducer: (state, action) => {
        state.unlinkExchangeStatus = action.payload.nextStatus;
      },
      prepare: (nextStatus) => ({
        payload: { nextStatus },
      }),
    },

    unlinkExchangeError: {
      reducer: (state, action) => {
        state.unlinkExchangeStatus = action.payload.nextStatus;
      },
      prepare: (response, nextStatus) => ({
        payload: { response, nextStatus },
      }),
    },

    // Update Exchange All Ledgers
    updateExchangeAllLedgers: {
      reducer: () => {},
      prepare: (exchangeId, linkGUID) => ({
        payload: { exchangeId, linkGUID },
      }),
    },

    requestUpdateExchangeAllLedgers: {
      reducer: () => {},
      prepare: () => ({
        payload: {},
      }),
    },

    receiveUpdateExchangeAllLedgers: {
      reducer: (state, action) => {
        state.ledgers = updateLedgerSyncState(
          [
            {
              source: {
                id: action.payload.exchangeId,
                linkGUID: action.payload.linkGUID,
              },
            },
          ],
          state.ledgers
        );
      },
      prepare: (exchangeId, linkGUID) => ({
        payload: { exchangeId, linkGUID },
      }),
    },

    updateExchangeAllLedgersError: {
      reducer: (state) => {
        state.updating = false;
      },
      prepare: (response) => ({
        payload: { response },
      }),
    },

    // Update Exchange API Token
    updateExchangeApiToken: {
      reducer: () => {},
      prepare: (exchange, linkGUID, token) => ({
        payload: { exchange, linkGUID, token },
      }),
    },

    requestUpdateExchangeApiToken: {
      reducer: (state) => {
        state.apiTokenSavingStatus = STATUS.WORKING;
        state.apiTokenError = undefined;
      },
      prepare: () => ({
        payload: {},
      }),
    },

    receiveUpdateExchangeApiToken: {
      reducer: (state) => {
        state.apiTokenSavingStatus = STATUS.SUCCESS;
      },
      prepare: (response) => ({
        payload: { response },
      }),
    },

    receiveUpdateExchangeApiTokenError: {
      reducer: (state, action) => {
        state.apiTokenSavingStatus = STATUS.ERROR;
        state.apiTokenError = {
          message: action.payload.response.message,
          type: "apiTokenInvalid",
        };
      },
      prepare: (response) => ({
        payload: { response },
      }),
    },

    // Update Exchange API
    updateExchangeApiKey: {
      reducer: () => {},
      prepare: (
        exchange,
        linkGUID,
        apiKey,
        secretKey,
        passphrase,
        callLedgerImportAfterHandleKey,
        ledgerIds,
        exchangeId
      ) => ({
        payload: {
          exchange,
          linkGUID,
          apiKey,
          secretKey,
          passphrase,
          callLedgerImportAfterHandleKey,
          ledgerIds,
          exchangeId,
        },
      }),
    },

    requestUpdateExchangeApiKey: {
      reducer: (state) => {
        state.apiKeySavingStatus = STATUS.WORKING;
        state.apiTokenError = undefined;
      },
      prepare: () => ({
        payload: {},
      }),
    },

    receiveUpdateExchangeApiKey: {
      reducer: (state) => {
        state.apiKeySavingStatus = STATUS.SUCCESS;
      },
      prepare: (response) => ({
        payload: { response },
      }),
    },

    receiveUpdateExchangeApiKeyError: {
      reducer: (state, action) => {
        state.apiKeySavingStatus = STATUS.ERROR;
        state.apiKeyError = {
          message: action.payload.response.message,
          type: "apiKeyInvalid",
        };
      },
      prepare: (response) => ({
        payload: { response },
      }),
    },
    // Reducer functions
    updateExchangeLedgersViaUpload: {
      reducer: () => {},
      prepare: (files, exchangeId, linkGUID, ledgerIds) => ({
        payload: { files, exchangeId, linkGUID, ledgerIds },
      }),
    },

    requestUpdateExchangeLedgersViaUpload: {
      reducer: (state) => {
        state.uploading = true;
        state.failedCurrencies = undefined;
        state.userReadableErrors = undefined;
        state.skippedCurrencies = undefined;
        state.uploadError = undefined;
      },
      prepare: () => ({
        payload: {},
      }),
    },

    receiveUpdateExchangeLedgersViaUpload: {
      reducer: (state) => {
        state.uploading = false;
      },
      prepare: (response, exchangeId) => ({
        payload: { response, exchangeId },
      }),
    },

    updateExchangeLedgersViaUploadError: {
      reducer: (state, action) => {
        let uploadError;
        if (typeof action.payload.error !== "undefined") {
          uploadError = action.payload.response;
        }

        state.uploadError = uploadError;
        state.uploading = false;
        state.failedCurrencies = action.payload.response.failedCurrencies;
        state.userReadableErrors = action.payload.response.userReadableErrors;
      },
      prepare: (response, error) => ({
        payload: { response, error },
      }),
    },

    // Update Exchange Ledgers Via Address Groupings
    updateExchangeLedgersViaAddressGroupings: {
      reducer: () => {},
      prepare: (addressGroupings, exchangeId, ledgerIds) => ({
        payload: { addressGroupings, exchangeId, ledgerIds },
      }),
    },

    requestUpdateExchangeLedgersViaAddressGroupings: {
      reducer: (state) => {
        state.updating = true;
      },
      prepare: () => ({
        payload: {},
      }),
    },

    receiveUpdateExchangeLedgersViaAddressGroupings: {
      reducer: (state) => {
        state.updating = false;
      },
      prepare: (response, exchangeId) => ({
        payload: { response, exchangeId },
      }),
    },

    updateExchangeLedgersViaAddressGroupingsError: {
      reducer: (state, action) => {
        state.updating = false;
        state.updateError = action.payload.error;
      },
      prepare: (response, error) => ({
        payload: { response, error },
      }),
    },

    // Update Rollforward Via Upload
    updateRollforwardOpenLotsViaUpload: {
      reducer: () => {},
      prepare: (file) => ({
        payload: { file },
      }),
    },

    requestUpdateRollforwardOpenLotsViaUpload: {
      reducer: (state) => {
        state.rollforwardCreatingViaUpload = true;
        state.rollforwardCreated = false;
      },
      prepare: () => ({
        payload: {},
      }),
    },

    receiveUpdateRollforwardOpenLotsViaUpload: {
      reducer: (state) => {
        state.rollforwardCreatingViaUpload = true;
        state.rollforwardCreated = true;
      },
      prepare: (response) => ({
        payload: { response },
      }),
    },

    updateRollforwardOpenLotsViaUploadError: {
      reducer: (state, action) => {
        state.rollforwardCreatingViaUpload = false;
        state.rollforwardCreatingViaUploadError = action.payload.error;
        state.rollforwardCreated = false;
      },
      prepare: (error) => ({
        payload: { error },
      }),
    },

    // Verify Exchange Link
    verifyExchangeLink: {
      reducer: () => {},
      prepare: (
        exchange,
        linkGUID,
        apiLinkSuccessCallback,
        apiDismissCallback,
        oauthRequiredCallback
      ) => ({
        payload: {
          exchange,
          linkGUID,
          apiLinkSuccessCallback,
          apiDismissCallback,
          oauthRequiredCallback,
        },
      }),
    },

    requestVerifyExchangeLink: {
      reducer: (state, action) => {
        state.verifyingExchangeLink[action.payload.linkGUID] = STATUS.LOADING;
      },
      prepare: (linkGUID) => ({
        payload: { linkGUID },
      }),
    },

    receiveVerifyExchangeLink: {
      reducer: (state, action) => {
        state.verifyingExchangeLink[action.payload.linkGUID] = STATUS.SUCCESS;
      },
      prepare: (linkGUID) => ({
        payload: { linkGUID },
      }),
    },

    verifyExchangeLinkError: {
      reducer: (state, action) => {
        state.verifyingExchangeLink[action.payload.linkGUID] = STATUS.ERROR;
      },
      prepare: (linkGUID, response) => ({
        payload: { linkGUID, response },
      }),
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(reinitializePortfolio, () => initialState)
      .addCase(resetPortfolioData, (state) => {
        state.supportedExchangesStatus = STATUS.UNINITIALIZED;
        state.ledgersStatus = STATUS.UNINITIALIZED;
      })
      .addCase(dismissModal, (state) => {
        state.addLedgerError = undefined;
        state.uploadError = undefined;
        state.uploading = false;
        state.apiKeySavingStatus = STATUS.INITIAL;
        state.apiTokenSavingStatus = STATUS.INITIAL;
        state.apiKeyError = undefined;
        state.apiTokenError = undefined;
        state.renamingLinkedAccount = false;
        state.renamingLinkedAccountError = undefined;
      })
      .addCase(resetAddLedger, (state) => {
        state.uploadError = undefined;
        state.creatingViaUpload = false;
        state.creatingViaApi = false;
        state.createdLedgers = undefined;
        state.userReadableErrors = undefined;
        state.failedCurrencies = undefined;
        state.skippedCurrencies = undefined;
        state.uploading = undefined;
        state.asyncCreationInProgress = undefined;
        state.asyncUploadExchangeInProgress = undefined;
      });
  },
});

export const {
  linkedExchangesRequest,
  linkedExchangesReceive,
  bulkDeleteExchangeLedgers,
  requestbulkDeleteExchangeLedgers,
  receiveBulkDeleteExchangeLedgers,
  bulkDeleteExchangeLedgersError,
  bulkDeleteExchangeLedgersByIds,
  requestBulkDeleteExchangeLedgersByIds,
  receiveBulkDeleteExchangeLedgersByIds,
  bulkDeleteExchangeLedgersByIdsError,
  bulkUpdateLedgersBySource,
  requestBulkUpdateLedgersBySource,
  receiveBulkUpdateLedgersBySource,
  connectExchangeViaApiKey,
  requestConnectExchangeViaApiKey,
  receiveConnectExchangeViaApiKey,
  receiveConnectExchangeViaApiKeyError,
  connectExchangeOAuth,
  requestConnectExchangeOAuth,
  receiveConnectExchangeOAuthError,
  createExchangeLedgers,
  requestCreateExchangeLedgers,
  receiveCreateExchangeLedgers,
  createExchangeLedgersError,
  createExchangeLedgersViaUpload,
  requestCreateExchangeLedgersViaUpload,
  receiveCreateExchangeLedgersViaUpload,
  createExchangeLedgersViaUploadError,
  createExchangeLedgersViaUploadReset,
  createWalletLedgers,
  requestCreateWalletLedgers,
  receiveCreateWalletLedgers,
  createWalletLedgersError,
  resetWallletLedgerState,
  deleteExchangeLedger,
  requestDeleteExchangeLedger,
  receiveDeleteExchangeLedger,
  deleteExchangeLedgerError,
  fetchExchangeAccounts,
  requestExchangeAccounts,
  receiveExchangeAccounts,
  exchangeAccountsError,
  fetchApiKeyVaults,
  requestApiKeyVaults,
  receiveApiKeyVaults,
  receiveApiKeyVaultsError,
  importApiKeyVaults,
  requestImportApiKeyVaults,
  receiveImportApiKeyVaults,
  receiveImportApiKeyVaultsError,
  fetchLinkedExchanges,
  requestLinkedExchanges,
  receiveLinkedExchanges,
  receiveLinkedExchangesError,
  pollSettings,
  migrateSourcesViaUpload,
  requestMigrateSourcesViaUpload,
  receiveMigrateSourcesViaUpload,
  receiveMigrateSourcesViaUploadError,
  rebuildExchangeLedgers,
  requestRebuildExchangeLedgers,
  receiveRebuildExchangeLedgers,
  rebuildExchangeLedgersError,
  migrateViaUpload,
  requestMigrateViaUpload,
  receiveMigrateViaUpload,
  migrateViaUploadError,
  renameLedger,
  requestRenameLedger,
  receiveRenameLedger,
  renameLedgerError,
  renameLinkedAccount,
  requestRenameLinkedAccount,
  receiveRenameLinkedAccount,
  renameLinkedAccountError,
  resetAddExchangeUpload,
  fetchSettings,
  requestSettings,
  receiveSettings,
  settingsError,
  fetchSupportedExchanges,
  requestSupportedExchanges,
  receiveSupportedExchanges,
  receiveSupportedExchangesError,
  unlinkExchange,
  requestUnlinkExchange,
  receiveUnlinkExchange,
  unlinkExchangeError,
  updateExchangeAllLedgers,
  requestUpdateExchangeAllLedgers,
  receiveUpdateExchangeAllLedgers,
  updateExchangeAllLedgersError,
  updateExchangeApiToken,
  requestUpdateExchangeApiToken,
  receiveUpdateExchangeApiToken,
  receiveUpdateExchangeApiTokenError,
  updateExchangeApiKey,
  requestUpdateExchangeApiKey,
  receiveUpdateExchangeApiKey,
  receiveUpdateExchangeApiKeyError,
  updateExchangeLedgersViaUpload,
  requestUpdateExchangeLedgersViaUpload,
  receiveUpdateExchangeLedgersViaUpload,
  updateExchangeLedgersViaUploadError,
  updateExchangeLedgersViaAddressGroupings,
  requestUpdateExchangeLedgersViaAddressGroupings,
  receiveUpdateExchangeLedgersViaAddressGroupings,
  updateExchangeLedgersViaAddressGroupingsError,
  updateRollforwardOpenLotsViaUpload,
  requestUpdateRollforwardOpenLotsViaUpload,
  receiveUpdateRollforwardOpenLotsViaUpload,
  updateRollforwardOpenLotsViaUploadError,
  verifyExchangeLink,
  requestVerifyExchangeLink,
  receiveVerifyExchangeLink,
  verifyExchangeLinkError,
  fetchLedgers,
  requestLedgers,
  receiveLedgers,
  ledgersError,
} = exchangesSlice.actions;

export default exchangesSlice.reducer;
