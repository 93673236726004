export const ACCOUNTS_PAYABLE = "Accounts Payable";
export const ACCOUNTS_RECEIVABLE = "Accounts Receivable";

export function getAccountById(accountId, accounts) {
  return accounts.find((i) => parseInt(i.id, 10) === parseInt(accountId, 10));
}

export function isTagInGroup(tag, tagGroups) {
  for (let i = 0; i < tagGroups.length; i += 1) {
    const { tags } = tagGroups[i];
    for (let j = 0; j < tags.length; j += 1) {
      if (tags[j].tag === tag) {
        return true;
      }
    }
  }
  return false;
}

export function getDefaultAccountInfo(accountMappings) {
  let defaultMappingId = null;
  let feeAccountId;
  let gainLossAccountId;

  if (accountMappings.defaultMapping) {
    defaultMappingId = accountMappings.defaultMapping.id;
    feeAccountId = accountMappings.defaultMapping.feeAccountId || undefined;
    gainLossAccountId =
      accountMappings.defaultMapping.gainLossAccountId || undefined;
  }

  return { defaultMappingId, feeAccountId, gainLossAccountId };
}

function isDefined(field) {
  return typeof field !== "undefined" && field !== null && field !== "";
}

export function isMappingValid(mapGroup) {
  if (isDefined(mapGroup)) {
    return true;
  }

  return false;
}

export function filterUsedTagGroups(tagGroups, tagGroupMappings) {
  return tagGroups.filter((tg) => {
    return !tagGroupMappings.find((mapping) => mapping.tagGroupId === tg.id);
  });
}

export function getMappedAccount(tagGroup, tagGroupMappings, accounts) {
  const mapping = (tagGroupMappings || []).find(
    (m) => parseInt(m.tagGroupId, 10) === parseInt(tagGroup.id, 10)
  );

  return mapping
    ? (accounts || []).find(
        (a) => parseInt(a.id, 10) === parseInt(mapping.accountId, 10)
      )
    : undefined;
}

function escapeCsvCell(parentFolder, value) {
  let returnValue = value;
  returnValue = returnValue.replace(/[*?"<>|:]/g, "");
  // Check if the value contains a comma, newline, or double quote
  if (
    typeof returnValue === "string" &&
    (returnValue.includes(",") || returnValue.includes("\n"))
  ) {
    return `"${parentFolder}${returnValue}"`.slice(0, 100);
  }
  // Return the value as is if no special characters are found
  return `${parentFolder}${returnValue}`.slice(0, 100);
}

export function downloadAssetCsv(smartWalletList, parentFolder, name) {
  const data = smartWalletList.map((smartWallet) => {
    let pf = "";
    if (parentFolder.trim() !== "") {
      pf = `${parentFolder}:`;
    }

    const tokenPart = `${smartWallet.tokenName || smartWallet.currencyType} (${smartWallet.currencyType})`;

    const row = [
      ``,
      `${escapeCsvCell(pf, tokenPart)}`,
      `Other Current Assets`,
      `Other Current Assets`,
    ];
    return row.join(",");
  });

  const csvContent = `Account Number,Account Name,Type,Detail Type\n${data.join("\n")}`;

  const blob = new Blob([csvContent], {
    type: "text/csv",
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${name.toLowerCase()}-asset-import.csv`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}
