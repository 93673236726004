import { call, put, all, select } from "redux-saga/effects";
import {
  requestCreateExchangeLedgers,
  createExchangeLedgersError,
  receiveCreateExchangeLedgers,
  fetchLedgers,
} from "app.reducers/exchanges";

import { MODAL_EXCHANGE_OAUTH_POP_UP } from "app.constants/modals";

import { handleApiKeyError } from "app.actions/helpers";

import ExchangesAPI from "app.api/ExchangesAPI";

import { doRedirect, openModal, openApiKeyModal } from "app.reducers/ui";

function* createExchangeLedgers(action) {
  const {
    exchangeId,
    linkGUID,
    accountIds,
    accountingStrategy,
    oauthRequiredCallback,
    apiLinkSuccessCallback,
    apiDismissCallback,
  } = action.payload;

  yield put(requestCreateExchangeLedgers());

  const response = yield call(
    ExchangesAPI.addBulkExchangeLedgers,
    exchangeId,
    linkGUID,
    accountIds,
    accountingStrategy
  );

  const { body, error } = response;

  if (error) {
    if (
      typeof body.metadata !== "undefined" &&
      typeof body.metadata.code !== "undefined"
    ) {
      const exchange = yield select((state) =>
        state.exchanges.supportedExchanges.find(
          (item) => item.id === exchangeId
        )
      );

      switch (body.metadata.code) {
        case "oauthRequired":
          yield put(
            openModal(MODAL_EXCHANGE_OAUTH_POP_UP, undefined, {
              exchange,
              linkGUID,
              reLink: true,
              oauthRequiredCallback,
            })
          );
          break;
        case "apiKeyInvalidSignedRequest":
        case "apiKeyInvalid":
        case "apiKeyOverScope":
          yield put(
            handleApiKeyError({
              errorCode: body.metadata.code, // errorCode
              exchange,
              linkGUID,
              openApiKeyModal,
              errorCallback: createExchangeLedgersError,
              error,
              apiLinkSuccessCallback,
              apiDismissCallback,
            })
          );
          break;
        default:
          break;
      }

      yield put(createExchangeLedgersError(response.body, response.error));
    }
  } else {
    yield all([
      put(receiveCreateExchangeLedgers(response.body, exchangeId)),
      put(fetchLedgers()),
      put(
        doRedirect(
          `/portfolio/add-ledger/confirm/${exchangeId}?linkGUID=${linkGUID}`
        )
      ),
    ]);
  }
}

export default createExchangeLedgers;
