import { call, put } from "redux-saga/effects";
import * as tagsActions from "app.reducers/tags";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";
import { getLoadingState } from "app.utils/selectors";
import { getSortBy } from "app.utils";
import { STATUS, CONDITIONS } from "app.constants";

function* fetchTagsAndRules() {
  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.tags.tagsAndRulesStatus
  );

  if (isLoading) return;

  yield put(tagsActions.requestTagsAndRules(nextStatus));

  const response = yield call(ExchangeLedgerAPI.getTagsAndRules);

  if (response.error) {
    console.error("Error in fetchTagsAndRules", response.body, response.error);
    yield put(tagsActions.tagsAndRulesError(response.body, STATUS.ERROR));
  } else {
    const rules = response.body.filter(
      (item) => item.conditionLabel !== CONDITIONS.NO_CONDITION
    );

    // merge rule tags into no_condition
    const mergedTags = Object.values(
      response.body.reduce((acc, item) => {
        const key = item.tag;
        const color = item.colorId;

        if (!acc[key]) {
          // If the label doesn't exist yet, initialize it
          acc[key] =
            item.conditionLabel === CONDITIONS.NO_CONDITION
              ? { ...item }
              : {
                  tag: item.tag,
                  colorId: color,
                  id: undefined,
                  counterPartyMappings: null,
                  tagsApplied: 0,
                  conditionLabel: CONDITIONS.NO_CONDITION,
                };
          if (item.conditionLabel !== CONDITIONS.NO_CONDITION) {
            // Merge counts into the "NO_CONDITION" type object
            acc[key].tagsApplied += item.tagsApplied;
          }
        }

        return acc;
      }, {})
    );

    const tags = mergedTags.sort(getSortBy((x) => x.tag || "", true));

    yield put(tagsActions.receiveTagsAndRules(tags, rules, response.body));
  }
}

export default fetchTagsAndRules;
