import { select, call, delay, put, all } from "redux-saga/effects";
import i18n from "i18next";

import { handleApiKeyError, handleWalletTokenError } from "app.actions/helpers";
import {
  requestUpdateExchangeAllLedgers,
  receiveUpdateExchangeAllLedgers,
  updateExchangeAllLedgersError,
  fetchLedgers,
} from "app.reducers/exchanges";

import {
  openModal,
  openApiKeyModal,
  openWalletTokenModal,
  dismissModal,
  addFlashMessage,
  removeFlashMessage,
} from "app.reducers/ui";

import {
  generateGUID,
  getLinkedExchangeByLinkGUID,
  getExchangeById,
} from "app.utils";

import { STATUS_INFO } from "app.components/Messages/FlashMessage";

import { TEN_SECONDS } from "app.constants";
import { MODAL_EXCHANGE_OAUTH_POP_UP } from "app.constants/modals";

import ExchangeAPI from "app.api/ExchangeLedgerAPI";

function* updateExchangeAllLedgers(action) {
  const { exchangeId, linkGUID } = action.payload;

  yield put(requestUpdateExchangeAllLedgers());

  const response = yield call(
    ExchangeAPI.updateExchangeAllLedgers,
    exchangeId,
    linkGUID
  );
  const { body, error } = response;

  if (error) {
    const exchange = yield select((state) =>
      state.exchanges.supportedExchanges.find((item) => item.id === exchangeId)
    );

    if (
      typeof body.metadata !== "undefined" &&
      typeof body.metadata.code !== "undefined"
    ) {
      if (
        typeof body.metadata !== "undefined" &&
        typeof body.metadata.code !== "undefined"
      ) {
        switch (body.metadata.code) {
          case "oauthRequired":
            yield put(
              openModal(MODAL_EXCHANGE_OAUTH_POP_UP, undefined, {
                exchange,
                reLink: true,
              })
            );
            break;
          case "exchangeLinkInactive":
          case "apiKeyInvalid":
            yield put(
              handleApiKeyError({
                errorCode: body.metadata.code, // errorCode
                exchange,
                linkGUID: body.metadata.linkGUID,
                openApiKeyModal,
                errorCallback: updateExchangeAllLedgersError,
                error,
              })
            );
            break;
          case "apiTokenInvalid":
            yield put(
              handleWalletTokenError(
                body.metadata.code, // errorCode
                exchange,
                body.metadata.linkGUID,
                openWalletTokenModal,
                updateExchangeAllLedgersError,
                error,
                undefined,
                undefined
              )
            );
            break;
          default:
            break;
        }
        yield put(updateExchangeAllLedgersError(body, error));
      }
    }
  } else {
    const errorToDo = [];

    const { supportedExchanges, linkedExchanges } = yield select(
      (state) => state.exchanges
    );

    if (Array.isArray(body.syncNotNeeded)) {
      body.syncNotNeeded.forEach((curSync) => {
        const messageId = generateGUID();

        const linkedExchange = getLinkedExchangeByLinkGUID(
          curSync,
          linkedExchanges
        );

        const exchange = getExchangeById(
          linkedExchange.exchangeId,
          supportedExchanges
        );

        errorToDo.push({
          // eslint-disable-next-line redux-saga/yield-effects
          removeFlashMessageAction: put(removeFlashMessage(messageId)),
        });

        errorToDo.push({
          // eslint-disable-next-line redux-saga/yield-effects
          putAction: put(
            addFlashMessage(
              i18n.t("modals.syncAll.upToDate", {
                label: exchange?.label || "",
                nickname: linkedExchange?.nickname || "",
              }),
              messageId,
              STATUS_INFO
            )
          ),
        });
      });
    }
    yield all(errorToDo.map((x) => x.putAction));

    yield all([
      put(receiveUpdateExchangeAllLedgers(exchangeId, linkGUID)),
      put(fetchLedgers()),
      put(dismissModal()),
    ]);

    yield delay(TEN_SECONDS);
    yield all(errorToDo.map((x) => x.removeFlashMessageAction));
  }
}

export default updateExchangeAllLedgers;
