import { call, put } from "redux-saga/effects";
import {
  requestConnectAccountingOAuth,
  connectAccountingOAuthError,
} from "app.reducers/accountingIntegrations";
import ExchangesAPI from "app.api/ExchangesAPI";

function* connectAccountingOAuth(action) {
  const { accountingIntegrationName, slug } = action.payload;

  yield put(requestConnectAccountingOAuth());

  const response = yield call(
    ExchangesAPI.initConnectIntegration,
    accountingIntegrationName,
    slug
  );

  if (response.error) {
    yield put(connectAccountingOAuthError(response.body, response.error));
  } else {
    window.location = response.body.exchangeOAuthURI;
  }
}

export default connectAccountingOAuth;
