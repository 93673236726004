import React, { useEffect, useRef } from "react";
import { getDateLocalePattern } from "app.utils/dates";
import { useGetUserLocale } from "app.hooks";
import { parse, format } from "date-fns";

const INPUT_BASE_CLASS =
  "text-sm border border-zinc-100 rounded-sm bg-white shadow-sm p-1 w-28";

const DateInput = ({
  open,
  selectedDate,
  invalidDay,
  invalidDate,
  placeholder,
  disabled,
  selectCallback,
}) => {
  const dateInput = useRef();

  const userLocale = useGetUserLocale();
  const formatString = getDateLocalePattern(userLocale);

  useEffect(() => {
    if (!selectedDate) dateInput.current.value = "";
    if (selectedDate && !invalidDay) {
      dateInput.current.value = format(selectedDate, formatString);
    }
  }, [selectedDate, invalidDay, formatString]);

  const typeDate = (e) => {
    const parsedDate = parse(e.target.value, formatString, new Date());
    selectCallback(parsedDate);
  };

  return (
    <input
      onClick={(e) => {
        if (open) e.stopPropagation();
      }}
      ref={dateInput}
      placeholder={open ? formatString.toUpperCase() : placeholder}
      className={[
        INPUT_BASE_CLASS,
        invalidDate ? "text-red-700" : "",
        "w-28",
      ].join(" ")}
      disabled={disabled}
      autoComplete="off"
      onChange={typeDate}
    />
  );
};

export default DateInput;
