import React from "react";

const BUTTON_BASE_SIZE = "text-sm px-1";

// we can add support for LG and XLG buttons later, but we don't
// even currently use them anywhere
const BUTTON_SIZE = {
  xs: "text-xxs px-2 py-0.5",
  sm: "text-xs px-1 py-0.75",
  md: "text-xs px-2.5 py-1.5",
  lg: "text-md px-2.5 py-1.5",
};

const Select = ({
  hasError,
  buttonType = "standard",
  buttonSize,
  className,
  children,
  ...htmlAttributes
}) => {
  const inputClass = [
    "transition-all",
    "rounded-sm",
    "border",
    buttonType === "standard" ? "h-8" : "",
    buttonType === "standard" ? "shadow-inner" : "",
    BUTTON_SIZE[buttonSize] ? BUTTON_SIZE[buttonSize] : BUTTON_BASE_SIZE,
    "leading-7",
    "disabled:cursor-not-allowed",
    "disabled:opacity-60",
    "disabled:bg-zinc-100",
    "placeholder:text-sm",
    "focus-visible:ring",
    "focus:ring",
    "bg-white",
    hasError ? "ring-red-600" : "",
    buttonType === "standard" ? "bg-white" : "",
    className,
    hasError ? "border-red-700 text-red-700" : "border-zinc-300",
  ]
    .filter(Boolean)
    .join(" ");
  return (
    <select className={inputClass} {...htmlAttributes}>
      {children}
    </select>
  );
};

Select.displayName = "Select";

export default Select;
