import { call, put } from "redux-saga/effects";

import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  requestFavoriteLedger,
  receiveFavoriteLedger,
  favoriteLedgerError,
} from "app.actions/exchangeLedger";

// import { fetchLedgers } from "app.reducers/exchanges";

function* favoriteLedger(action) {
  const { ledgerId, userFavorite } = action;

  yield put(requestFavoriteLedger(ledgerId, userFavorite));

  const response = yield call(
    ExchangeLedgerAPI.favoriteLedger,
    ledgerId,
    userFavorite
  );

  if (response.error) {
    yield put(favoriteLedgerError(ledgerId));
  } else {
    yield put(receiveFavoriteLedger(ledgerId));
  }
}

export default favoriteLedger;
