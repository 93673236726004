import { call, put, all, select } from "redux-saga/effects";
import {
  createExchangeLedgersViaUploadReset,
  createExchangeLedgersViaUploadError,
  requestCreateExchangeLedgersViaUpload,
  receiveCreateExchangeLedgersViaUpload,
  fetchLedgers,
} from "app.reducers/exchanges";
import { doRedirect, openApiKeyModal } from "app.reducers/ui";
import ExchangesAPI from "app.api/ExchangesAPI";

import { handleApiKeyError } from "app.actions/helpers";

function* createExchangeLedgersViaUpload(action) {
  const { files, exchangeId, linkGUID, accountingStrategy } = action.payload;

  yield put(requestCreateExchangeLedgersViaUpload());

  const response = yield call(
    ExchangesAPI.createExchangeLedgersViaUpload,
    files,
    exchangeId,
    accountingStrategy
  );

  const { body, error } = response;

  if (error) {
    if (typeof body.metadata !== "undefined") {
      const exchange = yield select((state) =>
        state.exchanges.supportedExchanges.find(
          (item) => item.id === exchangeId
        )
      );

      yield all([
        put(
          handleApiKeyError({
            errorCode: body.metadata.code, // errorCode
            exchange,
            linkGUID,
            openApiKeyModal,
            errorCallback: createExchangeLedgersViaUploadError,
            error,
          })
        ),
        put(createExchangeLedgersViaUploadReset()),
      ]);
    } else {
      yield put(createExchangeLedgersViaUploadError(body, error));
    }
  } else {
    yield all([
      put(receiveCreateExchangeLedgersViaUpload(exchangeId)),
      put(fetchLedgers()),
      put(doRedirect(`/portfolio/add-ledger/confirm/${exchangeId}`)),
    ]);
  }
}
export default createExchangeLedgersViaUpload;
