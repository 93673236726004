import { call, put } from "redux-saga/effects";
import {
  requestAccountMappings,
  receiveAccountMappings,
  accountMappingsError,
} from "app.reducers/accountingIntegrations";
import ExchangesAPI from "app.api/ExchangesAPI";
import { getLoadingState } from "app.utils/selectors";
import { STATUS } from "app.constants";

function* fetchAccountMappings(action) {
  const { connectionId } = action.payload;
  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.accountingIntegrations.accountMappingStatus
  );

  if (isLoading) return;

  yield put(requestAccountMappings(nextStatus));

  const response = yield call(ExchangesAPI.getAccountMappings, connectionId);

  if (response.error) {
    console.error(
      "Error in fetchAccountMappings",
      response.body,
      response.error
    );
    yield put(accountMappingsError(response.body, STATUS.ERROR));
  } else {
    yield put(receiveAccountMappings(response.body));
  }
}

export default fetchAccountMappings;
