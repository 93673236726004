import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { dismissModal } from "app.reducers/ui";
import { useTranslation } from "react-i18next";
import Button from "app.components/Util/Button";
import CopyToClipboard from "app.components/Util/CopyToClipboard";
import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";
import { CLERK_BASE } from "app.constants";

function ModalProfileInfo() {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const handleDismiss = (e) => {
    e.preventDefault();
    reduxDispatch(dismissModal());
  };

  const clerkProfileLink = CLERK_BASE
    ? `${CLERK_BASE}/account/account?type=userGUID&val=${user.userGUID}`
    : undefined;

  const clerkOrgLink = CLERK_BASE
    ? `${CLERK_BASE}/organization/${user.organizationGUID}`
    : undefined;

  return (
    <>
      <ModalHeader
        title={t("organization.profiles.profileInfo")}
        closeCallback={handleDismiss}
      />

      <div className="m-4">
        <div className="flex items-start justify-between">
          <dl>
            <dt className="text-sm font-bold">
              {t("organization.profiles.userId")}
            </dt>
            <dd className="mb-2 flex items-center gap-1 text-black/80">
              {user.sub ? (
                <>
                  <span>{user.sub}</span>
                  <CopyToClipboard text={user.sub} label={user.sub} />
                </>
              ) : (
                <span className="text-zinc-500 italic">
                  {t("common.nSlashA")}
                </span>
              )}
            </dd>
            <dt className="text-sm font-bold">
              {t("organization.profiles.profileDisplayName")}
            </dt>
            <dd className="mb-2 flex items-center gap-1 text-black/80">
              {user.profileDisplayName ? (
                <>
                  <span>{user.profileDisplayName}</span>
                  <CopyToClipboard
                    text={user.profileDisplayName}
                    label={user.profileDisplayName}
                  />
                </>
              ) : (
                <span className="text-zinc-500 italic">
                  {t("common.nSlashA")}
                </span>
              )}
            </dd>
            <dt className="text-sm font-bold">
              {t("organization.profiles.profileId")}
            </dt>
            <dd className="mb-2 flex items-center gap-1 font-mono text-black/80">
              {user.uid}
              <CopyToClipboard text={user.uid} label={user.uid} />
            </dd>
            <dt className="text-sm font-bold">
              {t("organization.profiles.profileGuid")}
            </dt>
            <dd className="mb-2 flex items-center gap-1 font-mono text-black/80">
              {user.profileGUID}
              <CopyToClipboard
                text={user.profileGUID}
                label={user.profileGUID}
              />
            </dd>
            <dt className="text-sm font-bold">
              {t("organization.profiles.userGuid")}
            </dt>
            <dd className="mb-2 flex items-center gap-1 font-mono text-black/80">
              {user.userGUID}
              <CopyToClipboard text={user.userGUID} label={user.userGUID} />
            </dd>
            <dt className="text-sm font-bold">
              {t("organization.profiles.organizationGuid")}
            </dt>
            <dd className="mb-2 flex items-center gap-1 font-mono text-black/80">
              {user.organizationGUID}
              <CopyToClipboard
                text={user.organizationGUID}
                label={user.organizationGUID}
              />
            </dd>
          </dl>
          <div className="flex flex-col justify-start gap-2">
            {clerkProfileLink ? (
              <a
                href={clerkProfileLink}
                rel="noopener noreferrer"
                target="_blank"
                className="text-link-blue border-link-blue hover:border-link-blue-active hover:text-link-blue-active inline-block rounded-sm border p-2 transition-all hover:bg-blue-50"
              >
                {t("organization.profiles.goToUserInClerk")} &rarr;
              </a>
            ) : null}
            {clerkOrgLink ? (
              <a
                href={clerkOrgLink}
                rel="noopener noreferrer"
                target="_blank"
                className="text-link-blue border-link-blue hover:border-link-blue-active hover:text-link-blue-active inline-block rounded-sm border p-2 transition-all hover:bg-blue-50"
              >
                {t("organization.profiles.goToOrganizationInClerk")} &rarr;
              </a>
            ) : null}
          </div>
        </div>
      </div>
      <ModalControls>
        <Button
          data-testid="close-modal"
          onClick={handleDismiss}
          buttonType="text"
          text={t("common.close")}
        />
      </ModalControls>
    </>
  );
}

ModalProfileInfo.displayName = "ModalProfileInfo";

export default ModalProfileInfo;
