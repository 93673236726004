import React from "react";
import { useTranslation } from "react-i18next";

const Label = ({
  required = false,
  optional = false,
  className,
  children,
  mini = false,
  small = false,
  error,
  disabled = false,
  description,
  htmlFor,
  ...htmlAttributes
}) => {
  const { t } = useTranslation();
  const labelClass = [
    "leading-5",
    mini ? "text-xs text-zinc-500 font-normal uppercase" : "",
    small ? "text-xs font-medium" : "",
    !mini && !small ? "font-bold text-sm" : "",
    "mb-1 md:mb-0",
    disabled ? "opacity-75" : "",
    className,
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <label className={labelClass} htmlFor={htmlFor} {...htmlAttributes}>
      {children}
      {required && !error ? (
        <small className="leading-none font-normal text-red-700 md:ml-1">
          {t("common.required")}
        </small>
      ) : null}
      {optional && !error ? (
        <small className="leading-none font-normal text-zinc-500 md:ml-1">
          {t("common.optional")}
          {description ? "," : ""}
        </small>
      ) : null}
      {error ? (
        <small className="leading-none font-normal text-red-700 md:ml-1">
          {error}
        </small>
      ) : null}
      {description ? (
        <small className="leading-none font-normal text-zinc-500 md:ml-1">
          {description}
        </small>
      ) : null}
    </label>
  );
};

Label.displayName = "Label";

export default Label;
