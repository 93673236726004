import React from "react";
import { useTranslation } from "react-i18next";

import { TIME_ZONES } from "app.constants";

const SELECT_DROPDOWN_BASE_CLASS =
  "bg-white border border-zinc-100 rounded-sm shadow-sm text-sm h-[30px] px-2 py-1.5";

function getDefaultTimeZone() {
  const envTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (TIME_ZONES.findIndex((i) => i === envTz) === -1) {
    return "UTC";
  }
  return envTz;
}

const TimeZonePicker = ({
  id,
  disabled,
  selectedTimeZone,
  savedTimeZone,
  setTimeZoneCallback,
}) => {
  const { t } = useTranslation();
  const localTz = getDefaultTimeZone();

  return (
    <select
      disabled={disabled}
      className={SELECT_DROPDOWN_BASE_CLASS}
      name={id}
      id={id}
      value={selectedTimeZone}
      onBlur={(e) => {
        e.preventDefault();
        setTimeZoneCallback(e.target.value);
      }}
      onChange={(e) => {
        e.preventDefault();
        setTimeZoneCallback(e.target.value);
      }}
    >
      {savedTimeZone ? (
        <optgroup label={t("settings.timeZone.current")}>
          <option key="curtz" value={savedTimeZone}>
            {savedTimeZone.replaceAll("_", " ")}
          </option>
        </optgroup>
      ) : null}
      <optgroup label={t("settings.timeZone.localTimeZone")}>
        <option key="localtz" value={localTz}>
          {localTz.replaceAll("_", " ")}
        </option>
      </optgroup>
      <optgroup label={t("settings.timeZone.timeZones")}>
        {TIME_ZONES.map((tz) => (
          <option key={tz} value={tz}>
            {tz.replaceAll("_", " ")}
          </option>
        ))}
      </optgroup>
    </select>
  );
};

TimeZonePicker.getDefaultTimeZone = getDefaultTimeZone;

export default TimeZonePicker;
