Object.values =
  Object.values ||
  function values(O) {
    return Object.keys(O).map((x) => O[x]);
  };

export const TIERS = {
  BASIC: 1,
  ESSENTIALS: 2,
  PROFESSIONAL: 3,
  _values: {
    1: { value: "Individual", id: "balance_basics", ordinal: 1 },
    2: { value: "Tax Professional / CPA", id: "balance_unlimited", ordinal: 2 },
    3: { value: "Professional", id: "balance_unlimited", ordinal: 3 },
  },

  values() {
    // eslint-disable-next-line no-underscore-dangle
    return Object.values(this._values);
  },

  valueAt(val) {
    // eslint-disable-next-line no-underscore-dangle
    return this._values[val]?.value || undefined;
  },

  ids() {
    // Object.values can't guarantee order
    // It will probably be ordered, but still

    const values = this.values();

    values.sort((left, right) => {
      return left.ordinal - right.ordinal;
    });

    return values.map((x) => x.id);
  },
};

export const FEATURES = {
  DOCUMENTS: "documents",
  REPORTS: "reports",
  SMARTWALLET: "smart-wallet",
  COLLABORATORS: "collaborators",
  WALLETS: "wallets",
  EXCHANGES: "exchanges",
  FIFOLIFO: "fifolifo",
};
