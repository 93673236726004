import { put, call, select, all } from "redux-saga/effects";
import { receiveAuthorizeUser } from "app.reducers/user";
import TellerAPI from "app.api/TellerAPI";
import {
  DEFAULT_PLAN_ID,
  PROFESSIONAL_PLAN_ID,
  BILLING_ERROR_PATH,
  ACCOUNT_ERROR_PATH,
  BILLING_ERROR_DEFAULT,
  ORGANIZATION_ERROR_PATH,
} from "app.constants";

import { STRIPE_INSTANCE as stripePromise } from "app.constants/stripe";

import {
  receiveEnrollment,
  requestEnrollment,
  setUseCurrentBillingInfo,
  setAccountError,
  setAccountEmailError,
  setOrganizationError,
  setBillingError,
  receiveEnrollmentError,
} from "app.actions/enrollment";
import { doRedirect } from "app.reducers/ui";
import fetchUser from "app.sagas/user/fetchUser";
import enterEnrollmentSection from "app.sagas/enrollment/enterEnrollmentSection";

import {
  checkPasswordsMatch,
  checkPasswordLength,
  lookupTranslation,
} from "app.utils";

function* doLogin(email, password) {
  const loginResponse = yield call(TellerAPI.login, email, password);
  const ticket = loginResponse.body;
  const response = yield call(TellerAPI.getTokenFromTicket, ticket);
  yield put(receiveAuthorizeUser(response.body.access_token));
  // Setup the user
  yield call(fetchUser);
}

function* runEnrollment({ cardInstance }) {
  const {
    queryingEnrollmentData,
    enrollmentSubmitting,
    currentlySelectedPlanId,
    accountInformation,
    billingInformation,
    locationInformation,
    organizationInformation,
    isLoggedIn,
    planQuote,
  } = yield select((state) => state.enrollment);
  const { email, password, confirmPassword, acceptTOS } = accountInformation;

  if (!isLoggedIn && (!email || !password || !confirmPassword || !acceptTOS)) {
    return;
  }

  if (queryingEnrollmentData || enrollmentSubmitting) return;
  yield put(requestEnrollment());

  const isDefaultPlan = currentlySelectedPlanId === DEFAULT_PLAN_ID;
  const isProfessionalPlan = currentlySelectedPlanId === PROFESSIONAL_PLAN_ID;
  // We have 4 potential sources of errors:
  // 1. Billing info error; can come from them not having a cardholder name, or from stripe creating a token error, or from the server after we make the account
  // 2. Location info error; can we actually have an error here?
  // 3. Org info error; can come from here, or from making an org
  // 4. The server, somewhere; we can potentially figure out if there's another place to jam a server error once we have it (like into one of those 4 sections)
  const submissionErrors = [];
  // If we aren't logged in, we can check for account info errors
  const { zipCode } = locationInformation;
  const { cardholderName, cardValid, useCurrentBillingInfo } =
    billingInformation;
  let stripeToken;
  if (!isDefaultPlan) {
    // 1. Billing Info Errors;
    let billingError;
    // Nothing to check if we have are using our current billing information
    if (!useCurrentBillingInfo) {
      // Check if card is invalid; the rest will be checked at the server step
      if (!cardholderName || !cardValid) {
        billingError = lookupTranslation("card_invalid", BILLING_ERROR_PATH);
      } else {
        // Make token, apply to account, set the billing info
        const tokenOptions = {
          name: cardholderName,
          address_zip: zipCode,
        };
        const stripe = yield stripePromise;
        const tokenRequest = yield call(
          stripe.createToken,
          cardInstance,
          tokenOptions
        );

        if (tokenRequest.error) {
          billingError = tokenRequest.error.message;
        } else {
          stripeToken = tokenRequest.token.id;
        }
      }
    }
    if (typeof billingError !== "undefined") {
      submissionErrors.push(setBillingError(billingError));
    }
  }
  // 2. Location Errors -- so far, no possible errors
  // 3. Organization Info
  const { organizationName, profileLimit } = organizationInformation;
  if (isProfessionalPlan) {
    let organizationError;
    if (!organizationName) {
      organizationError = lookupTranslation(
        "name_required",
        ORGANIZATION_ERROR_PATH
      );
    } else if (Number.isNaN(profileLimit) || profileLimit <= 0) {
      organizationError = lookupTranslation(
        "seats_invalid",
        ORGANIZATION_ERROR_PATH
      );
    }
    if (typeof organizationError !== "undefined") {
      submissionErrors.push(setOrganizationError(organizationError));
    }
  }
  if (submissionErrors.length > 0) {
    yield all([
      ...submissionErrors.map((x) => put(x)),
      put(receiveEnrollmentError()),
    ]);
    return;
  }
  if (password && confirmPassword) {
    const passwordCheck = checkPasswordLength(password);
    const matchPasswords = checkPasswordsMatch(password, confirmPassword);

    if (!passwordCheck || !matchPasswords) {
      yield all([put(receiveEnrollmentError())]);
      return;
    }
  }
  // Now we need to start working with server errors.
  // We have to:
  // 1. Send the make account API call (if not logged in)
  // 2. Login
  // 3. Are we doing free plan? If so, exit out.
  // 4. Make the "add billing info" call, and show errors for the billing info area
  // 5. Make the subscribe to plan call; and show errors for the billing or org area.
  if (!isLoggedIn) {
    const response = yield call(
      TellerAPI.sendProfileCreate,
      email,
      password,
      confirmPassword,
      acceptTOS
    );
    if (response.error) {
      const errorMessage = lookupTranslation(
        response.body.message,
        ACCOUNT_ERROR_PATH
      );

      if (response.body.message === "invalid_email_format") {
        yield all([
          put(receiveEnrollmentError()),
          put(setAccountEmailError(errorMessage)),
        ]);
      } else {
        yield all([
          put(receiveEnrollmentError()),
          put(setAccountError(errorMessage)),
        ]);
      }

      return;
    }
    yield* doLogin(email, password);
  }
  // If we are on the free plan, it is now time to go to the checkout page
  if (isDefaultPlan) {
    // We don't have to do anything else, go to the checkout page
    yield put(receiveEnrollment());
    yield put(doRedirect("/enroll/confirmation"));
    return;
  }
  // From above:
  // 4. Make the "add billing info" call, and show errors for the billing info area
  if (typeof stripeToken !== "undefined") {
    const setPaymentInfoResponse = yield call(
      TellerAPI.setPaymentInfo,
      stripeToken
    );
    if (setPaymentInfoResponse.error) {
      if (setPaymentInfoResponse.error === 500) {
        yield put(
          setBillingError(lookupTranslation("default", BILLING_ERROR_PATH))
        );
      } else {
        const errorMessage = lookupTranslation(
          setPaymentInfoResponse.message,
          BILLING_ERROR_PATH,
          BILLING_ERROR_DEFAULT
        );
        yield put(setBillingError(errorMessage));
      }
      return;
    }
  }
  // 5. Make the subscribe to plan call; and show errors for the billing or org area.
  const subscribeResponse = yield call(
    TellerAPI.subscribeToPlan,
    currentlySelectedPlanId,
    planQuote.availableCouponId,
    zipCode,
    organizationName,
    profileLimit
  );
  // Didn't work
  if (subscribeResponse.error) {
    const message = lookupTranslation(
      subscribeResponse.body.message,
      BILLING_ERROR_PATH,
      BILLING_ERROR_DEFAULT
    );
    yield all([put(setBillingError(message)), put(receiveEnrollmentError())]);
  } else {
    yield call(enterEnrollmentSection);
    yield put(setUseCurrentBillingInfo(true));
    yield put(doRedirect("/enroll/confirmation"));
  }
}

export default runEnrollment;
