import { call, put, select, all } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  receiveDeleteTag,
  requestDeleteTag,
  deleteTagError,
  fetchTagsAndRules,
} from "app.reducers/tags";

import {
  dismissModal,
  startModalWorking,
  endModalWorking,
} from "app.reducers/ui";

function* deleteTag(action) {
  const { ledgerItemId, conditionId, tag } = action.payload;

  const { data } = yield select((state) => state.exchangeLedger);

  const updatedItems =
    typeof ledgerItemId !== "undefined"
      ? data.items.map((entry) =>
          entry.id === ledgerItemId
            ? { ...entry, tags: entry.tags.filter((t) => t.name !== tag) }
            : entry
        )
      : data.items;
  yield put(startModalWorking());
  yield put(requestDeleteTag());

  const response = yield call(
    ExchangeLedgerAPI.bulkDeleteLedgerTag,
    ledgerItemId,
    conditionId,
    tag
  );

  const { error } = response;
  if (error) {
    yield put(endModalWorking());
    yield put(deleteTagError());
  } else {
    yield put(receiveDeleteTag(updatedItems));
    yield all([
      put(endModalWorking()),
      put(dismissModal()),
      put(fetchTagsAndRules()),
    ]);
  }
}

export default deleteTag;
