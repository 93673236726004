import { all, call, put } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";
import { fetchLedgers } from "app.reducers/exchanges";

import {
  requestTransferOutToSourceless,
  receiveTransferOutToSourceless,
  receiveTransferOutToSourcelessError,
} from "app.actions/exchangeLedger";
import {
  dismissModal,
  endModalWorking,
  startModalWorking,
} from "app.reducers/ui";

function* changeTransferOutToSourceless(action) {
  const { ledgerItemId, toLedgerId, newLedgerName } = action;

  yield all([put(startModalWorking()), put(requestTransferOutToSourceless())]);

  const response = yield call(
    ExchangeLedgerAPI.setTransferOut,
    ledgerItemId,
    toLedgerId,
    newLedgerName
  );

  if (response.error) {
    yield all([
      put(endModalWorking()),
      put(receiveTransferOutToSourcelessError(response.body.message)),
    ]);

    return;
  }

  yield all([
    put(fetchLedgers()),
    put(receiveTransferOutToSourceless(ledgerItemId)),
    put(dismissModal()),
  ]);
}

export default changeTransferOutToSourceless;
