import { call, put, all, select } from "redux-saga/effects";
import {
  requestImportApiKeyVaults,
  receiveImportApiKeyVaults,
  receiveImportApiKeyVaultsError,
  fetchLedgers,
} from "app.reducers/exchanges";
import { doRedirect } from "app.reducers/ui";
import ExchangesAPI from "app.api/ExchangesAPI";

function* importApiKeyVaults(action) {
  const { exchange, vaults } = action.payload;

  yield put(requestImportApiKeyVaults());

  const { apiKeyVault } = yield select((state) => state.exchanges);

  const response = yield call(
    ExchangesAPI.importVaults,
    exchange.id,
    apiKeyVault.apiKey,
    apiKeyVault.secretKey,
    vaults
  );

  const { body, error } = response;
  if (error) {
    if (typeof body.metadata !== "undefined") {
      yield put(
        receiveImportApiKeyVaultsError(
          { type: "bad-key", message: body.metadata.message },
          error
        )
      );
    } else {
      yield put(receiveImportApiKeyVaults(body, error));
    }
  } else {
    yield all([
      put(receiveImportApiKeyVaults(body)),
      put(fetchLedgers()),
      put(doRedirect(`/portfolio/add-ledger/confirm/${exchange.groupKey}`)),
    ]);
  }
}

export default importApiKeyVaults;
