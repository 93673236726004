import { call, put } from "redux-saga/effects";
import {
  requestTagGroups,
  receiveTagGroups,
  tagGroupsError,
} from "app.reducers/accountingIntegrations";
import ExchangesAPI from "app.api/ExchangesAPI";
import { getLoadingState } from "app.utils/selectors";
import { STATUS } from "app.constants";

function* fetchTagGroups() {
  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.accountingIntegrations.tagGroupsStatus
  );

  if (isLoading) return;

  yield put(requestTagGroups(nextStatus));

  const response = yield call(ExchangesAPI.getTagGroups);

  if (response.error) {
    console.error("Error in fetchTagGroups", response.body, response.error);
    yield put(tagGroupsError(response.body, STATUS.ERROR));
  } else {
    yield put(receiveTagGroups(response.body));
  }
}

export default fetchTagGroups;
